img {
    height: auto;
    max-width: 100%;

}

html,
body {
    width: 100%;
    height: 100%;
}

input::-ms-clear,
input::-ms-reveal {
    display: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
    width: device-width;
}

body {
    margin: 0;
}

[tabindex='-1']:focus {
    outline: none;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 500;
    color: #000;
    font-size: inherit;
}

p {
    margin-top: 0;
    margin-bottom: 0;
    color: #000;
}

abbr[title],
abbr[data-original-title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline;
    text-decoration: underline dotted;
    border-bottom: 0;
    cursor: help;
}

address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1em;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 500;
}

dd {
    margin-bottom: 0.5em;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1em;
}

dfn {
    font-style: italic;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

pre,
code,
kbd,
samp {
    font-size: 1em;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

pre {
    margin-top: 0;
    margin-bottom: 1em;
    overflow: auto;
}

figure {
    margin: 0 0 1em;
}

img {
    vertical-align: middle;
    border-style: none;
}

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
    touch-action: manipulation;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75em;
    padding-bottom: 0.3em;
    text-align: left;
    caption-side: bottom;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
    outline: none;
}

button,
select {
    text-transform: none;

}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.5em;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    line-height: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

mark {
    padding: 0.2em;
    background-color: #feffe6;
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button;
    background-color: initial;
    background-image: none;
}

a {
    text-decoration: none;
}



/*@import url(https://fonts.googleapis.com/css2?family=Inter:wghpreviewWrapt@300;400;500;600;700&display=swap)*/
;
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);

*,
:after,
:before {
    border: 0 solid #e5e7eb
}

:after,
:before {
    --tw-content: ""
}

html {
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-variation-settings: normal;
    line-height: 1.5;
    tab-size: 4
}

body {
    line-height: inherit
}

hr {
    border-top-width: 1px;
    color: inherit
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit
}

a {
    color: inherit;
    text-decoration: inherit
}

code,
kbd,
pre,
samp {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace
}

table {
    border-color: inherit;
    text-indent: 0
}

button,
input,
optgroup,
select,
textarea {
    font-size: 100%;
    font-weight: inherit;
    padding: 0
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button;
    background-color: initial;
    background-image: none;
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
    margin: 0
}

menu,
ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0
}


[role=button],
button {
    cursor: pointer
}

:disabled {
    cursor: default
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
    display: block;
    vertical-align: middle
}

img,
video {
    height: auto;
    max-width: 100%
}

[hidden] {
    display: none
}

*,
:after,
:before {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

::-webkit-backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

.container {
    width: 100%
}

@media (min-width: 640px) {
    .container {
        max-width: 640px
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px
    }
}

@media (min-width: 1536px) {
    .container {
        max-width: 1536px
    }
}

.sr-only {
    clip: rect(0, 0, 0, 0);
    border-width: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.pointer-events-none {
    pointer-events: none
}

.visible {
    visibility: visible
}

.collapse {
    visibility: collapse
}

.fixed {
    position: fixed
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.sticky {
    position: -webkit-sticky;
    position: sticky
}

.inset-0 {
    inset: 0
}

.inset-y-0 {
    bottom: 0;
    top: 0
}

.-bottom-96 {
    bottom: -24rem
}

.-top-64 {
    top: -16rem
}

.bottom-0 {
    bottom: 0
}

.bottom-2 {
    bottom: .5rem
}

.bottom-3 {
    bottom: .75rem
}

.bottom-5 {
    bottom: 1.25rem
}

.bottom-8 {
    bottom: 2rem
}

.bottom-\[144px\] {
    bottom: 144px
}

.bottom-\[87px\] {
    bottom: 87px
}

.left-0 {
    left: 0
}

.left-1\/2 {
    left: 50%
}

.left-3 {
    left: .75rem
}

.left-5 {
    left: 1.25rem
}

.left-6 {
    left: 1.5rem
}

.left-9 {
    left: 2.25rem
}

.left-\[500px\] {
    left: 500px
}

.right-0 {
    right: 0
}

.right-20 {
    right: 5rem
}

.right-24 {
    right: 6rem
}

.right-3 {
    right: .75rem
}

.right-5 {
    right: 1.25rem
}

.right-6 {
    right: 1.5rem
}

.right-64 {
    right: 16rem
}

.right-8 {
    right: 2rem
}

.right-9 {
    right: 2.25rem
}

.top-0 {
    top: 0
}

.top-1\/2 {
    top: 50%
}

.top-10 {
    top: 2.5rem
}

.top-11 {
    top: 2.75rem
}

.top-14 {
    top: 3.5rem
}

.top-16 {
    top: 4rem
}

.top-8 {
    top: 2rem
}

.top-\[14px\] {
    top: 14px
}

.top-\[273px\] {
    top: 273px
}

.top-\[530px\] {
    top: 530px
}

.top-\[85px\] {
    top: 85px
}

.z-0 {
    z-index: 0
}

.z-10 {
    z-index: 10
}

.z-40 {
    z-index: 40
}

.z-50 {
    z-index: 50
}

.col-span-4 {
    grid-column: span 4/span 4
}

.col-span-full {
    grid-column: 1/-1
}

.m-10 {
    margin: 2.5rem
}

.m-3 {
    margin: .75rem
}

.m-5 {
    margin: 1.25rem
}

.m-\[100px\] {
    margin: 100px
}

.m-\[10px\] {
    margin: 10px
}

.m-\[30px\] {
    margin: 30px
}

.mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
}

.mx-2 {
    margin-left: .5rem;
    margin-right: .5rem
}

.mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem
}

.mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
}

.mx-\[20px\] {
    margin-left: 20px;
    margin-right: 20px
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.my-10 {
    margin-bottom: 2.5rem;
    margin-top: 2.5rem
}

.my-12 {
    margin-bottom: 3rem;
    margin-top: 3rem
}

.my-2 {
    margin-bottom: .5rem;
    margin-top: .5rem
}

.my-3 {
    margin-bottom: .75rem;
    margin-top: .75rem
}

.my-5 {
    margin-bottom: 1.25rem;
    margin-top: 1.25rem
}

.my-6 {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem
}

.my-8 {
    margin-bottom: 2rem;
    margin-top: 2rem
}

.mb-0 {
    margin-bottom: 0
}

.mb-1 {
    margin-bottom: .25rem
}

.mb-10 {
    margin-bottom: 2.5rem
}

.mb-12 {
    margin-bottom: 3rem
}

.mb-2 {
    margin-bottom: .5rem
}

.mb-20 {
    margin-bottom: 5rem
}

.mb-4 {
    margin-bottom: 1rem
}

.mb-5 {
    margin-bottom: 1.25rem
}

.mb-6 {
    margin-bottom: 1.5rem
}

.mb-7 {
    margin-bottom: 1.75rem
}

.mb-8 {
    margin-bottom: 2rem
}

.ml-0 {
    margin-left: 0
}

.ml-1 {
    margin-left: .25rem
}

.ml-12 {
    margin-left: 3rem
}

.ml-2 {
    margin-left: .5rem
}

.ml-20 {
    margin-left: 5rem
}

.ml-3 {
    margin-left: .75rem
}

.ml-32 {
    margin-left: 8rem
}

.ml-4 {
    margin-left: 1rem
}

.ml-5 {
    margin-left: 1.25rem
}

.ml-6 {
    margin-left: 1.5rem
}

.ml-8 {
    margin-left: 2rem
}

.ml-\[12px\] {
    margin-left: 12px
}

.ml-\[15px\] {
    margin-left: 15px
}

.ml-\[330px\] {
    margin-left: 330px
}

.ml-auto {
    margin-left: auto
}

.mr-0 {
    margin-right: 0
}

.mr-1 {
    margin-right: .25rem
}

.mr-12 {
    margin-right: 3rem
}

.mr-2 {
    margin-right: .5rem
}

.mr-3 {
    margin-right: .75rem
}

.mr-4 {
    margin-right: 1rem
}

.mr-5 {
    margin-right: 1.25rem
}

.mr-7 {
    margin-right: 1.75rem
}

.mr-8 {
    margin-right: 2rem
}

.ms-auto {
    -webkit-margin-start: auto;
    margin-inline-start: auto
}

.mt-0 {
    margin-top: 0
}

.mt-1 {
    margin-top: .25rem
}

.mt-10 {
    margin-top: 2.5rem
}

.mt-12 {
    margin-top: 3rem
}

.mt-14 {
    margin-top: 3.5rem
}

.mt-16 {
    margin-top: 4rem
}

.mt-2 {
    margin-top: .5rem
}

.mt-20 {
    margin-top: 5rem
}

.mt-3 {
    margin-top: .75rem
}

.mt-4 {
    margin-top: 1rem
}

.mt-5 {
    margin-top: 1.25rem
}

.mt-6 {
    margin-top: 1.5rem
}

.mt-7 {
    margin-top: 1.75rem
}

.mt-8 {
    margin-top: 2rem
}

.mt-9 {
    margin-top: 2.25rem
}

.mt-\[800px\] {
    margin-top: 800px
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}

.inline {
    display: inline
}

.flex {
    display: flex
}

.inline-flex {
    display: inline-flex
}

.table {
    display: table
}

.grid {
    display: grid
}

.hidden {
    display: none
}

.h-10 {
    height: 2.5rem
}

.h-12 {
    height: 3rem
}

.h-14 {
    height: 3.5rem
}

.h-16 {
    height: 4rem
}

.h-24 {
    height: 6rem
}

.h-28 {
    height: 7rem
}

.h-3 {
    height: .75rem
}

.h-32 {
    height: 8rem
}

.h-40 {
    height: 10rem
}

.h-5 {
    height: 1.25rem
}

.h-52 {
    height: 13rem
}

.h-6 {
    height: 1.5rem
}

.h-64 {
    height: 16rem
}

.h-72 {
    height: 18rem
}

.h-8 {
    height: 2rem
}

.h-96 {
    height: 24rem
}

.h-\[140px\] {
    height: 140px
}

.h-\[222px\] {
    height: 222px
}

.h-\[39px\] {
    height: 39px
}

.h-\[400px\] {
    height: 400px
}

.h-\[40px\] {
    height: 40px
}

.h-\[47px\] {
    height: 47px
}

.h-\[550px\] {
    height: 550px
}

.h-\[636px\] {
    height: 636px
}

.h-auto {
    height: auto
}

.h-full {
    height: 100%
}

.h-screen {
    height: 100vh
}

.min-h-\[400px\] {
    min-height: 400px
}

.min-h-\[500px\] {
    min-height: 500px
}

.min-h-full {
    min-height: 100%
}

.min-h-screen {
    min-height: 100vh
}

.w-1\/2 {
    width: 50%
}

.w-1\/6 {
    width: 16.666667%
}

.w-10 {
    width: 2.5rem
}

.w-10\/12 {
    width: 83.333333%
}

.w-12 {
    width: 3rem
}

.w-14 {
    width: 3.5rem
}

.w-16 {
    width: 4rem
}

.w-2\/12 {
    width: 16.666667%
}

.w-2\/4 {
    width: 50%
}

.w-2\/5 {
    width: 40%
}

.w-20 {
    width: 5rem
}

.w-24 {
    width: 6rem
}

.w-28 {
    width: 7rem
}

.w-3 {
    width: .75rem
}

.w-3\/12 {
    width: 25%
}

.w-3\/4 {
    width: 75%
}

.w-3\/5 {
    width: 60%
}

.w-3\/6 {
    width: 50%
}

.w-32 {
    width: 8rem
}

.w-36 {
    width: 9rem
}

.w-4\/12 {
    width: 33.333333%
}

.w-4\/5 {
    width: 80%
}

.w-40 {
    width: 10rem
}

.w-44 {
    width: 11rem
}

.w-48 {
    width: 12rem
}

.w-5 {
    width: 1.25rem
}

.w-5\/6 {
    width: 83.333333%
}

.w-52 {
    width: 13rem
}

.w-56 {
    width: 14rem
}

.w-6 {
    width: 1.5rem
}

.w-60 {
    width: 15rem
}

.w-64 {
    width: 16rem
}

.w-72 {
    width: 18rem
}

.w-8 {
    width: 2rem
}

.w-80 {
    width: 20rem
}

.w-96 {
    width: 24rem
}

.w-\[170px\] {
    width: 170px
}

.w-\[196px\] {
    width: 196px
}

.w-\[198px\] {
    width: 198px
}

.w-\[200px\] {
    width: 200px
}

.w-\[220px\] {
    width: 220px
}

.w-\[240px\] {
    width: 240px
}

.w-\[250px\] {
    width: 250px
}

.w-\[260px\] {
    width: 260px
}

.w-\[262px\] {
    width: 262px
}

.w-\[280px\] {
    width: 280px
}

.w-\[300px\] {
    width: 300px
}

.w-\[350px\] {
    width: 350px
}

.w-\[400px\] {
    width: 400px
}

.w-\[40px\] {
    width: 40px
}

.w-\[420px\] {
    width: 420px
}

.w-\[45px\] {
    width: 45px
}

.w-\[470px\] {
    width: 470px
}

.w-\[477px\] {
    width: 477px
}

.w-\[512px\] {
    width: 512px
}

.w-\[620px\] {
    width: 620px
}

.w-auto {
    width: auto
}

.w-full {
    width: 100%
}

.min-w-0 {
    min-width: 0
}

.min-w-\[320px\] {
    min-width: 320px
}

.min-w-full {
    min-width: 100%
}

.max-w-3xl {
    max-width: 48rem
}

.max-w-7xl {
    max-width: 80rem
}
.max-w-8xl {
    max-width: 90rem
}

.max-w-9xl {
    max-width: 100rem
}

.max-w-full {
    max-width: 100%
}

.max-w-lg {
    max-width: 32rem
}

.max-w-md {
    max-width: 28rem
}

.max-w-sm {
    max-width: 24rem
}

.flex-1 {
    flex: 1 1
}

.flex-shrink-0 {
    flex-shrink: 0
}

.shrink {
    flex-shrink: 1
}

.flex-grow {
    flex-grow: 1
}

.border-collapse {
    border-collapse: collapse
}

.origin-top-right {
    -webkit-transform-origin: top right;
    transform-origin: top right
}

.-translate-x-1\/2 {
    --tw-translate-x: -50%
}

.-translate-x-1\/2,
.-translate-y-1\/2 {
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-1\/2 {
    --tw-translate-y: -50%
}

.-rotate-45 {
    --tw-rotate: -45deg
}

.-rotate-45,
.scale-100 {
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1
}

.scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95
}

.scale-95,
.transform {
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-not-allowed {
    cursor: not-allowed
}

.cursor-pointer {
    cursor: pointer
}

.resize {
    resize: both
}

.appearance-none {
    -webkit-appearance: none;
    appearance: none
}

.grid-flow-col {
    grid-auto-flow: column
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
}

.flex-row {
    flex-direction: row
}

.flex-row-reverse {
    flex-direction: row-reverse
}

.flex-col {
    flex-direction: column
}

.flex-wrap {
    flex-wrap: wrap
}

.flex-nowrap {
    flex-wrap: nowrap
}

.items-end {
    align-items: flex-end
}

.items-center {
    align-items: center
}

.justify-start {
    justify-content: flex-start
}

.justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.justify-around {
    justify-content: space-around
}

.justify-items-center {
    justify-items: center
}

.gap-0 {
    gap: 0
}

.gap-10 {
    gap: 2.5rem
}

.gap-2 {
    gap: .5rem
}

.gap-3 {
    gap: .75rem
}

.gap-4 {
    gap: 1rem
}

.gap-5 {
    gap: 1.25rem
}

.gap-6 {
    gap: 1.5rem
}

.gap-8 {
    gap: 2rem
}

.gap-x-1 {
    -webkit-column-gap: .25rem;
    column-gap: .25rem
}

.gap-x-1\.5 {
    -webkit-column-gap: .375rem;
    column-gap: .375rem
}

.gap-x-2 {
    -webkit-column-gap: .5rem;
    column-gap: .5rem
}

.gap-x-6 {
    -webkit-column-gap: 1.5rem;
    column-gap: 1.5rem
}

.gap-y-8 {
    row-gap: 2rem
}

.space-x-3>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-left: calc(.75rem*(1 - var(--tw-space-x-reverse)));
    margin-right: calc(.75rem*var(--tw-space-x-reverse))
}

.space-x-3\.5>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-left: calc(.875rem*(1 - var(--tw-space-x-reverse)));
    margin-right: calc(.875rem*var(--tw-space-x-reverse))
}

.space-y-1>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-bottom: calc(.25rem*var(--tw-space-y-reverse));
    margin-top: calc(.25rem*(1 - var(--tw-space-y-reverse)))
}

.overflow-auto {
    overflow: auto
}

.overflow-hidden {
    overflow: hidden
}

.overflow-x-auto {
    overflow-x: auto
}

.overflow-y-auto {
    overflow-y: auto
}

.overflow-x-hidden {
    overflow-x: hidden
}

.overflow-x-scroll {
    overflow-x: scroll
}

.whitespace-nowrap {
    white-space: nowrap
}

.break-words {
    overflow-wrap: break-word
}

.rounded {
    border-radius: .25rem
}

.rounded-3xl {
    border-radius: 1.5rem
}

.rounded-\[33px\] {
    border-radius: 33px
}

.rounded-\[6px\] {
    border-radius: 6px
}

.rounded-full {
    border-radius: 9999px
}

.rounded-lg {
    border-radius: .5rem
}

.rounded-md {
    border-radius: .375rem
}

.rounded-none {
    border-radius: 0
}

.rounded-xl {
    border-radius: .75rem
}

.rounded-l-lg {
    border-bottom-left-radius: .5rem;
    border-top-left-radius: .5rem
}

.rounded-l-md {
    border-bottom-left-radius: .375rem;
    border-top-left-radius: .375rem
}

.rounded-l-xl {
    border-bottom-left-radius: .75rem;
    border-top-left-radius: .75rem
}

.rounded-r-lg {
    border-bottom-right-radius: .5rem;
    border-top-right-radius: .5rem
}

.rounded-r-md {
    border-bottom-right-radius: .375rem;
    border-top-right-radius: .375rem
}

.rounded-r-xl {
    border-bottom-right-radius: .75rem;
    border-top-right-radius: .75rem
}

.rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.border {
    border-width: 1px
}

.border-0 {
    border-width: 0
}

.border-2 {
    border-width: 2px
}

.border-4 {
    border-width: 4px
}

.border-b {
    border-bottom-width: 1px
}

.border-b-2 {
    border-bottom-width: 2px
}

.border-l-0 {
    border-left-width: 0
}

.border-r-0 {
    border-right-width: 0
}

.border-t {
    border-top-width: 1px
}

.border-t-0 {
    border-top-width: 0
}

.border-solid {
    border-style: solid
}

.border-black {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0/var(--tw-border-opacity))
}

.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219/var(--tw-border-opacity))
}

.border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgb(249 250 251/var(--tw-border-opacity))
}

.border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgb(34 197 94/var(--tw-border-opacity))
}

.border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68/var(--tw-border-opacity))
}

.border-slate-300 {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225/var(--tw-border-opacity))
}

.border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgb(45 212 191/var(--tw-border-opacity))
}

.border-transparent {
    border-color: transparent
}

.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255/var(--tw-border-opacity))
}

.bg-\[\#1e293b\] {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59/var(--tw-bg-opacity))
}

.bg-\[\#dffedc\] {
    --tw-bg-opacity: 1;
    background-color: rgb(223 254 220/var(--tw-bg-opacity))
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity))
}

.bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254/var(--tw-bg-opacity))
}

.bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254/var(--tw-bg-opacity))
}

.bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246/var(--tw-bg-opacity))
}

.bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235/var(--tw-bg-opacity))
}

.bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175/var(--tw-bg-opacity))
}

.bg-emerald-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129/var(--tw-bg-opacity))
}

.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246/var(--tw-bg-opacity))
}

.bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235/var(--tw-bg-opacity))
}

.bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175/var(--tw-bg-opacity))
}

.bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251/var(--tw-bg-opacity))
}

.bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128/var(--tw-bg-opacity))
}

.bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94/var(--tw-bg-opacity))
}

.bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241/var(--tw-bg-opacity))
}

.bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229/var(--tw-bg-opacity))
}

.bg-neutral-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(115 115 115/var(--tw-bg-opacity))
}

.bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22/var(--tw-bg-opacity))
}

.bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(234 88 12/var(--tw-bg-opacity))
}

.bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 38 38/var(--tw-bg-opacity))
}

.bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249/var(--tw-bg-opacity))
}

.bg-slate-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240/var(--tw-bg-opacity))
}

.bg-slate-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225/var(--tw-bg-opacity))
}

.bg-slate-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184/var(--tw-bg-opacity))
}

.bg-slate-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252/var(--tw-bg-opacity))
}

.bg-slate-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105/var(--tw-bg-opacity))
}

.bg-slate-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85/var(--tw-bg-opacity))
}

.bg-slate-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59/var(--tw-bg-opacity))
}

.bg-transparent {
    background-color: initial
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity))
}

.bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 240 138/var(--tw-bg-opacity))
}

.bg-opacity-20 {
    --tw-bg-opacity: 0.2
}

.bg-opacity-30 {
    --tw-bg-opacity: 0.3
}

.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops))
}

.from-\[\#996F07\] {
    --tw-gradient-from: #996f07 var(--tw-gradient-from-position);
    --tw-gradient-from-position: ;
    --tw-gradient-to: rgba(153, 111, 7, 0) var(--tw-gradient-from-position);
    --tw-gradient-to-position: ;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-\[\#D3A940\] {
    --tw-gradient-from: #d3a940 var(--tw-gradient-from-position);
    --tw-gradient-from-position: ;
    --tw-gradient-to: rgba(211, 169, 64, 0) var(--tw-gradient-from-position);
    --tw-gradient-to-position: ;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-\[\#DDB34A\] {
    --tw-gradient-from: #ddb34a var(--tw-gradient-from-position);
    --tw-gradient-from-position: ;
    --tw-gradient-to: rgba(221, 179, 74, 0) var(--tw-gradient-from-position);
    --tw-gradient-to-position: ;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.to-\[\#976D05\] {
    --tw-gradient-to: #976d05 var(--tw-gradient-to-position);
    --tw-gradient-to-position:
}

.to-\[\#9E740C\] {
    --tw-gradient-to: #9e740c var(--tw-gradient-to-position);
    --tw-gradient-to-position:
}

.to-\[\#DAB047\] {
    --tw-gradient-to: #dab047 var(--tw-gradient-to-position);
    --tw-gradient-to-position:
}

.bg-cover {
    background-size: cover
}

.bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text
}

.fill-current {
    fill: currentColor
}

.object-cover {
    object-fit: cover
}

.object-center {
    object-position: center
}

.p-0 {
    padding: 0
}

.p-1 {
    padding: .25rem
}

.p-10 {
    padding: 2.5rem
}

.p-2 {
    padding: .5rem
}

.p-2\.5 {
    padding: .625rem
}

.p-3 {
    padding: .75rem
}

.p-4 {
    padding: 1rem
}

.p-5 {
    padding: 1.25rem
}

.p-6 {
    padding: 1.5rem
}

.px-1 {
    padding-left: .25rem;
    padding-right: .25rem
}

.px-12 {
    padding-left: 3rem;
    padding-right: 3rem
}

.px-2 {
    padding-left: .5rem;
    padding-right: .5rem
}

.px-3 {
    padding-left: .75rem;
    padding-right: .75rem
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem
}

.px-\[100px\] {
    padding-left: 100px;
    padding-right: 100px
}

.py-1 {
    padding-bottom: .25rem;
    padding-top: .25rem
}

.py-1\.5 {
    padding-bottom: .375rem;
    padding-top: .375rem
}

.py-10 {
    padding-bottom: 2.5rem;
    padding-top: 2.5rem
}

.py-2 {
    padding-bottom: .5rem;
    padding-top: .5rem
}

.py-2\.5 {
    padding-bottom: .625rem;
    padding-top: .625rem
}

.py-3 {
    padding-bottom: .75rem;
    padding-top: .75rem
}

.py-4 {
    padding-bottom: 1rem;
    padding-top: 1rem
}

.py-5 {
    padding-bottom: 1.25rem;
    padding-top: 1.25rem
}

.pb-1 {
    padding-bottom: .25rem
}

.pb-10 {
    padding-bottom: 2.5rem
}

.pb-3 {
    padding-bottom: .75rem
}

.pb-6 {
    padding-bottom: 1.5rem
}

.pb-8 {
    padding-bottom: 2rem
}

.pe-0 {
    -webkit-padding-end: 0;
    padding-inline-end: 0
}

.pl-1 {
    padding-left: .25rem
}

.pl-10 {
    padding-left: 2.5rem
}

.pl-2 {
    padding-left: .5rem
}

.pl-28 {
    padding-left: 7rem
}

.pl-3 {
    padding-left: .75rem
}

.pl-5 {
    padding-left: 1.25rem
}

.pl-8 {
    padding-left: 2rem
}

.pl-\[10px\] {
    padding-left: 10px
}

.pr-10 {
    padding-right: 2.5rem
}

.pr-2 {
    padding-right: .5rem
}

.pr-28 {
    padding-right: 7rem
}

.pr-5 {
    padding-right: 1.25rem
}

.pr-\[38px\] {
    padding-right: 38px
}

.pt-0 {
    padding-top: 0
}

.pt-2 {
    padding-top: .5rem
}

.pt-20 {
    padding-top: 5rem
}

.pt-3 {
    padding-top: .75rem
}

.pt-4 {
    padding-top: 1rem
}

.pt-5 {
    padding-top: 1.25rem
}

.pt-6 {
    padding-top: 1.5rem
}

.pt-8 {
    padding-top: 2rem
}

.pt-\[150px\] {
    padding-top: 150px
}

.pt-\[50px\] {
    padding-top: 50px
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.text-right {
    text-align: right
}

.align-middle {
    vertical-align: middle
}

.font-\[\'Poppins\'\] {
    font-family: Poppins
}

.font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
}

.text-5xl {
    font-size: 3rem;
    line-height: 1
}

.text-6xl {
    font-size: 3.75rem;
    line-height: 1
}

.text-\[11px\] {
    font-size: 11px
}

.text-\[12px\] {
    font-size: 12px
}
.text-\[13px\] {
    font-size: 12px
}

.text-\[15px\] {
    font-size: 15px
}

.text-\[18px\] {
    font-size: 18px
}

.text-\[20px\] {
    font-size: 20px
}

.text-\[22px\] {
    font-size: 22px
}

.text-\[26px\] {
    font-size: 26px
}

.text-\[36px\] {
    font-size: 36px
}
.text-\[40px\] {
    font-size: 40px
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}

.text-xs {
    font-size: .75rem;
    line-height: 1rem
}

.font-black {
    font-weight: 900
}

.font-bold {
    font-weight: 700
}

.font-light {
    font-weight: 300
}

.font-medium {
    font-weight: 500
}

.font-normal {
    font-weight: 400
}

.font-semibold {
    font-weight: 600
}

.uppercase {
    text-transform: uppercase
}

.lowercase {
    text-transform: lowercase
}

.leading-6 {
    line-height: 1.5rem
}

.leading-8 {
    line-height: 2rem
}

.leading-none {
    line-height: 1
}

.leading-tight {
    line-height: 1.25
}

.tracking-tight {
    letter-spacing: -.025em
}

.text-\[\#996F07\] {
    --tw-text-opacity: 1;
    color: rgb(153 111 7/var(--tw-text-opacity))
}

.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity))
}

.text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(59 130 246/var(--tw-text-opacity))
}

.text-blue-700 {
    --tw-text-opacity: 1;
    color: rgb(29 78 216/var(--tw-text-opacity))
}

.text-emerald-500 {
    --tw-text-opacity: 1;
    color: rgb(16 185 129/var(--tw-text-opacity))
}

.text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219/var(--tw-text-opacity))
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175/var(--tw-text-opacity))
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128/var(--tw-text-opacity))
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99/var(--tw-text-opacity))
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81/var(--tw-text-opacity))
}

.text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(31 41 55/var(--tw-text-opacity))
}

.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39/var(--tw-text-opacity))
}

.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94/var(--tw-text-opacity))
}

.text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74/var(--tw-text-opacity))
}

.text-green-900 {
    --tw-text-opacity: 1;
    color: rgb(20 83 45/var(--tw-text-opacity))
}

.text-orange-500 {
    --tw-text-opacity: 1;
    color: rgb(249 115 22/var(--tw-text-opacity))
}

.text-orange-600 {
    --tw-text-opacity: 1;
    color: rgb(234 88 12/var(--tw-text-opacity))
}

.text-pink-500 {
    --tw-text-opacity: 1;
    color: rgb(236 72 153/var(--tw-text-opacity))
}

.text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68/var(--tw-text-opacity))
}

.text-red-600 {
    --tw-text-opacity: 1;
    color: rgb(220 38 38/var(--tw-text-opacity))
}

.text-slate-500 {
    --tw-text-opacity: 1;
    color: rgb(100 116 139/var(--tw-text-opacity))
}

.text-slate-600 {
    --tw-text-opacity: 1;
    color: rgb(71 85 105/var(--tw-text-opacity))
}

.text-slate-700 {
    --tw-text-opacity: 1;
    color: rgb(51 65 85/var(--tw-text-opacity))
}

.text-transparent {
    color: transparent
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity))
}

.text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgb(234 179 8/var(--tw-text-opacity))
}

.text-zinc-50 {
    --tw-text-opacity: 1;
    color: rgb(250 250 250/var(--tw-text-opacity))
}

.underline {
    text-decoration-line: underline
}

.underline-offset-1 {
    text-underline-offset: 1px
}

.placeholder-slate-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(148 163 184/var(--tw-placeholder-opacity))
}

.placeholder-slate-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(148 163 184/var(--tw-placeholder-opacity))
}

.opacity-0 {
    opacity: 0
}

.opacity-100 {
    opacity: 1
}

.opacity-25 {
    opacity: .25
}

.shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color)
}

.shadow,
.shadow-lg {
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color)
}

.shadow-sm,
.shadow-xl {
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color)
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-inset {
    --tw-ring-inset: inset
}

.ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0 0 0/var(--tw-ring-opacity))
}

.ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219/var(--tw-ring-opacity))
}

.ring-opacity-5 {
    --tw-ring-opacity: 0.05
}

.filter {
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
    transition-duration: .15s;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.transition-all {
    transition-duration: .15s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.duration-100 {
    transition-duration: .1s
}

.duration-150 {
    transition-duration: .15s
}

.duration-300 {
    transition-duration: .3s
}

.duration-75 {
    transition-duration: 75ms
}

.ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1)
}

.ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.ease-linear {
    transition-timing-function: linear
}

.ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1)
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none
}

.scrollbar-hide::-webkit-scrollbar {
    display: none
}

.placeholder\:text-gray-400::-webkit-input-placeholder {
    --tw-text-opacity: 1;
    color: rgb(156 163 175/var(--tw-text-opacity))
}

.placeholder\:text-gray-400::placeholder {
    --tw-text-opacity: 1;
    color: rgb(156 163 175/var(--tw-text-opacity))
}

.placeholder\:text-slate-400::-webkit-input-placeholder {
    --tw-text-opacity: 1;
    color: rgb(148 163 184/var(--tw-text-opacity))
}

.placeholder\:text-slate-400::placeholder {
    --tw-text-opacity: 1;
    color: rgb(148 163 184/var(--tw-text-opacity))
}

.invalid\:border-pink-500:invalid {
    --tw-border-opacity: 1;
    border-color: rgb(236 72 153/var(--tw-border-opacity))
}

.invalid\:text-pink-600:invalid {
    --tw-text-opacity: 1;
    color: rgb(219 39 119/var(--tw-text-opacity))
}

.hover\:rotate-1:hover {
    --tw-rotate: 1deg
}

.hover\:rotate-12:hover,
.hover\:rotate-1:hover {
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:rotate-12:hover {
    --tw-rotate: 12deg
}

.hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255/var(--tw-border-opacity))
}

.hover\:bg-\[\#475569\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105/var(--tw-bg-opacity))
}

.hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity))
}

.hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216/var(--tw-bg-opacity))
}

.hover\:bg-emerald-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(5 150 105/var(--tw-bg-opacity))
}

.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251/var(--tw-bg-opacity))
}

.hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81/var(--tw-bg-opacity))
}

.hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241/var(--tw-bg-opacity))
}

.hover\:bg-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(194 65 12/var(--tw-bg-opacity))
}

.hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(185 28 28/var(--tw-bg-opacity))
}

.hover\:bg-slate-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240/var(--tw-bg-opacity))
}

.hover\:bg-slate-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225/var(--tw-bg-opacity))
}

.hover\:bg-slate-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85/var(--tw-bg-opacity))
}

.hover\:bg-slate-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59/var(--tw-bg-opacity))
}

.hover\:bg-gradient-to-b:hover {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.hover\:text-\[\#795909\]:hover {
    --tw-text-opacity: 1;
    color: rgb(121 89 9/var(--tw-text-opacity))
}

.hover\:text-\[\#ffffff\]:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity))
}

.hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgb(30 64 175/var(--tw-text-opacity))
}

.hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgb(30 58 138/var(--tw-text-opacity))
}

.hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgb(75 85 99/var(--tw-text-opacity))
}

.hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgb(31 41 55/var(--tw-text-opacity))
}

.hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity))
}

.hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgb(202 138 4/var(--tw-text-opacity))
}

.hover\:underline:hover {
    text-decoration-line: underline
}

.hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246/var(--tw-border-opacity))
}

.focus\:border-sky-500:focus {
    --tw-border-opacity: 1;
    border-color: rgb(14 165 233/var(--tw-border-opacity))
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)
}

.focus\:ring-1:focus,
.focus\:ring-2:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)
}

.focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-inset:focus {
    --tw-ring-inset: inset
}

.focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 197 253/var(--tw-ring-opacity))
}

.focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246/var(--tw-ring-opacity))
}

.focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(75 85 99/var(--tw-ring-opacity))
}

.focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(79 70 229/var(--tw-ring-opacity))
}

.focus\:ring-sky-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 165 233/var(--tw-ring-opacity))
}

.focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 255 255/var(--tw-ring-opacity))
}

.focus\:invalid\:border-pink-500:invalid:focus {
    --tw-border-opacity: 1;
    border-color: rgb(236 72 153/var(--tw-border-opacity))
}

.focus\:invalid\:ring-pink-500:invalid:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 72 153/var(--tw-ring-opacity))
}

.active\:bg-indigo-600:active {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229/var(--tw-bg-opacity))
}

.disabled\:border-slate-200:disabled {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240/var(--tw-border-opacity))
}

.disabled\:bg-slate-50:disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252/var(--tw-bg-opacity))
}

.disabled\:text-slate-500:disabled {
    --tw-text-opacity: 1;
    color: rgb(100 116 139/var(--tw-text-opacity))
}

.disabled\:opacity-75:disabled {
    opacity: .75
}

.disabled\:shadow-none:disabled {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

@media (prefers-color-scheme:dark) {
    .dark\:border-gray-600 {
        --tw-border-opacity: 1;
        border-color: rgb(75 85 99/var(--tw-border-opacity))
    }

    .dark\:bg-gray-700 {
        --tw-bg-opacity: 1;
        background-color: rgb(55 65 81/var(--tw-bg-opacity))
    }

    .dark\:text-blue-400 {
        --tw-text-opacity: 1;
        color: rgb(96 165 250/var(--tw-text-opacity))
    }

    .dark\:text-gray-400 {
        --tw-text-opacity: 1;
        color: rgb(156 163 175/var(--tw-text-opacity))
    }

    .dark\:text-green-400 {
        --tw-text-opacity: 1;
        color: rgb(74 222 128/var(--tw-text-opacity))
    }

    .dark\:text-pink-500 {
        --tw-text-opacity: 1;
        color: rgb(236 72 153/var(--tw-text-opacity))
    }

    .dark\:text-white {
        --tw-text-opacity: 1;
        color: rgb(255 255 255/var(--tw-text-opacity))
    }

    .dark\:placeholder-gray-400::-webkit-input-placeholder {
        --tw-placeholder-opacity: 1;
        color: rgb(156 163 175/var(--tw-placeholder-opacity))
    }

    .dark\:placeholder-gray-400::placeholder {
        --tw-placeholder-opacity: 1;
        color: rgb(156 163 175/var(--tw-placeholder-opacity))
    }

    .dark\:focus\:border-blue-500:focus {
        --tw-border-opacity: 1;
        border-color: rgb(59 130 246/var(--tw-border-opacity))
    }

    .dark\:focus\:ring-blue-500:focus {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(59 130 246/var(--tw-ring-opacity))
    }
}

@media (min-width:640px) {
    .sm\:ml-6 {
        margin-left: 1.5rem
    }

    .sm\:mt-0 {
        margin-top: 0
    }

    .sm\:flex {
        display: flex
    }

    .sm\:w-2\/4 {
        width: 50%
    }

    .sm\:w-\[350px\] {
        width: 350px
    }

    .sm\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr))
    }

    .sm\:p-10 {
        padding: 2.5rem
    }

    .sm\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .sm\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .sm\:text-left {
        text-align: left
    }

    .sm\:text-6xl {
        font-size: 3.75rem;
        line-height: 1
    }

    .sm\:text-\[22px\] {
        font-size: 22px
    }

    .sm\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem
    }

    .sm\:leading-6 {
        line-height: 1.5rem
    }
}

@media (min-width:768px) {
    .md\:-top-96 {
        top: -24rem
    }

    .md\:right-32 {
        right: 8rem
    }

    .md\:mx-auto {
        margin-left: auto;
        margin-right: auto
    }

    .md\:mb-0 {
        margin-bottom: 0
    }

    .md\:mb-20 {
        margin-bottom: 5rem
    }

    .md\:mb-5 {
        margin-bottom: 1.25rem
    }

    .md\:ml-\[380px\] {
        margin-left: 380px
    }

    .md\:ml-\[800px\] {
        margin-left: 800px
    }

    .md\:mt-10 {
        margin-top: 2.5rem
    }

    .md\:mt-12 {
        margin-top: 3rem
    }

    .md\:block {
        display: block
    }

    .md\:inline-block {
        display: inline-block
    }

    .md\:flex {
        display: flex
    }

    .md\:hidden {
        display: none
    }

    .md\:h-full {
        height: 100%
    }

    .md\:w-1\/2 {
        width: 50%
    }

    .md\:w-5\/12 {
        width: 41.666667%
    }

    .md\:w-5\/6 {
        width: 83.333333%
    }

    .md\:w-6\/12 {
        width: 50%
    }

    .md\:w-7\/12 {
        width: 58.333333%
    }

    .md\:w-96 {
        width: 24rem
    }

    .md\:w-\[247px\] {
        width: 247px
    }

    .md\:w-\[310px\] {
        width: 310px
    }

    .md\:w-\[350px\] {
        width: 350px
    }

    .md\:w-auto {
        width: auto
    }

    .md\:min-w-\[500px\] {
        min-width: 500px
    }

    .md\:max-w-lg {
        max-width: 32rem
    }

    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .md\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr))
    }

    .md\:grid-rows-3 {
        grid-template-rows: repeat(3, minmax(0, 1fr))
    }

    .md\:items-center {
        align-items: center
    }

    .md\:justify-between {
        justify-content: space-between
    }

    .md\:gap-4 {
        gap: 1rem
    }

    .md\:p-8 {
        padding: 2rem
    }

    .md\:text-left {
        text-align: left
    }

    .md\:text-center {
        text-align: center
    }

    .md\:text-2xl {
        font-size: 1.5rem;
        line-height: 2rem
    }
}

@media (min-width:1024px) {
    .lg\:mb-20 {
        margin-bottom: 5rem
    }

    .lg\:mt-0 {
        margin-top: 0
    }

    .lg\:block {
        display: block
    }

    .lg\:inline-block {
        display: inline-block
    }

    .lg\:flex {
        display: flex
    }

    .lg\:hidden {
        display: none
    }

    .lg\:w-1\/2 {
        width: 50%
    }

    .lg\:w-11\/12 {
        width: 91.666667%
    }

    .lg\:w-auto {
        width: auto
    }

    .lg\:flex-grow {
        flex-grow: 1
    }

    .lg\:items-center {
        align-items: center
    }

    .lg\:rounded-l-none {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0
    }

    .lg\:p-20 {
        padding: 5rem
    }

    .lg\:p-8 {
        padding: 2rem
    }

    .lg\:px-20 {
        padding-left: 5rem;
        padding-right: 5rem
    }

    .lg\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .lg\:text-5xl {
        font-size: 3rem;
        line-height: 1
    }
}

@media (min-width:1280px) {
    .xl\:mb-0 {
        margin-bottom: 0
    }

    .xl\:w-3\/4 {
        width: 75%
    }
}

.App {
    text-align: center
}

.App-logo {
    height: 40vmin;
    pointer-events: none
}

@media (prefers-reduced-motion:no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin 20s linear infinite;
        animation: App-logo-spin 20s linear infinite
    }
}

.App-header {
    align-items: center;
    background-color: #282c34;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    justify-content: center;
    min-height: 100vh
}

.App-link {
    color: #61dafb
}

@-webkit-keyframes App-logo-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes App-logo-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.modal_overlay {
    align-items: flex-start;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0
}

.modal_overlay .ant-form .ant-input {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    height: 42px
}

.modal_overlay .ant-form .ant-btn {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    height: 52px
}

.modal_overlay .ant-form p {
    font-size: 12px;
    margin-top: 5px;
    text-align: left
}

.card-counts .ant-statistic-content {
    display: flex;
    font-size: 38px;
    font-weight: 400
}

.card-counts .ant-statistic-content .ant-statistic-content-prefix {
    align-items: center;
    display: flex;
    font-size: 34px;
    margin-right: 10px
}

.ant-layout .sideWrap .ant-layout-sider {
    min-height: 100%
}

.add-button {
    align-items: center;
    background-color: #1677ff;
    color: #fff;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    gap: 5px;
    height: 38px;
    justify-content: center;
    padding: 5px 15px;
    transition: all .3s ease
}

.add-button:hover {
    background-color: #1369e1;
    color: #fff
}

.add-button .anticon {
    font-size: 18px
}


body,
html {
    height: 100%;
    width: 100%
}

input::-ms-clear,
input::-ms-reveal {
    display: none
}

*,
:after,
:before {
    box-sizing: border-box
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: sans-serif;
    line-height: 1.15
}

body {
    margin: 0
}

[tabindex="-1"]:focus {
    outline: none
}

hr {
    box-sizing: initial;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    margin-bottom: .5em;
    margin-top: 0
}

p {
    margin-bottom: 0;
    margin-top: 0
}

abbr[data-original-title],
abbr[title] {
    border-bottom: 0;
    cursor: help;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline;
    text-decoration: underline dotted
}

address {
    font-style: normal;
    line-height: inherit;
    margin-bottom: 1em
}



dl,
ol,
ul {
    margin-bottom: 1em;
    margin-top: 0
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 500
}

dd {
    margin-bottom: .5em;
    margin-left: 0
}

blockquote {
    margin: 0 0 1em
}

dfn {
    font-style: italic
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: initial
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
    font-size: 1em
}

pre {
    margin-bottom: 1em;
    margin-top: 0;
    overflow: auto
}

figure {
    margin: 0 0 1em
}

img {
    border-style: none;
    vertical-align: middle
}

[role=button],
a,
area,
button,
input:not([type=range]),
label,
select,
summary,
textarea {
    touch-action: manipulation
}

table {
    border-collapse: collapse
}

caption {
    caption-side: bottom;
    padding-bottom: .3em;
    padding-top: .75em;
    text-align: left
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0
}

legend {
    color: inherit;
    display: block;
    font-size: 1.5em;
    line-height: inherit;
    margin-bottom: .5em;
    max-width: 100%;
    padding: 0;
    white-space: normal;
    width: 100%
}

progress {
    vertical-align: initial
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: none;
    outline-offset: -2px
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

output {
    display: inline-block
}

summary {
    display: list-item
}

template {
    display: none
}

[hidden] {
    display: none !important
}

mark {
    background-color: #feffe6;
    padding: .2em
}



.previewWrap1 {
    background-attachment: fixed;
    height: 100%;
    padding-bottom: 20px;
    position: relative
}

.previewWrap1 .bannerImage {
    background: #f4f4f4;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, .05);
    -moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, .05);
    -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, .05);
    height: 200px;
    position: absolute;
    width: 100%
}

.previewWrap1 .bannerImage img {
    background: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer1 {
    margin: 0 auto;
    max-width: 380px;
    padding: 0 12px 60px;
    position: relative;
    z-index: 5
}

.previewContainer1 .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 220px;
    padding-bottom: 35px;
    padding-top: 65px
}

.previewContainer1 .header img {
    width: 106px
}

.previewContainer1 .header img path {
    fill: #fff
}

.previewContainer1 .header .btn {
    align-items: center;
    border: 1px solid #fff;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #fff;
    display: flex;
    font-size: 11px;
    font-weight: 400;
    height: 30px;
    justify-content: center;
    line-height: 13px;
    padding: 0 15px;
    text-decoration: none;
    width: auto
}

.previewContainer1 .userDetails {
    align-items: center;
    background: #c3c3c3;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative
}

.previewContainer1 .userDetails figure {
    height: 200px;
    margin: 0;
    min-width: 122px;
    width: 122px
}

.previewContainer1 .userDetails figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer1 .user {
    padding: 20px
}

.previewContainer1 .user h1 {
    word-wrap: break-word;
    color: #000;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 5px;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, .3)
}

.previewContainer1 .user p {
    color: #221a1a;
    font-size: 12px;
    line-height: 18px;
    margin: 0
}

.previewContainer1 .buttons {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    margin-bottom: 40px;
    overflow: hidden;
    width: 100%
}

.previewContainer1 .buttons a {
    align-items: center;
    background: #e6e6e6;
    color: #000;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 17px;
    height: 49px;
    justify-content: center;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    width: 50%
}

.previewContainer1 .buttons a.addToContact {
    background: #000;
    color: #fff
}

.previewContainer1 h2 {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    padding-bottom: 10px;
    padding-right: 30px
}

.previewContainer1 h2,
.previewContainer1 h4 {
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
    position: relative
}

.previewContainer1 h4 {
    font-size: 27px;
    font-weight: 600;
    justify-content: center;
    line-height: 33px;
    padding: 4px 0 14px
}

.previewContainer1 h2 span {
    align-items: center;
    background: #1d1d1f;
    border-radius: 44px;
    -moz-border-radius: 44px;
    -webkit-border-radius: 44px;
    display: flex;
    height: 44px;
    justify-content: center;
    width: 44px
}

.previewContainer1 .about {
    color: #424242;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 24px
}

.previewContainer1 .social-links {
    background: #1d1d1f;
    border-radius: 9px;
    -moz-border-radius: 9px;
    -webkit-border-radius: 9px;
    display: flex;
    gap: 12px;
    justify-content: center;
    margin: 0 0 35px;
    padding: 9px
}

.previewContainer1 .social-links a {
    align-items: center;
    background: #1d1d1f;
    border: .829932px solid #fff;
    border-radius: 44px;
    -moz-border-radius: 44px;
    -webkit-border-radius: 44px;
    display: flex;
    height: 44px;
    justify-content: center;
    min-width: 44px;
    width: 44px
}

.previewContainer1 .social-media {
    display: flex;
    margin: 0;
    padding: 0
}

.previewContainer1 .contactOptions .social-media a {
    align-items: center;
    color: #000;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    line-height: 20px;
    min-height: 34px;
    padding: 0;
    text-decoration: none;
    width: 100%
}

.previewContainer1 .contactOptions .social-media a img {
    height: 43px;
    width: 43px
}

.previewContainer1 .social-media h5 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px
}

.previewContainer1 .social-media h5 span {
    color: #858585;
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px
}

.previewContainer1 .contactOptions {
    background: #fff;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin-bottom: 13px;
    padding: 16px 24px
}

.previewContainer1 .contactOptions h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px
}

.previewContainer1 .contactOptions a {
    align-items: center;
    color: #000;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    line-height: 20px;
    min-height: 34px;
    padding: 0 0 10px;
    text-decoration: none
}

.previewContainer1 a.blk-btn {
    align-items: center;
    background: #1d1d1f;
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
    color: #fff;
    display: inline-flex;
    font-size: 12px;
    height: 40px;
    justify-content: center;
    line-height: 14px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0 25px
}

.previewContainer1 .otherLinks {
    margin-bottom: 40px
}

.previewContainer1 .otherLinks a {
    align-items: center;
    /* background: #efefef url(/static/media/more_icon.94451243b75c2fbf8686.svg) no-repeat 96%; */
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    overflow: hidden;
    padding: 0;
    text-decoration: none
}

.previewContainer1 .otherLinks figure {
    height: 89px;
    min-width: 180px;
    padding-left: 1px;
    width: 180px
}

.previewContainer1 .otherLinks figure img {
    border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer1 .otherLinks figcaption {
    color: #1e1e1e;
    font-size: 15px;
    line-height: 20px;
    padding: 15px 35px 15px 15px;
    width: calc(100% - 180px)
}

.previewContainer1 .photoGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    justify-content: space-between;
    margin-bottom: 20px
}

.previewContainer1 .photoGrid img {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin: 0;
    object-fit: cover
}

.addToContact {
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    bottom: 27px;
    box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, .2);
    height: 60px;
    position: fixed;
    right: 27px;
    width: 150px;
    z-index: 10
}

.addToContact img {
    width: 100%
}

.previewContainer1 .photoGrid figure {
    display: block;
    height: 164px;
    margin-bottom: 22px;
    min-width: calc(50% - 11px)
}

.previewContainer1 .photoGrid figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer1 .companyLogo {
    margin-bottom: 40px
}

.previewContainer1 .companyLogo img {
    display: block;
    height: 50px;
    margin: 0 auto
}

.previewWrap {
    height: 100%;
    position: relative
}

.previewWrap .bannerImage {
    background: #f4f4f4;
    height: 200px;
    position: absolute;
    width: 100%
}

.previewWrap .bannerImage img {
    background: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer {
    max-width: 380px;
    padding: 0 12px;
    position: relative;
    z-index: 5
}

.previewContainer .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 170px;
    padding-bottom: 35px;
    padding-top: 65px
}

.previewContainer .header img {
    width: 106px
}

.previewContainer .header img path {
    fill: #fff
}

.previewContainer .header .btn {
    align-items: center;
    border: 1px solid #fff;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #fff;
    display: flex;
    font-size: 11px;
    font-weight: 400;
    height: 30px;
    justify-content: center;
    line-height: 13px;
    padding: 0 15px;
    text-decoration: none;
    width: auto
}

.previewContainer .userDetails {
    align-items: center;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative
}

.previewContainer .userDetails figure {
    height: 200px;
    margin: 0;
    min-width: 154px;
    width: 154px
}

.previewContainer .userDetails figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer .user {
    padding: 20px
}

.previewContainer .user h1 {
    word-wrap: break-word;
    color: #fff;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 5px
}

.previewContainer .user p {
    color: #c8c8c8;
    font-size: 12px;
    line-height: 18px;
    margin: 0
}

.previewContainer .buttons {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    margin-bottom: 40px;
    overflow: hidden;
    width: 100%
}

.previewContainer .buttons a {
    align-items: center;
    background: #efefef;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 17px;
    height: 49px;
    justify-content: center;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    width: 50%
}

.previewContainer .buttons a.addTo {
    color: #fff
}

.previewContainer h2 {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 16px;
    padding-right: 10px;
    position: relative
}

.previewContainer .about {
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 24px
}

.previewContainer .social-links {
    display: flex;
    gap: 28px;
    margin: 0 0 35px
}

.previewContainer .social-links a {
    align-items: center;
    background: #efefef;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    display: flex;
    height: 54px;
    justify-content: center;
    min-width: 54px;
    width: 54px
}

.previewContainer .contactOptions {
    margin-bottom: 40px
}

.previewContainer .contactOptions a {
    align-items: center;
    /* background: url(/static/media/more_icon.94451243b75c2fbf8686.svg) no-repeat 100%; */
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    gap: 10px;
    line-height: 20px;
    min-height: 34px;
    padding: 10px 0;
    text-decoration: none
}

.previewContainer .otherLinks {
    margin-bottom: 40px
}

.previewContainer .otherLinks a {
    align-items: center;
    /* background: #efefef url(/static/media/more_icon.94451243b75c2fbf8686.svg) no-repeat 96%; */
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    overflow: hidden;
    text-decoration: none
}

.previewContainer .otherLinks figure {
    height: 89px;
    min-width: 180px;
    padding-left: 1px;
    width: 180px
}

.previewContainer .otherLinks figure img {
    border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer .otherLinks figcaption {
    color: #1e1e1e;
    font-size: 15px;
    line-height: 20px;
    padding: 15px 35px 15px 15px;
    width: calc(100% - 180px)
}

.previewContainer .photoGrid {
    grid-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    grid-template-columns: 50% 50%;
    justify-content: space-between
}

.previewContainer .photoGrid img {
    object-fit: cover
}

.qr-code {
    border: none;
    border-radius: 5px !important;
    padding: 12px;
    right: 7px
}

.previewContainer .photoGrid figure {
    display: block;
    height: 164px;
    margin-bottom: 22px;
    min-width: calc(50% - 11px)
}

.previewContainer .photoGrid figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer .companyLogo {
    margin-bottom: 40px
}

.previewContainer .companyLogo img {
    display: block;
    height: 50px;
    margin: 0 auto
}

.previewWrap .footer {
    background: #1e1e1e;
    padding: 25px 0 18px;
    text-align: center
}

.previewWrap .footer p {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #dbb24b, #ded099);
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 10px
}

.previewWrap .footer p a {
    color: #fff;
    text-decoration: none
}

.popup-container1 {
    background: #efefef
}

.popup-container1 .close-popup {
    align-items: center;
    background: #000;
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
    color: #fff;
    display: flex;
    height: 45px;
    justify-content: center;
    width: 45px
}

.popup-container1 .userDetails {
    padding: 30px;
    width: 100%
}

.popup-container1 .userDetails figure {
    border-radius: 140px;
    -moz-border-radius: 140px;
    -webkit-border-radius: 140px;
    height: 140px;
    margin: 0 auto 10px;
    overflow: hidden;
    width: 140px
}

.popup-container1 .userDetails .user {
    margin-bottom: 20px;
    text-align: center
}

.popup-container1 .userDetails .user h1 {
    color: #000;
    font-size: 27.88px;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 4px
}

.popup-container1 .userDetails .user p {
    color: #525252;
    font-size: 14px;
    font-weight: 600
}

.popup-container1 .my-qr-code {
    margin: 0 auto 30px
}

.popup-container1 .my-qr-download {
    cursor: pointer;
    margin: 0 auto 30px;
    padding: 5px;
    text-align: center;
    width: 200px
}

.addToHome {
    align-items: center;
    /* background: #000 url(/static/media/plus_icon.181d73e85582f6b9246a.svg) no-repeat 95%; */
    border-radius: 13px;
    -moz-border-radius: 13px;
    -webkit-border-radius: 13px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 44px;
    justify-content: space-between;
    line-height: 19px;
    padding-left: 14px;
    width: 100%
}

.popup-container1 .userDetails figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewWrap31 {
    background-color: #fff;
    height: 100%;
    position: relative
}

.previewWrap31 .bannerImage {
    background: #f4f4f4;
    height: 310px;
    position: absolute;
    width: 100%
}

.previewWrap31 .bannerImage img {
    background: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer31 {
    margin: 0 auto;
    max-width: 380px;
    padding: 0 15px;
    position: relative;
    z-index: 5
}

.previewContainer31 .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 240px;
    padding-bottom: 35px;
    padding-top: 65px
}

.previewContainer31 .header img {
    width: 106px
}

.previewContainer31 .header img path {
    fill: #fff
}

.previewContainer31 .header .btn {
    align-items: center;
    border: 1px solid #fff;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #fff;
    display: flex;
    font-size: 11px;
    font-weight: 400;
    height: 30px;
    justify-content: center;
    line-height: 13px;
    padding: 0 15px;
    text-decoration: none;
    width: auto
}

.previewContainer31 .userDetails {
    align-items: center;
    background: linear-gradient(90deg, #fe4f32, #dd2474);
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative
}

.previewContainer31 .userDetails figure {
    height: 200px;
    margin: 0;
    min-width: 154px;
    width: 154px
}

.previewContainer31 .userDetails figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer31 .userDetails .user {
    width: calc(100% - 154px)
}

.previewContainer31 .user {
    padding: 25px
}

.previewContainer31 .user h1 {
    color: #fff;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 5px
}

.previewContainer31 .user p {
    color: #c8c8c8;
    font-size: 12px;
    line-height: 18px;
    margin: 0
}

.previewWrap31 .buttons {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    overflow: hidden
}

.previewWrap31 .buttons a {
    align-items: center;
    background: #e6e6e6;
    color: #000;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 17px;
    height: 53px;
    justify-content: center;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    width: 50%
}

.previewWrap31 .buttons a.addTo {
    color: #fff
}

.previewContainer31 h2 {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 16px;
    padding-right: 30px;
    position: relative
}

.previewContainer31 .about {
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 24px
}

.previewContainer31 .social-links {
    display: flex;
    gap: 28px;
    margin: 0 0 35px
}

.previewContainer31 .social-links a {
    align-items: center;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    display: flex;
    height: 54px;
    justify-content: center;
    min-width: 54px;
    width: 54px
}

.previewContainer31 .contactOptions {
    margin-bottom: 40px
}

.previewContainer31 .contactOptions a {
    align-items: center;
    /* background: url(/static/media/more_icon.94451243b75c2fbf8686.svg) no-repeat 100%; */
    border-bottom: 1px solid #e6e6e6;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    gap: 10px;
    line-height: 20px;
    min-height: 34px;
    padding: 10px 0;
    text-decoration: none
}

.previewContainer31 .otherLinks {
    margin-bottom: 40px
}

.previewContainer31 .otherLinks a {
    align-items: center;
    /* background: #efefef url(/static/media/more_icon.94451243b75c2fbf8686.svg) no-repeat 96%; */
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    overflow: hidden;
    text-decoration: none
}

.previewContainer31 .otherLinks figure {
    height: 80px;
    min-width: 180px;
    width: 180px
}

.previewContainer31 .otherLinks figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer31 .otherLinks figcaption {
    color: #1e1e1e;
    font-size: 15px;
    line-height: 20px;
    padding: 15px 35px 15px 15px;
    width: calc(100% - 180px)
}

.previewContainer31 .photoGrid {
    grid-gap: 5px;
    display: grid;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
    margin-bottom: 20px
}

.qr-code-modern {
    background: linear-gradient(90deg, #fe4f32, #dd2474);
    border: none;
    border-radius: 5px !important;
    padding: 12px;
    right: 7px
}

.photoGrid figure img {
    grid-gap: 1rem;
    display: flex;
    grid-template-columns: 10rem 10rem 10rem;
    object-fit: cover
}

.previewContainer31 .companyLogo {
    margin-bottom: 40px;
    width: 100px
}

.previewContainer31 .companyLogo img {
    height: 70px;
    margin-top: 30px;
    width: 70px
}

.previewContainer31 .companyLogoDown {
    margin-bottom: 40px
}

.previewContainer31 .companyLogoDown img {
    display: block;
    height: 50px;
    margin: 0 auto
}

.previewWrap31 .footer {
    background: #1e1e1e;
    padding: 25px 0 100px;
    text-align: center
}

.previewWrap31 .footer p {
    color: #fff;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 5px
}

.previewWrap31 .footer p a {
    color: #fff;
    text-decoration: none
}

.popup-container2 {
    background: #efefef
}

.popup-container2 .close-popup {
    align-items: center;
    background: linear-gradient(90deg, #fe4f32, #dd2474);
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
    color: #fff;
    display: flex;
    height: 45px;
    justify-content: center;
    width: 45px
}

.popup-container2 .userDetails {
    padding: 30px;
    width: 100%
}

.popup-container2 .userDetails figure {
    border-radius: 140px;
    -moz-border-radius: 140px;
    -webkit-border-radius: 140px;
    height: 140px;
    margin: 0 auto 10px;
    overflow: hidden;
    width: 140px
}

.popup-container2 .userDetails .user {
    margin-bottom: 20px;
    text-align: center
}

.popup-container2 .userDetails .user h1 {
    color: #000;
    font-size: 27.88px;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 4px
}

.popup-container2 .userDetails .user p {
    color: #525252;
    font-size: 14px;
    font-weight: 600
}

.popup-container2 .my-qr-code {
    margin: 0 auto 30px
}

.popup-container2 .my-qr-download {
    cursor: pointer;
    margin: 0 auto 30px;
    padding: 5px;
    text-align: center;
    width: 200px
}

.addToHome2 {
    align-items: center;
    /* background: url(/static/media/plus_icon.181d73e85582f6b9246a.svg) no-repeat 95%, linear-gradient(90deg, #fe4f32, #dd2474); */
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 44px;
    justify-content: space-between;
    line-height: 19px;
    padding-left: 14px;
    width: 100%
}

.addToHome2,
.addtoHomeImage2 {
    border-radius: 13px;
    -moz-border-radius: 13px;
    -webkit-border-radius: 13px
}

.addtoHomeImage2 {
    margin: 20px auto 0;
    max-width: 80%
}

.popup-container2 .userDetails figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.btnrelative {
    height: calc(100vh - 86px);
    max-height: 722px;
    position: relative
}

.btnrelative .buttons {
    bottom: 0;
    left: 0;
    padding: 0 10px 70px;
    position: fixed;
    top: inherit;
    width: 100%
}

.mobilePreview .btnrelative .buttons {
    padding: 0 20px 70px;
    top: 78%;
    transform: scale(.92);
    -moz-transform: scale(.92);
    -webkit-transform: scale(.92);
    -webkit-transform-origin: 0 center;
    transform-origin: 0 center
}

.previewWrapDark {
    height: 100%;
    position: relative
}

.previewWrapDark .bannerImage {
    background: #f4f4f4;
    height: 200px;
    position: absolute;
    width: 100%
}

.previewWrapDark .bannerImage img {
    background: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainerDark {
    margin: 0 auto;
    max-width: 380px;
    padding: 0 12px;
    position: relative;
    z-index: 5
}

.previewContainerDark .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 170px;
    padding-bottom: 35px;
    padding-top: 65px
}

.previewContainerDark .header img {
    width: 106px
}

.previewContainerDark .header img path {
    fill: #fff
}

.previewContainerDark .header .btn {
    align-items: center;
    border: 1px solid #fff;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #fff;
    display: flex;
    font-size: 11px;
    font-weight: 400;
    height: 30px;
    justify-content: center;
    line-height: 13px;
    padding: 0 15px;
    text-decoration: none;
    width: auto
}

.previewContainerDark .userDetails {
    align-items: center;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative
}

.previewContainerDark .userDetails figure {
    height: 200px;
    margin: 0;
    min-width: 154px;
    width: 154px
}

.previewContainerDark .userDetails figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainerDark .userDetails .user {
    width: calc(100% - 154px)
}

.previewContainerDark .user {
    padding: 20px
}

.previewContainerDark .user h1 {
    word-wrap: break-word;
    color: #fff;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 5px
}

.previewContainerDark .user p {
    color: #c8c8c8;
    font-size: 12px;
    line-height: 18px;
    margin: 0
}

.previewContainerDark .buttons {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    margin-bottom: 40px;
    overflow: hidden;
    width: 100%
}

.previewContainerDark .buttons a {
    align-items: center;
    background: #efefef;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 17px;
    height: 49px;
    justify-content: center;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    width: 50%
}

.previewContainerDark .buttons a.addTo {
    background: #ff5c00;
    color: #fff
}

.previewContainerDark h2 {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 16px;
    padding-right: 10px;
    position: relative
}

.previewContainerDark .about {
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 24px
}

.previewContainerDark .social-links {
    display: flex;
    gap: 28px;
    margin: 0 0 35px
}

.previewContainerDark .social-links a {
    align-items: center;
    background: #efefef;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    display: flex;
    height: 54px;
    justify-content: center;
    min-width: 54px;
    width: 54px
}

.previewContainerDark .contactOptions {
    margin-bottom: 40px
}

.previewContainerDark .contactOptions a {
    align-items: center;
    /* background: url(/static/media/more_icon.94451243b75c2fbf8686.svg) no-repeat 100%; */
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    gap: 10px;
    line-height: 20px;
    min-height: 34px;
    padding: 10px 0;
    text-decoration: none
}

.previewContainerDark .otherLinks {
    margin-bottom: 40px
}

.previewContainerDark .otherLinks a {
    align-items: center;
    /* background: #efefef url(/static/media/more_icon.94451243b75c2fbf8686.svg) no-repeat 96%; */
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    overflow: hidden;
    text-decoration: none
}

.previewContainerDark .otherLinks figure {
    height: 89px;
    min-width: 180px;
    width: 180px
}

.previewContainerDark .otherLinks figure img {
    border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainerDark .otherLinks figcaption {
    color: #1e1e1e;
    font-size: 15px;
    line-height: 20px;
    padding: 15px 35px 15px 15px;
    width: calc(100% - 180px)
}

.previewContainerDark .photoGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
    margin-bottom: 20px
}

.previewContainerDark .photoGrid img {
    object-fit: cover
}

.qr-code-stdDark {
    background: #fff;
    color: #000;
    padding: 12px;
    right: 7px
}

.qr-code-download,
.qr-code-stdDark {
    border: none;
    border-radius: 5px !important
}

.qr-code-download {
    margin-bottom: 21px;
    padding: 11px;
    right: 80px
}

.previewContainerDark .photoGrid figure {
    display: block;
    height: 164px;
    margin-bottom: 22px;
    min-width: calc(50% - 11px)
}

.previewContainerDark .photoGrid figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainerDark .companyLogo {
    margin-bottom: 40px
}

.previewContainerDark .companyLogo img {
    display: block;
    height: 50px;
    margin: 0 auto
}

.previewWrapDark .footer {
    background: #1e1e1e;
    padding: 25px 0 18px;
    text-align: center
}

.previewWrapDark .footer p {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #dbb24b, #ded099);
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 10px
}

.previewWrapDark .footer p a {
    color: #fff;
    text-decoration: none
}

.popup-containerDark {
    background: #efefef
}

.popup-containerDark .close-popup {
    align-items: center;
    background: #000;
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
    color: #fff;
    display: flex;
    height: 45px;
    justify-content: center;
    width: 45px
}

.popup-containerDark .userDetails {
    padding: 30px;
    width: 100%
}

.popup-containerDark .userDetails figure {
    border-radius: 140px;
    -moz-border-radius: 140px;
    -webkit-border-radius: 140px;
    height: 140px;
    margin: 0 auto 10px;
    overflow: hidden;
    width: 140px
}

.popup-containerDark .userDetails .user {
    margin-bottom: 20px;
    text-align: center
}

.popup-containerDark .userDetails .user h1 {
    color: #000;
    font-size: 27.88px;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 4px
}

.popup-containerDark .userDetails .user p {
    color: #525252;
    font-size: 14px;
    font-weight: 600
}

.popup-containerDark .my-qr-code {
    margin: 0 auto 30px
}

.popup-containerDark .my-qr-download {
    cursor: pointer;
    margin: 0 auto 30px;
    padding: 5px;
    text-align: center;
    width: 200px
}

.addToHomeDark {
    align-items: center;
    /* background: #000 url(/static/media/plus_icon.181d73e85582f6b9246a.svg) no-repeat 95%; */
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 44px;
    justify-content: space-between;
    line-height: 19px;
    padding-left: 14px;
    width: 100%
}

.addToHomeDark,
.addtoHomeImageDark {
    border-radius: 13px;
    -moz-border-radius: 13px;
    -webkit-border-radius: 13px
}

.addtoHomeImageDark {
    margin: 20px auto 0;
    max-width: 80%
}

.popup-containerDark .userDetails figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewWrap14 {
    height: 100%;
    position: relative
}

.previewWrap14 .bannerImage {
    background: #f4f4f4;
    height: 220px;
    position: absolute;
    width: 100%
}

.previewWrap14 .bannerImage img {
    background: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer14 {
    margin: 0 auto;
    max-width: 380px;
    padding: 0 15px;
    position: relative;
    z-index: 5
}

.previewContainer14 .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 170px;
    padding-bottom: 35px;
    padding-top: 65px
}

.previewContainer14 .header img {
    width: 106px
}

.previewContainer14 .header img path {
    fill: #fff
}

.previewContainer14 .header .btn {
    align-items: center;
    border: 1px solid #fff;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #fff;
    display: flex;
    font-size: 11px;
    font-weight: 400;
    height: 30px;
    justify-content: center;
    line-height: 13px;
    padding: 0 15px;
    text-decoration: none;
    width: auto
}

.previewContainer14 .userDetails {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative
}

.userDetails-img {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin-bottom: 30px
}

.previewContainer14 .userDetails figure {
    height: 200px;
    margin: 0;
    min-width: 154px;
    width: 154px
}

.previewContainer14 .userDetails figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer14 .userDetails .user {
    width: calc(100% - 154px)
}

.previewContainer14 .user {
    padding: 25px
}

.previewContainer14 .user h1 {
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 5px;
    margin-top: 35px
}

.previewContainer14 .user p {
    color: #312b2b;
    font-size: 12px;
    line-height: 18px;
    margin: 0
}

.previewWrap14 .buttons {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    overflow: hidden
}

.previewWrap14 .buttons a {
    align-items: center;
    background: #e6e6e6;
    color: #000;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 17px;
    height: 54px;
    justify-content: center;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    width: 50%
}

.previewWrap14 .buttons a.addTo {
    color: #fff
}

.previewContainer14 h2 {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 16px;
    padding-right: 30px;
    position: relative
}

.previewContainer14 .about {
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 24px
}

.previewContainer14 .social-links {
    display: flex;
    gap: 28px;
    margin: 0 0 35px
}

.previewContainer14 .social-links a {
    align-items: center;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    display: flex;
    height: 54px;
    justify-content: center;
    min-width: 54px;
    width: 54px
}

.previewContainer14 .contactOptions {
    margin-bottom: 40px
}

.previewContainer14 .contactOptions a {
    align-items: center;
    /* background: url(/static/media/more_icon.94451243b75c2fbf8686.svg) no-repeat 100%; */
    border-bottom: 1px solid grey;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    gap: 10px;
    line-height: 20px;
    min-height: 34px;
    padding: 10px 0;
    text-decoration: none
}

.previewContainer14 .otherLinks {
    margin-bottom: 40px
}

.previewContainer14 .otherLinks a {
    align-items: center;
    /* background: #efefef url(/static/media/more_icon.94451243b75c2fbf8686.svg) no-repeat 96%; */
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    overflow: hidden;
    text-decoration: none
}

.previewContainer14 .otherLinks figure {
    height: 80px;
    min-width: 180px;
    width: 180px
}

.previewContainer14 .otherLinks figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer14 .otherLinks figcaption {
    color: #1e1e1e;
    font-size: 15px;
    line-height: 20px;
    padding: 15px 35px 15px 15px;
    width: calc(100% - 180px)
}

.previewContainer14 .photoGrid {
    grid-gap: 5px;
    display: grid;
    gap: 5px;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
    margin-bottom: 20px
}

.photoGrid {
    grid-gap: 1rem;
    color: #efefef;
    display: flex;
    grid-template-columns: 50% 50%
}

.mb-6 {
    height: 100%;
    max-width: 100%;
    object-fit: cover
}

.qr-code-minimal {
    background: linear-gradient(90deg, #662d8c, #ed1e79);
    border: none;
    border-radius: 5px !important;
    padding: 12px;
    right: 7px
}

.previewContainer14 .photoGrid figure {
    display: block;
    height: 164px;
    margin-bottom: 22px;
    min-width: calc(50% - 11px)
}

.previewContainer14 .photoGrid figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer14 .companyLogo {
    margin-bottom: 40px
}

.previewContainer14 .companyLogo img {
    display: block;
    height: 50px;
    margin: 0 auto
}

.previewWrap14 .footer {
    background: #1e1e1e;
    padding: 25px 0 100px;
    text-align: center
}

.previewWrap14 .footer p {
    color: #fff;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 5px
}

.previewWrap14 .footer p a {
    color: #fff;
    text-decoration: none
}

.popup-container3 {
    background: #efefef
}

.popup-container3 .close-popup {
    align-items: center;
    background: linear-gradient(90deg, #662d8c, #ed1e79);
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
    color: #fff;
    display: flex;
    height: 45px;
    justify-content: center;
    width: 45px
}

.popup-container3 .userDetails {
    padding: 30px;
    width: 100%
}

.popup-container3 .userDetails figure {
    border-radius: 140px;
    -moz-border-radius: 140px;
    -webkit-border-radius: 140px;
    height: 140px;
    margin: 0 auto 10px;
    overflow: hidden;
    width: 140px
}

.popup-container3 .userDetails .user {
    margin-bottom: 20px;
    text-align: center
}

.popup-container3 .userDetails .user h1 {
    color: #000;
    font-size: 27.88px;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 4px
}

.popup-container3 .userDetails .user p {
    color: #525252;
    font-size: 14px;
    font-weight: 600
}

.popup-container3 .my-qr-code {
    margin: 0 auto 30px
}

.popup-container3 .my-qr-download {
    cursor: pointer;
    margin: 0 auto 30px;
    padding: 5px;
    text-align: center;
    width: 200px
}

.addToHome3 {
    align-items: center;
    /* background: url(/static/media/plus_icon.181d73e85582f6b9246a.svg) no-repeat 95%, linear-gradient(90deg, #662d8c, #ed1e79); */
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 44px;
    justify-content: space-between;
    line-height: 19px;
    padding-left: 14px;
    width: 100%
}

.addToHome3,
.addtoHomeImage3 {
    border-radius: 13px;
    -moz-border-radius: 13px;
    -webkit-border-radius: 13px
}

.addtoHomeImage3 {
    margin: 20px auto 0;
    max-width: 80%
}

.popup-container3 .userDetails figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

*,
body,
button {
    font-family: Inter, sans-serif;
    margin: 0;
    padding: 0
}

.previewWrap2 {
    background-attachment: fixed;
    height: 100%;
    padding-bottom: 20px;
    position: relative
}

.previewWrap2 .bannerImage2 {
    background: #f4f4f4;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, .05);
    -moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, .05);
    -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, .05);
    height: 200px;
    position: absolute;
    width: 100%
}

.previewWrap2 .bannerImage2 img {
    background: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer2 {
    margin: 0 auto;
    max-width: 380px;
    padding: 0 12px 60px;
    position: relative;
    z-index: 5
}

.previewContainer2 .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 220px;
    padding-bottom: 35px;
    padding-top: 65px
}

.previewContainer2 .header img {
    width: 106px
}

.previewContainer2 .header img path {
    fill: #fff
}

.previewContainer2 .header .btn {
    align-items: center;
    border: 1px solid #fff;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #fff;
    display: flex;
    font-size: 11px;
    font-weight: 400;
    height: 30px;
    justify-content: center;
    line-height: 13px;
    padding: 0 15px;
    text-decoration: none;
    width: auto
}

.previewContainer2 .userDetails {
    align-items: center;
    background: #c3c3c3;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative
}

.previewContainer2 .userDetails figure {
    height: 200px;
    margin: 0;
    min-width: 122px;
    width: 122px
}

.previewContainer2 .userDetails figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer2 .user {
    padding: 20px
}

.previewContainer2 .user h1 {
    word-wrap: break-word;
    color: #000;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 5px;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, .3)
}

.previewContainer2 .user p {
    color: #221a1a;
    font-size: 12px;
    line-height: 18px;
    margin: 0
}

.previewContainer2 .buttons {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    margin-bottom: 40px;
    overflow: hidden;
    width: 100%
}

.previewContainer2 .buttons a {
    align-items: center;
    background: #e6e6e6;
    color: #000;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 17px;
    height: 49px;
    justify-content: center;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    width: 50%
}

.previewContainer2 .buttons a.addToContactClassic2 {
    color: #fff
}

.previewContainer2 h2 {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    padding-bottom: 10px;
    padding-right: 30px
}

.previewContainer2 h2,
.previewContainer2 h4 {
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
    position: relative
}

.previewContainer2 h4 {
    font-size: 27px;
    font-weight: 600;
    justify-content: center;
    line-height: 33px;
    padding: 4px 0 14px
}

.previewContainer2 h2 span {
    background: #1d1d1f
}

.previewContainer2 h2 span,
.websiteIcon {
    align-items: center;
    border-radius: 44px;
    -moz-border-radius: 44px;
    -webkit-border-radius: 44px;
    display: flex;
    height: 44px;
    justify-content: center;
    width: 44px
}

.previewContainer2 .about {
    color: #424242;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 24px
}

.previewContainer2 .social-links {
    background: #1d1d1f;
    border-radius: 9px;
    -moz-border-radius: 9px;
    -webkit-border-radius: 9px;
    display: flex;
    gap: 12px;
    justify-content: center;
    margin: 0 0 35px;
    padding: 9px
}

.previewContainer2 .social-links a {
    align-items: center;
    background: #1d1d1f;
    border: .829932px solid #fff;
    border-radius: 44px;
    -moz-border-radius: 44px;
    -webkit-border-radius: 44px;
    display: flex;
    height: 44px;
    justify-content: center;
    min-width: 44px;
    width: 44px
}

.previewContainer2 .social-media2 {
    display: flex;
    margin: 0;
    padding: 0
}

.previewContainer2 .contactOptions2 .social-media2 a {
    align-items: center;
    color: #000;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    line-height: 20px;
    min-height: 34px;
    padding: 0;
    text-decoration: none;
    width: 100%
}

.previewContainer2 .contactOptions2 .social-media2 a img {
    height: 43px;
    width: 43px
}

.previewContainer2 .social-media2 h5 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px
}

.previewContainer2 .social-media2 h5 span {
    color: #858585;
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px
}

.previewContainer2 .contactOptions2 {
    background: #fff;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin-bottom: 13px;
    padding: 16px 24px
}

.previewContainer2 .contactOptions2 h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px
}

.previewContainer2 .contactOptions2 a {
    align-items: center;
    color: #000;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    line-height: 20px;
    min-height: 34px;
    padding: 0 0 10px;
    text-decoration: none
}

.previewContainer2 a.blk-btn {
    align-items: center;
    background: #1d1d1f;
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
    color: #fff;
    display: inline-flex;
    font-size: 12px;
    height: 40px;
    justify-content: center;
    line-height: 14px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0 25px
}

.previewContainer2 .otherLinks {
    margin-bottom: 40px
}

.previewContainer2 .otherLinks a {
    align-items: center;
    /* background: #efefef url(/static/media/more_icon.94451243b75c2fbf8686.svg) no-repeat 96%; */
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    overflow: hidden;
    padding: 0;
    text-decoration: none
}

.previewContainer2 .otherLinks figure {
    height: 89px;
    min-width: 180px;
    padding-left: 1px;
    width: 180px
}

.previewContainer2 .otherLinks figure img {
    border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer2 .otherLinks figcaption {
    color: #1e1e1e;
    font-size: 15px;
    line-height: 20px;
    padding: 15px 35px 15px 15px;
    width: calc(100% - 180px)
}

.previewContainer2 .photoGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    justify-content: space-between;
    margin-bottom: 20px
}

.previewContainer2 .photoGrid img {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin: 0;
    object-fit: cover
}

.qr-code1 {
    left: 27px;
    padding: 5px
}

.qr-code1,
.share {
    align-items: center;
    background: #1d1d1f;
    border-radius: 41px;
    -moz-border-radius: 41px;
    -webkit-border-radius: 41px;
    bottom: 27px;
    box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, .2);
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 60px;
    justify-content: center;
    position: fixed;
    width: 60px;
    z-index: 10
}

.share {
    left: 100px;
    padding: 10px
}

.share img {
    margin-bottom: 4px;
    width: 75%
}

.addToContactClassic2 {
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    bottom: 27px;
    box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, .2);
    height: 60px;
    position: fixed;
    right: 27px;
    width: 150px;
    z-index: 10
}

.addToContactClassic2 img {
    width: 100%
}

.previewContainer2 .photoGrid figure {
    display: block;
    height: 164px;
    margin-bottom: 22px;
    min-width: calc(50% - 11px)
}

.previewContainer2 .photoGrid figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.previewContainer2 .companyLogo {
    margin-bottom: 40px
}

.previewContainer2 .companyLogo img {
    display: block;
    height: 50px;
    margin: 0 auto
}

.footer {
    background: #1e1e1e;
    padding: 25px 0 18px;
    text-align: center
}

.footer p {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #dbb24b, #ded099);
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 10px
}

.footer p a {
    color: #fff;
    text-decoration: none
}

.popup-container {
    background: #efefef
}

.popup-container .close-popup {
    align-items: center;
    background: #000;
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
    color: #fff;
    display: flex;
    height: 45px;
    justify-content: center;
    width: 45px
}

.popup-container .userDetails {
    padding: 30px;
    width: 100%
}

.popup-container .userDetails figure {
    border-radius: 140px;
    -moz-border-radius: 140px;
    -webkit-border-radius: 140px;
    height: 140px;
    margin: 0 auto 10px;
    overflow: hidden;
    width: 140px
}

.popup-container .userDetails .user {
    margin-bottom: 20px;
    text-align: center
}

.popup-container .userDetails .user h1 {
    color: #000;
    font-size: 27.88px;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 4px
}

.popup-container .userDetails .user p {
    color: #525252;
    font-size: 14px;
    font-weight: 600
}

.popup-container .my-qr-code {
    margin: 0 auto 30px
}

.popup-container .my-qr-download {
    cursor: pointer;
    margin: 0 auto 30px;
    padding: 5px;
    text-align: center;
    width: 200px
}

.addToHomeClassic2 {
    align-items: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 44px;
    justify-content: space-between;
    line-height: 19px;
    padding-left: 14px;
    width: 100%
}

.addToHomeClassic2,
.addtoHomeImage {
    border-radius: 13px;
    -moz-border-radius: 13px;
    -webkit-border-radius: 13px
}

.addtoHomeImage {
    margin: 20px auto 0;
    max-width: 80%
}

.popup-container .userDetails figure img {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.navbar-toggler {
    background: #fff
}

.navbar-nav li a {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #2d2d2d, #2d2d2d);
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font: 700;
    transition: all .3s ease
}

.navbar-nav li a:hover,
.navbar-nav li.active a {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #dbb24b, #926903);
    -webkit-background-clip: text;
    background-clip: text;
    border: none;
    font-size: 16px;
    line-height: 24px
}

.navbar-nav li.active a {
    font-weight: 600
}

.navbar-nav li .btn {
    align-items: center;
    background: linear-gradient(90deg, #dbb24b, #926903);
    border: none;
    color: #795909;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    gap: 17px;
    height: 47px;
    line-height: 24px;
    padding: 0 22px;
    text-align: center
}

.navbar-nav li .btn:hover {
    border: none;
    border-radius: 5px;
    color: #fff
}

.navbar-nav li .btn .span {
    width: 20px
}

.nav-item {
    align-items: center;
    display: flex;
    padding: 0 15px
}

.zeeqr-section .zeeqr-section_p {
    display: flex;
    font-size: 16px;
    gap: 10px
}

.zeeqr-section .zeeqr-section_p:after {
    background: #d9d9d9;
    content: "";
    display: flex;
    height: 2px;
    margin-top: 14px;
    width: 60%
}

@media (min-width:768px) {
    .zeeqr-section .zeeqr-section_p {
        display: flex;
        font-size: 16px;
        margin-top: 14px
    }
}

.zeeqr-section .btn {
    align-items: center;
    border-radius: 4.31476px;
    color: #000;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    height: 46px;
    justify-content: center;
    width: 147px
}

.zeeqr-section .btn:hover {
    background-color: #000;
    color: #fff
}

@media (min-width:768px) {
    .zeeqr-section .btn {
        color: #000;
        margin-bottom: 40px;
        width: 147px
    }
}

.cartCountRound {
    align-items: center;
    background: linear-gradient(180deg, #d5ab42, #9d730b);
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    display: flex;
    font-size: 11px;
    font-weight: 700;
    height: 20px;
    justify-content: center;
    position: absolute;
    right: -2px;
    text-align: center;
    top: 0;
    width: 20px
}
.material{
    color: #FFAA12;
}
.materialSelect {
    background:linear-gradient(92deg, #D38800 5.78%, #D48800 17.09%, #FEB42E 41.12%, #FCB12B 57.14%, #DD9411 77.87%, #FFB52F 96.25%);
    color: #4D3000;
}

.material,
.materialSelect {
    align-items: center;
    border: 1px solid #FD5;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    font-size: small;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;

}
.ant-color-picker-trigger{
    background: linear-gradient(92deg, #D38800 5.78%, #D48800 17.09%, #FEB42E 41.12%, #FCB12B 57.14%, #DD9411 77.87%, #FFB52F 96.25%);
        border-color: #Fd5;
}

.card-container {
    margin: 0 auto 50px;
    max-width: 1140px;
    width: 100%
}

/* @media (max-width:768px) {
    .card-container {
        padding: 0 20px
    }
} */

.card-container.m-b-8 {
    margin-bottom: 100px
}

.previewWrapBlock {
    display: flex;
    height: calc(100vh - 82px);
    margin: 0 auto;
    max-width: 1140px
}

.previewContainerMyorders {
    margin: 0 auto 50px;
    max-width: 1140px
}

.previewContainer {
    margin: 0 auto
}

.previewWrapBlock .ant-collapse.border-red-500 {
    border: none
}

.previewWrapBlock .ant-collapse.border-red-500 .ant-collapse-item {
    border: 1px solid #ef4444;
    border-radius: 8px
}

.mobilePreview {
    background-size: 380px;
    max-height: 860px;
    padding-top: 19px;
    transform: scale(.8);
    -moz-transform: scale(.8);
    -webkit-transform: scale(.8);
    transform-origin: center right;
    -moz-transform-origin: center right;
    -webkit-transform-origin: center right
}

.mobilePreview,
.mobilePreviewB {
    box-sizing: border-box;
    height: 100vh;
    width: 380px
}

.mobilePreviewB {
    background-size: 376px;
    max-height: 740px;
    padding-bottom: 22px
}

.themepreviewWrap {
    border-radius: 44px;
    height: calc(100vh - 16px);
    margin: 0 auto;
    max-height: 722px;
    overflow: auto;
    width: 340px
}

.themepreviewWrap::-webkit-scrollbar {
    display: none
}

.themepreviewWrap {
    -ms-overflow-style: none;
    scrollbar-width: none
}

.overlayLoader {
    align-items: center;
    background: hsla(0, 0%, 100%, .8);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10
}

.overlayLoader .ant-spin .ant-spin-dot-item {
    background: #ea580c
}

button.text-white:focus {
    box-shadow: none
}

.ant-modal .ant-btn-primary {
    background: #ea580c;
    box-shadow: none
}

.ant-modal .ant-btn-primary:focus,
.ant-modal .ant-btn-primary:hover {
    background: #d24c05
}

.ant-modal .ant-btn {
    height: 40px;
    padding: 5px 25px
}

.w-100 {
    width: 100%
}

.previewWrapBlock .ant-collapse p {
    margin: 0
}

.cartItemWrap {
    padding: 15px 50px
}

.remove-section {
    border-bottom: 2px solid #d9d9d9;
    color: #6e6e6e;
    padding-bottom: 20px
}

.remove-section p {
    line-height: 20px;
    margin: 0
}

.remove-section p:hover {
    color: red
}

.remove-section img {
    height: 20px;
    object-fit: cover;
    width: 20px
}

.trigger {
    background: #001529;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    line-height: 65px;
    transition: color .3s;
    width: 40px
}

.trigger:hover {
    color: #1890ff
}

.logo {
    margin: 16px
}

.remove-button {
    /* background-color: #f1f1f1; */
    /* font-size: 25px; */
    padding: 5px 4px;
    color: #C2C2C2;
    transition: 0.5s;
    background-color: #6C1313;
        border-radius: 25px;
        color: #FF2222;
}

.remove-button:hover {
    color: #fb3d3d;
}

.sharelink {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}

.sharebutton {
    border: 1px solid #eaeaea;
}

#htmltoimage {
    position: fixed;
    top: -500%;
    width: 750px;
    height: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    border: 5px solid #ff5c00;
}
.share-model .ant-modal-header {
    background-color: #888;
}