.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.modal_overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.modal_overlay .ant-form .ant-input {
  height: 42px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.modal_overlay .ant-form .ant-btn {
  height: 52px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.modal_overlay .ant-form p {
  text-align: left;
  margin-top: 5px;
  font-size: 12px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.card-counts .ant-statistic-content {
  display: flex;
  font-size: 38px;
  font-weight: 400;
}

.card-counts .ant-statistic-content .ant-statistic-content-prefix {
  display: flex;
  align-items: center;
  font-size: 34px;
  margin-right: 10px;
}

.ant-layout .sideWrap .ant-layout-sider {
  min-height: 100%;
}

.add-button {
  height: 38px;
  background-color: #1677ff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  gap: 5px;
  font-weight: 500;
  padding: 5px 15px;
  transition: all 0.3s ease;
}

.add-button:hover {
  color: #fff;
  background-color: #1369e1;
}

.add-button .anticon {
  font-size: 18px;
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}