@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body,
*,
button {
  font-family: 'Poppins', sans-serif;
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    padding: 50px 0 10px;
  }
}

.header_bg {

  background: url("../../../assests/imges/contact.png");
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}

.header1 {
  width: 100%;
  background-color: rgba(0, 0, 0, .2);
}



.container {
  margin: 0 20px;
}

@media (min-width: 768px) {
  .container {
    width: 1200px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    margin: 30px 30px 80px 30px;
  }

}


@media (min-width: 992px) {
  .container {
    width: 1250px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}




.innerPagebanner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

@media (min-width: 769px) {
  .innerPagebanner {
    height: 250px;

  }
}

.innerPagebanner .blog_banner_text1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  color: white;
  margin-bottom: 0;

  @media (min-width: 992px) {
    font-size: 60px;
    line-height: 78px;
  }
}

.innerPagebanner .blog_banner_text2 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: white;
  margin-bottom: 15px;

  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 28px;
  }
}

// ==========================================pricing_section=====================================================================

.pricing_section{
  // height: 100vh;
  margin-bottom: 100px;

}

  
.pricing_container{
  max-width: 1250px;
  height: 100%;
  margin: auto;
  }

.pricing_content_wrapper{
  height: 100%;
}
.pricing_top_container{
  height: 20%;
  display: flex;
  padding: 40px;
}
.top_container_left{
  height: 80%;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.top_container_left h1{
  font-size: 2rem;
  font-weight: 600;
}
.top_container_left p{
  color: #CCCCCC;
  font-weight: 600;
  font-size: 1rem;
}
.top_container_right{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.top_container_right button{
  background: linear-gradient(90deg, #DBB24B 0%, #966C05 100%);

  padding: 1.2rem;
  border-radius: 40px;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1;
  font-weight: 600;
}
.pricing_bottom_container{
}
.pricing_card_wrapper{
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  align-items: center;
  
}




// ==========================================pricing_section_end=====================================================================

// ==============================================price_card_start=========================================================
.price_card_container{
  width: 300px;
  height: 500px;
  background-color: #1A1A1A;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #fff;
  margin-top: 20px;
}
.price_card_content_wrapper{
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card_top_container{
height: 40%;
display: flex;
flex-direction: column;
gap: 1rem;
color: #fff;
}
.price_text{
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
}
.card_top_container{
  font-size: 1rem;
}
.card_top_container h2{
  font-size: 1.2rem;
}
.card_top_container h2,.card_top_container  p{
  color: #fff;
}
.card_bottom_container{
height: 60%;
}
.card_bottom_container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}
.card_btn button,.selected_card_btn button{
  border: 2px solid #DBB24B;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: 700;
}
.selected_card_btn button{
  border: 2px solid #fff;

}

.card_items{
  display: flex;
  gap: 10px;
  align-items: center;
  
}
.card_items h2{
  color: #fff;

}
.card_icons{
  color: #DBB24B;
}
.selected_card_icons{
  color: #fff;
}
.selected_price_card_container{
  width: 300px;
  height: 510px;
  background: linear-gradient(180deg, #DBB24B 0%, #6A4D03 100%);
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #fff;
  margin-top: 20px;

}



// ==============================================price_card_end=========================================================



