.SocialMediaContainer {
    padding-top: 10px;
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    .leftArrow {
        padding-right: 5px;
    }

    .rightArrow {
        padding-left: 5px;
    }

    .SliderContainer {
        width: 100%;
        --icon-size: 45px;
        height: var(--icon-size);
        width: 100%;
        overflow-x: auto;

        .slideItemDiv {
            width: var(--icon-size);
            display: flex;
            align-items: center;
            justify-content: center;
            /* background-color: #ededed; */
            margin: 0px auto;

            img {
                height: var(--icon-size);
                width: var(--icon-size);
                object-fit: contain;
            }
        }

    }
}

.TruncateSocialContainer {
    position: relative;


    .TruncateSocialItemContainer {
        display: flex;
        align-items: center;

        .TruncateSocialItemWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }
    }

    .TruncateSocialItem {
        margin-left: -10px;
        position: relative;
        width: 20px;
        height: 20px;
        // background-color: #fff;
        // border-radius: 20px;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        filter: drop-shadow(0px 0px 4px #0000003d);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .TruncateSocialItem:nth-child(1) {
        z-index: 3;
    }

    .TruncateSocialItem:nth-child(2) {
        z-index: 2;
    }

    .TruncateItemCount {
        border-radius: 20px;
        background: #E5EEFF;
        padding: 3px 10px;

        p {
            color: #3771C8;
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .DropdownContainer {
        position: absolute;
        top: 28px;
        right: 0px;
        width: 120px;
        z-index: -1;
        transition: 0.5s;
        opacity: 0;
        transform: translateY(-50px);
        // max-height: 250px;
        // overflow: auto;
        // padding: 10px;

        .DropdownItemContainer {
            padding: 5px 5px;
            background-color: #fff;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 6px;
            max-height: 250px;
            overflow: auto;

            .DropdownItem {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 6px 8px;
                cursor: pointer;
                border-radius: 4px;

                .Logo {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                p {
                    color: #555;
                    font-family: Inter;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .DropdownItem:hover {
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
    }

    .DropdownContainer.isActive {
        .DropdownContainer {
            transform: translateY(0px);
        }

    }

    .DropdownContainer::before {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #fff;
        position: absolute;
        top: -5px;
        transform: rotate(-45deg);
        left: 50%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        z-index: -1;
    }
}