@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

// font-family: 'Antonio', sans-serif;

// font-family: 'Montserrat', sans-serif;
.frontSideCard {
    display: flex;
    grid-template-columns: 56% 44%;
    height: 222px;
    width: 350px;
    padding: 7px 17px 7px 7px;
    border-radius: 8px;
    user-select: none;
    background-size: cover;
    background-repeat: no-repeat;

    .leftSide {
        align-self: flex-end;

    }

    .rightSide h3 {
        margin-bottom: 0px;
        text-transform: uppercase;
        font-weight: 300 !important;
    }

    .rightSide span {
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }


    .rightSide {
        align-self: center;
        margin-left: auto;
        text-align: left;
        max-width: 45%;
    }

    .rightSide .nameContainer {
        position: relative;
        padding-bottom: 5px;
    }

    .rightSide .strip {
        width: 100%;
        height: 1px;
        // background: #d4ad62;
        // background: linear-gradient(90deg, #d4ad62 20%, #7d3f29 100%);
        background: #d4ad62;
        background: linear-gradient(90deg, #7d3f29 20%, #d4ad62 100%);
        position: absolute;
        bottom: 0px;
        left: 0px;
        border-radius: 8px;
    }

    .LogoImage {
        margin-bottom: 20%;
        object-fit: cover;
        max-height: 50px;
    }




    .qrcode {
        border-radius: 0px;
        width: 55px;
        height: 55px;
    }

    .personalDetails {
        line-height: 13px;
        margin-top: 7px;
    }

    .personalDetails .Item {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        justify-content: flex-start;
        margin-bottom: 5px;
    }

    .personalDetails .Item small {
        font-size: 10px;
        font-weight: 300 !important;
        flex: 1;
        flex-grow: 1;
    }

    .personalDetails .Item .Icon {
        margin-top: 3px;
        flex-shrink: 1;
    }


}

.backSideCard {
    height: 222px;
    width: 350px;
    min-width: 350px;
    min-height: 222px;
    padding: 20px 20px;
    border-radius: 8px;
    user-select: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.backSideCard img {
    width: 150px;
    height: 150px;
    margin: 0px auto;
    object-fit: contain;

}

@media (max-width:600px) {

    .frontSideCard,
    .backSideCard {
        width: 100%;
        min-width: 100%;
        height: 191px;
        min-height: auto;
    }
}