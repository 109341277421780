.get_your_card {
    max-width: 1250px;
    margin: 0 auto 0px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    border-radius: 30px;
    // z-index: 0;

    .white_bg {
        background: #fff;
        position: absolute;
        width: 90%;
        height: 100%;
        border-radius: 30px;
        z-index: 0;
        top: 0px;
        right: 0px;

    }
}

@media (min-width: 992px) {
    .get_your_card {
        width: 100%;
        position: relative;
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
    }

}

@media (max-width:600px) {
    .get_your_card {
        margin: 0px;
    }
}

@media (min-width: 768px) {
    .get_your_card .getyour_card_text {
        background: linear-gradient(90deg, #2A2A2A 40.27%,
                rgba(42, 42, 42, 0.75) 48.93%,
                rgba(42, 42, 42, 0.00) 80.17%);
        border-radius: 30px;
        position: relative;
        z-index: 4;

    }

    .get_your_card .getyour_card_text::before {
        content: "";
        background: url(../../../../assests/imges/get_your_card_bg.png), linear-gradient(294deg, rgba(255, 255, 255, 1) 0%, rgba(55, 55, 55, 1) 100%);
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
        position: absolute;
        right: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .get_your_card .getyour_card_text p {
        color: #B4B4B4;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

@media (min-width: 992px) {
    .get_your_card .getyour_card_text p {
        color: #B4B4B4;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

}

.row_your_card {
    position: relative;
    z-index: 9;
    padding: 34px 34px 32px 59px;
    background: #2B2B2B;
    border-radius: 30px;
    background: linear-gradient(90deg, #2A2A2A 40.27%,
            rgba(42, 42, 42, 0.75) 48.93%,
            rgba(42, 42, 42, 0.00) 80.17%);

    h1 {
        background: linear-gradient(91deg, #F0BF73 7.13%,
                rgba(204, 158, 72, 1.00) 70.29%, rgba(46, 167, 255, 0.99) 80.01%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Inter;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0px;
        width: fit-content;
    }

    p {
        color: #B4B4B4;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 48px;
    }

}

@media (max-width:560px) {
    .get_your_card {
        padding: 0px 0px;

        .white_bg {
            background: transparent;
        }
    }

    .row_your_card {
        padding: 34px 34px 34px 34px;
        background: #2B2B2B;
        border-radius: 30px;

        h1 {
            font-size: 32px;
        }

        p {
            font-size: 16px;
            margin-bottom: 18px;
        }

    }

}

@media (min-width: 768px) {
    .row_your_card {
        display: flex;
    }
}

@media (min-width: 992px) {
    .row_your_card {
        display: flex;
        // width: fit-content;
    }
}

.get_your_card .btn_get_zeeqr {
    width: 147px;
    color: #4D3000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    height: 46px;
    font-weight: 700;
    justify-content: center;
    border: 0px;
    border-radius: 10px;
    background: linear-gradient(94deg, #F1B63F 46.68%, #E0A819 102.15%);
    box-shadow: 0px 11px 9px 0px rgba(255, 163, 0, 0.13);
}


.get_your_card .btn_get_zeeqr:hover {
    background-color: #FFFFFF;
    color: #7A5908;

}

@media (max-width:560px) {
    .get_your_card {
        padding: 0px 0px;

        .white_bg {
            background: transparent;
        }
    }

    .row_your_card {
        padding: 34px 34px 34px 34px;
        background: #2B2B2B;
        border-radius: 30px;

        h1 {
            font-size: 30px;
        }

        p {
            font-size: 16px;
            margin-bottom: 18px;
        }

    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .get_your_card .getyour_card_text::before {
        content: "";
        background: url(../../../../assests/imges/get_your_card_bg.png), linear-gradient(294deg, rgba(255, 255, 255, 1) 0%, rgba(55, 55, 55, 1) 100%);
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
        position: absolute;
        right: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .row_your_card {
        padding: 34px 34px 34px 34px;
        border-radius: 30px;

        h1 {
            font-size: 40px;
        }

        p {
            font-size: 16px;
            margin-bottom: 18px;
        }

    }
}