@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body,
*,
button {
  font-family: 'Poppins', sans-serif;
}

.wrapper {
  background-color: #151515;

}

.mainContainer {
  background: #151515;
  background-image: url('../../../assests/imges/vector/bg-image.svg');
  background-repeat: repeat;
  border-radius: 20px;
  z-index: 2;
  position: relative;
  margin-top: -30px;
}




.header_bgabout {

  background-position: left;
  background: url("../../../assests/imges/about.png");
  background-size: cover;
  padding: 150px 0px 80px;
}



.container {
  margin: 0 20px;
  padding: 0px 20px;
}

@media (max-width:560px) {
  .container {
    margin: 0;
    padding: 0px 20px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 1200px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    margin: 30px 30px 80px 30px;
  }

}


@media (min-width: 992px) {
  .container {
    max-width: 1250px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}




.innerPagebanner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

@media (min-width: 769px) {
  .innerPagebanner {
    height: 250px;

  }
}

.innerPagebanner .blog_banner_text1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  color: white;
  margin-bottom: 0;

  @media (min-width: 992px) {
    font-size: 60px;
    line-height: 78px;
  }
}

.innerPagebanner .blog_banner_text2 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: white;
  margin-bottom: 15px;

  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 28px;
  }
}



// }
@media (min-width: 768px) {
  .about_product .rowabout_product {
    display: flex;
    flex-direction: start;
  }
}

// }
@media (max-width: 820px) {
  .about_product .rowabout_product {
    display: flex;
    flex-direction: start;
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .about_proabout_productduct_zeeqr .rowabout_product {
    display: flex;
    flex-direction: start;
  }
}

.innerPagebanner .blog_banner_text2 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15.5068px;
  line-height: 23px;
  color: white;
}

.innerPagebanner .blog_banner_text2 a {
  text-decoration: none;
  color: white;
}


.about_product_zeeqr {
  padding-top: 50PX;
}

@media (min-width: 768px) {
  .about_product_zeeqr {
    padding-top: 80px;
  }


}

@media (min-width: 992px) {
  .about_product_zeeqr {
    padding-top: 100px;
  }



}

.toggle_page {
  background-color: white;
  border-radius: 15px;
}

@media (min-width: 768px) {
  .toggle_page {
    background-color: white;
    border-radius: 15px;

  }
}

@media (min-width: 992px) {
  .toggle_page {
    background-color: white;
  }
}

.about_product .about_product_p1 {
  display: flex;
  gap: 10px;
  color: #DBB24B;
}

.about_product .about_product_p1:before {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  background: #DBB24B;
  margin-top: 10px;


}

.about_product .about_product_p2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;  
  background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}

@media (min-width: 768px) {
  .about_product .about_product_p2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 55px;
    width: 700px;
  }

}

@media (min-width: 992px) {
  .about_product .about_product_p2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 65px;
    width: 700px;
  }

}

@media (min-width: 576px) and (max-width: 767.98px) {
  .about_product {
    .rowabout_product {
      // display: grid;
      // grid-template-columns: 50% 50%;
    }
  }

  .about_product .about_product_p2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 65px;    
  }  
  .row_eco_friendly_nfc .zeeqr_card_img{
    max-width: 60%;
    margin: auto;
  }
}

.about_product .about_product_p3 {

  font-weight: 500;
  font-size: 14.49px;
  line-height: 24px;
  padding: 8px;
}

@media (min-width: 768px) {

  .about_product .about_product_p3 {
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    width: 708px;
    height: 140px;
  }
}

@media (min-width: 992px) {
  .about_product .about_product_p3 {
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    width: 698px;
    height: 180px;
  }

}

.about_product .about_product_p4 {
  font-weight: 400;
  font-size: 14.49px;
  line-height: 24px;
  padding: 5px;
  color: #333333;


}

@media (min-width: 768px) {
  .about_product .about_product_p4 {
    font-weight: 400;
    font-size: 18.49px;
    line-height: 28px;
    width: 706px;
    line-height: 28px;
    color: #333333;



  }

}

@media (min-width: 992px) {
  .about_product .about_product_p4 {
    font-weight: 400;
    font-size: 18.49px;
    line-height: 28px;
    width: 696px;
    height: 90px;
    color: #333333;


  }

}

.about_product .about_product_zeeqr_img {
  object-fit: cover;
}

@media (min-width: 768px) {
  .about_product .about_product_zeeqr_img {

    object-fit: contain;
    width: 528px;
    height: 466px;
    margin: auto;
  }

}

@media (min-width: 992px) {
  .about_product .about_product_zeeqr_img {
    object-fit: contain;
    width: 528px;
    height: 466px;
  }

}

.zeeqr_Services {
  margin-top: 60px;
}


@media (min-width: 768px) {
  .zeeqr_Services {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .zeeqr_Services {
    margin-top: 70px;
  }
}

.zeeqr_Services .zeeqr_Services_p1 {
  font-weight: 600;
  font-size: 16.3961px;
  line-height: 25px;
  letter-spacing: 0.07em;
  color: #D18C0D;
  justify-content: center;
  display: flex;
  gap: 10px;
}

.zeeqr_Services .zeeqr_Services_p1:after {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  background: #DBB24B;
  margin-top: 10px;

}

.zeeqr_Services .zeeqr_Services_p1:before {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  background: #DBB24B;
  margin-top: 10px;

}

@media (min-width: 768px) {
  .zeeqr_Services .zeeqr_Services_p1 {
    font-weight: 600;
    font-size: 16.3961px;
    line-height: 25px;
    letter-spacing: 0.07em;
    color: #D18C0D;
    text-align: center;
  }

}

@media (min-width: 992px) {

  .zeeqr_Services .zeeqr_Services_p1 {
    font-weight: 600;
    font-size: 16.3961px;
    line-height: 25px;
    letter-spacing: 0.07em;
    color: #D18C0D;
    text-align: center;
  }
}

.zeeqr_Services .zeeqr_Services_p2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #000000;
  text-align: center;
  background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 768px) {
  .zeeqr_Services .zeeqr_Services_p2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 78px;
    color: #000000;
    text-align: center;
    background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}

@media (min-width: 992px) {

  .zeeqr_Services .zeeqr_Services_p2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 78px;
    color: #000000;
    text-align: center;
    background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}

.zeeqr_Services .zeeqr_Services_p3 {
  font-weight: 500;
  font-size: 18.49px;
  line-height: 28px;
  text-align: center;
  color: #D18C0D;
  max-width: 923px;
  margin: auto;

}

@media (min-width: 768px) {
  .zeeqr_Services .zeeqr_Services_p3 {
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    text-align: center;
    color: #D18C0D;
    max-width: 923px;
    margin: auto;

  }

}

@media (min-width: 992px) {
  .zeeqr_Services .zeeqr_Services_p3 {
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    text-align: center;
    color: #D18C0D;
    max-width: 923px;
    margin: auto;

  }

}

.zeeqr_Services_card {
  margin-top: 50px;

  img {
    margin: auto;
  }


}

.zeeqr_Services_card .card {

  padding: 20px;

}

@media (min-width: 768px) {
  .zeeqr_Services_card .card {

    gap: 20px;
  }

}

.zeeqr_Services_card .card .dots .dot {
  width: 9px;
  height: 9px;
  background: #DBB24B;
  border-radius: 50%;

}

.zeeqr_Services_card .card .dots {
  margin: 0 auto;
  width: 40px;
  display: flex;
}

@media (min-width: 992px) {

  .zeeqr_Services_card .card {

    gap: 20px;
  }

}

// .zeeqr_Services_card .card .card-img-top {}

.zeeqr_Services_card .card .card_title {
  font-style: normal;
  font-weight: 700;
  font-size: 20.49px;
  line-height: 31px;
  width: 166px;
  color: #FFFFFF;

}

.zeeqr_Services_card .card .card_text {
  font-weight: 300;
  font-size: 16.49px;
  line-height: 28px;
  color: #FCFCFC;
}

.slider .btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 259px;
  height: 47px;
  border: 0px;
  border-radius: 10px;
  background: linear-gradient(94deg, #F1B63F 46.68%, #E0A819 102.15%);

}

.slider .btn:hover {
  color: #fff;
  background: linear-gradient(90deg, #DDB34A 0%, #966C05 100%);
  border: none;
}

.eco_friendly_nfc {
  margin-top: 70px;
}

@media (min-width: 768px) {
  .eco_friendly_nfc {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .eco_friendly_nfc {
    margin-top: 100px;
  }

}

.eco_friendly_nfc .eco_friendly_zeeqr_p_1 {
  font-weight: 600;
  font-size: 17.9628px;
  line-height: 24px;
  letter-spacing: 0.07em;
  margin-top: 20px;
  color: #DBB24B;
  display: flex;
  gap: 10px;

}

.eco_friendly_nfc .eco_friendly_zeeqr_p_1:before {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  background: #DBB24B;
  margin-top: 10px;

}

@media (min-width: 768px) {
  .eco_friendly_nfc .eco_friendly_zeeqr_p_1 {
    font-weight: 600;
    font-size: 15.9628px;
    line-height: 24px;
    letter-spacing: 0.07em;
    color: #DBB24B;

  }
}

@media (min-width: 992px) {
  .eco_friendly_nfc .eco_friendly_zeeqr_p_1 {
    font-weight: 600;
    font-size: 15.9628px;
    line-height: 24px;
    letter-spacing: 0.07em;
    color: #DBB24B
  }

}

.eco_friendly_nfc .eco_friendly_zeeqr_p_2 {

  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  width: 300px;
  background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 768px) {
  .eco_friendly_nfc .eco_friendly_zeeqr_p_2 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 76px;
    width: 700px;
    background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}

@media (min-width: 992px) {
  .eco_friendly_nfc .eco_friendly_zeeqr_p_2 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 76px;
    width: 637px;
    // height: 176px;
    background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }


}

.eco_friendly_nfc .eco_friendly_zeeqr_p_3_p {
  font-weight: 500;
  font-size: 18.49px;
  line-height: 28px;
  color: #F6CD66;

}

@media (min-width: 768px) {
  .eco_friendly_nfc .eco_friendly_zeeqr_p_3_p {
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    width: 635px;
    color: #F6CD66;

  }
}

@media (min-width: 992px) {
  .eco_friendly_nfc .eco_friendly_zeeqr_p_3_p {
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    width: 635px;
    height: 131px;
    color: #F6CD66;

  }

}

.eco_friendly_nfc .row_ecofriendly {
  margin-top: 30px;
}

.eco_friendly_nfc .row_ecofriendly .eco_friendly_zeeqr_p_3 {
  font-style: normal;
  font-weight: 500;
  font-size: 18.0014px;
  line-height: 27px;
  /* identical to box height */


  color: #A5772B;

}

.eco_friendly_zeeqr_p_p {
  font-weight: 500;
  font-size: 18.49px;
  line-height: 28px;
  width: 190px;
  color: #F6CD66;


}

@media (min-width: 768px) {
  .eco_friendly_zeeqr_p_p {
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    width: 567px;
    color: #F6CD66;


  }
}

@media (min-width: 992px) {
  .eco_friendly_zeeqr_p_p {
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    width: 567px;
    color: #F6CD66;


  }
}

.eco_friendly_nfc .eco_friendly_zeeqr_p_4 {
  font-style: normal;
  font-weight: 400;
  font-size: 17.3701px;
  line-height: 26px;

  color: #F6CD66;
}

.eco_friendly_nfc .row_ecofriendly .eco_friendly_zeeqr_p_5 {
  font-style: normal;
  font-weight: 500;
  font-size: 18.0014px;
  line-height: 27px;
  /* identical to box height */


  color: #A5772B;

}

.eco_friendly_nfc .eco_friendly_zeeqr_p_6 {
  font-style: normal;
  font-weight: 400;
  font-size: 17.3701px;
  line-height: 26px;

  color: #F6CD66;
}


.row_ecofriendly_nfc {
  display: flex;
  gap: 20px;



}

.row_ecofriendly {
  display: flex;
  gap: 20px;
}

.eco_friendly_nfc .zeeqr_card_img {
  object-fit: cover;



}

// .eco_friendly_nfc .row_eco_friendly_nfc{



// }
@media (min-width: 768px) {
  .eco_friendly_nfc .row_eco_friendly_nfc {

    display: flex;

  }

}

@media (min-width: 992px) {

  .eco_friendly_nfc .row_eco_friendly_nfc {

    display: flex;

  }

}


// @media (min-width: 768px) {
//   .eco_friendly_nfc .zeeqr_card_img {
//     object-fit: cover;


//   }


// } 

//  @media (min-width: 992px) {
//   .eco_friendly_nfc .zeeqr_card_img {
//     object-fit: cover;


//   }
// }

.profiles {
  padding: 130px 0;
  margin-top: 50px;

}




.profiles {
  background: linear-gradient(rgba(0, 0, 0, .30), rgba(0, 0, 0, .30)), url("../../../assests/imges/pro.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px;



}

.profiles h2 {
  font-weight: 600;
  font-size: 57.2339px;
  line-height: 60px;
  gap: 10px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;

  color: #FFFFFF;
}

@media (min-width: 768px) {
  .profiles h2 {
    font-size: 52px;
    line-height: 58px;
    gap: 10px;

  }
}

@media (min-width: 1200px) {
  .profiles h2 {
    font-size: 72px;
    line-height: 80px;
    gap: 10px;
    padding: 20px;

  }
}

.profiles .small {
  font-weight: 600;
  font-size: 32px;
  line-height: 50px;
  justify-content: center;
  align-items: center;
  color: #FCFCFC;
}

@media (min-width: 768px) {
  .profiles .small {
    font-size: 38px;
    line-height: 52px;

  }
}

@media (min-width: 1400px) {
  .profiles .small {
    font-size: 42px;
    line-height: 60px;

  }
}

.profiles span {
  width: 13px;
  height: 13px;
  display: inline-block;
  background: #DBB24B;
  line-height: 13px;
  overflow: hidden;
  border-radius: 50%;
  ;
  text-indent: -9999px;
  position: relative;
  top: -5px;
}

@media (min-width: 1400px) {
  .profiles span {
    top: -8px;
  }
}

@media (max-width: 768px) {
  .profiles span {
    display: none;
  }
}

.profiles .nfc_text {
  width: 394;
  font-size: 18.49px;
  line-height: 28px;
  text-align: center;
  padding-bottom: 20px;
  color: #FFFFFF;
  max-width: 894px;
  margin: auto;

}

.eco_friendly {
  margin-top: 70px;
  margin-bottom: 150px;
}

@media (min-width: 768px) {
  .eco_friendly {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .eco_friendly {
    margin-top: 70px;
  }

}

.eco_friendly .eco_friendly_zeeqr_p_1 {
  font-weight: 600;
  font-size: 17.9628px;
  line-height: 24px;
  letter-spacing: 0.07em;
  margin-top: 20px;
  color: #F6CD66;
  display: flex;
  gap: 10px;

}

.eco_friendly .eco_friendly_zeeqr_p_1:before {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  background: #DBB24B;
  margin-top: 10px;

}

@media (min-width: 768px) {
  .eco_friendly .eco_friendly_zeeqr_p_1 {
    font-weight: 600;
    font-size: 15.9628px;
    line-height: 24px;
    letter-spacing: 0.07em;
    color: #F6CD66;

  }
}

@media (min-width: 992px) {
  .eco_friendly .eco_friendly_zeeqr_p_1 {
    font-weight: 600;
    font-size: 15.9628px;
    line-height: 24px;
    letter-spacing: 0.07em;
    color: #F6CD66;

  }

}

.eco_friendly .eco_friendly_zeeqr_p_2 {

  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  width: 300px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

@media (min-width: 768px) {
  .eco_friendly .eco_friendly_zeeqr_p_2 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    width: 700px;
    background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}

@media (min-width: 992px) {
  .eco_friendly .eco_friendly_zeeqr_p_2 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    width: 645px;
    margin-bottom: 30px;
    background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }


}

.eco_friendly .row_ecofriendly .eco_friendly_zeeqr_p_3 {
  font-style: normal;
  font-weight: 500;
  font-size: 18.0014px;
  line-height: 27px;
  margin: 0;
  /* identical to box height */


  color: #D18C0D;

}

.eco_friendly .eco_friendly_zeeqr_p_4 {
  font-style: normal;
  font-weight: 400;
  font-size: 17.3701px;
  line-height: 26px;
  max-width: 358px;
  height: 194px;
  color: #F6CD66;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .eco_friendly .eco_friendly_zeeqr_p_4 {
    font-style: normal;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;
    max-width: 698px;
    height: 104px;
    color: #F6CD66;
    margin-top: 10px;
  }

}


@media (min-width: 992px) {
  .eco_friendly .eco_friendly_zeeqr_p_4 {
    font-style: normal;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;
    max-width: 698px;
    height: 104px;
    color: #F6CD66;
    margin-top: 10px;
  }

}


.eco_friendly .row_ecofriendly .eco_friendly_zeeqr_p_5 {
  font-style: normal;
  font-weight: 500;
  font-size: 18.0014px;
  line-height: 27px;
  /* identical to box height */
  max-width: 200px;

  color: #D18C0D;

}

.eco_friendly .eco_friendly_zeeqr_p_6 {
  font-style: normal;
  font-weight: 400;
  font-size: 17.3701px;
  line-height: 26px;
  max-width: 358px;
  height: 198px;
  margin-top: 10px;
  color: #F6CD66;
}

@media (min-width: 768px) {

  .eco_friendly .eco_friendly_zeeqr_p_6 {
    font-style: normal;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;
    width: 698px;
    height: 98px;
    margin-top: 10px;
    color: #F6CD66;
  }


}

@media (min-width: 992px) {
  .eco_friendly .eco_friendly_zeeqr_p_6 {
    font-style: normal;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;
    width: 698px;
    height: 98px;
    margin-top: 10px;
    color: #F6CD66;
  }
}

.row_ecofriendly {
  display: flex;
  gap: 20px;
  align-items: center;
}



.eco_friendly .zeeqr_card_img {
  width: 100%;

}

@media (min-width: 768px) {
  .eco_friendly .zeeqr_card_img {
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 650px;
    margin-bottom: 0;
  }

  .eco .col {
    margin-right: 30px
  }

}

@media (min-width: 992px) {
  .eco_friendly .zeeqr_card_img {
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 650px;
    margin-bottom: 0;
    margin-right: 30px;

  }

  .eco .col {
    margin-right: 30px
  }

}

// .eco_friendly .eco{

// }

@media (min-width: 768px) {
  .eco_friendly .eco {
    display: flex;
    flex-direction: start;
  }

}

@media (min-width: 992px) {
  .eco_friendly .eco {
    display: flex;
    flex-direction: start;
  }
}


/* @media (min-width: 768px) {
  .eco_friendly .zeeqr_card_img {
    object-fit: contain;


  }

}

@media (min-width: 992px) {
  .eco_friendly .zeeqr_card_img {
    object-fit: contain;


  }

} */





.get_nfc {
  background: linear-gradient(90deg, #DDB34A 0%, #966C05 100%);
  max-width: 1250px;
  margin: 0 auto -150px;
}

@media (min-width: 768px) {
  .get_nfc {
    background: linear-gradient(90deg, #DDB34A 0%, #966C05 100%);
  }
}

@media (min-width: 992px) {
  .get_nfc {
    width: 100%;
    margin: 0 auto -140px;
    position: relative;
    background: linear-gradient(90deg, #DDB34A 0%, #966C05 100%);
  }

}

@media (max-width:600px) {
  .get_nfc {
    margin: 0px;
  }
}

.get_nfc .getnfc_text p {
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  color: #7A5908;
  padding: 25px 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .get_nfc .getnfc_text {
    height: 284px;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .get_nfc .getnfc_text p {
    font-weight: 600;
    font-size: 41px;
    line-height: 55px;
    width: 700px;
    color: #7A5908;
    margin: 0;
    padding: 0;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .get_nfc .getnfc_text p {
    font-weight: 600;
    font-size: 41px;
    line-height: 55px;

    width: 700px;

    color: #7A5908;
  }

}

.row_nfc {
  padding: 10px;
  text-align: center;
}

@media (min-width: 768px) {
  .row_nfc {
    display: flex;
  }
}

@media (min-width: 992px) {
  .row_nfc {
    display: flex;
  }
}

.get_nfc .btn_get_zeeqr {
  height: 60px;
  padding: 0 34px;
  margin-bottom: 40px;

  color: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
}


.get_nfc .btn_get_zeeqr:hover {
  background-color: #FFFFFF;
  color: #7A5908;

}


.footer {
  margin-top: 350px;
  padding-left: 0;
  background: radial-gradient(50% 50% at 50% 50%, #303030 0%, #272727 100%);
}

@media (min-width: 768px) {
  .get_nfc .btn_get_zeeqr {

    margin-bottom: 20px;
    margin-top: 20px;
  }

  .footer {
    margin-top: 350px;
    padding-left: 20px;
  }
}

@media (min-width: 992px) {
  .footer {
    margin-top: 0;
    padding-left: 0;
  }
}

.footer_zeeqr {
  padding-top: 100px;
  margin-top: 0;
  padding-left: 0px;

}

@media (min-width: 768px) {
  .footer_zeeqr {
    padding-top: 0;

  }

}

@media (min-width: 992px) {
  .footer_zeeqr {
    padding-top: 150px;

  }

}

.footer_logo .footer_logo_text {
  font-weight: 400;
  font-size: 17.3701px;
  line-height: 26px;
  color: #D9D9D9;
}

@media (min-width: 768px) {
  .footer_logo .footer_logo_text {
    width: 397px;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;
    color: #D9D9D9;
  }

}

@media (min-width: 992px) {
  .footer_logo .footer_logo_text {
    width: 397px;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;

    color: #D9D9D9;
  }

}

.footer_logo .social_icons img {
  height: 55px;
  gap: 10px;
  padding: 5px;
}

@media (min-width: 768px) {
  .footer_logo .social_icons img {
    height: 55px;
    gap: 10px;
  }

}

@media (min-width: 992px) {
  .footer_logo .social_icons img {
    height: 55px;
    gap: 10px;
  }
}

.social_address .social_address_p {
  font-weight: 600;
  font-size: 21.37px;
  line-height: 32px;
  color: #D9D9D9;
  width: 141px;
  height: 37px;

}

@media (min-width: 768px) {
  .social_address .social_address_p {
    font-weight: 600;
    font-size: 21.37px;
    line-height: 32px;
    color: #D9D9D9;
    width: 141px;
    height: 27px;

  }
}

@media (min-width: 992px) {
  .social_address .social_address_p {
    font-weight: 600;
    font-size: 21.37px;
    line-height: 32px;
    color: #D9D9D9;
    width: 141px;
    height: 27px;

  }

}

.social_address .social_address1 {
  gap: 15px;

}

.social_address .social_address1 p {
  font-weight: 400;
  font-size: 12.7586px;
  line-height: 19px;
  max-width: 220px;
  color: #D9D9D9;
}

.social_address1 a {
  color: #D9D9D9;
  text-decoration: none;
}

/* .nav-footer {} */

@media (min-width: 768px) {
  .nav_footer {
    padding-left: 70px;
  }

}

@media (min-width: 992px) {
  .nav_footer {
    padding-left: 70px;
  }

}

.nav_footer p {
  font-weight: 600;
  font-size: 21.37px;
  line-height: 32px;
  width: 121px;
  color: #D9D9D9;
}

.nav_footer .nav_footer_list p {
  font-weight: 400;
  font-size: 12.7586px;
  line-height: 19px;
  width: 109px;
  height: 29px;
  color: #D9D9D9;
  text-decoration: none;

}

.nav_footer .nav_footer_list p a {
  text-decoration: none;
  color: #fff;
}

// .footercol{

// }
@media (min-width: 768px) {
  .footercol {
    display: flex;
  }

}

@media (min-width: 992px) {
  .footercol {
    display: flex;
  }

}

.colfooter {


  margin-top: 20px;

}

@media (min-width: 992px) {
  .colfooter {

    float: left;
    width: 50%;

  }
}

@media (min-width: 768px) {
  .colfooter {

    float: left;
    width: 50%;

  }

}

.text_white {
  width: 100%;
  height: 2px;

  margin-top: 40px;
  background: #D9D9D9;
  opacity: 0.24;
}

.footer_gallery p {
  font-weight: 600;
  font-size: 21.37px;
  line-height: 32px;

  color: #D9D9D9;
  width: 83px;
}

.footer_gallery .img_zeeqr {
  width: 50px;
}

@media (min-width: 768px) {
  .footer_gallery .img_zeeqr {
    width: 200px;
  }
}

.footer_gallery {
  padding-bottom: 15px;
}

.copyright {
  padding: 0 15px 15px 15px;
}

.copyright .copyright_text {
  font-weight: 400;
  font-size: 12.7586px;
  line-height: 19px;
  text-align: center;
  margin: 30px;
  background: linear-gradient(90deg, #DDB34A 0%, #966C05 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}



#slider-container {
  height: 150px;
  width: 100%;
  max-width: 1000px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  box-shadow: 0 0 15px #888;
}

#slider_scroller {

  top: 0;
  left: 0;
  width: calc(260px * 6);
  transition: all 1s ease;
}

.slider .item {


  display: inline-block;
  margin: 20px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200px;
  max-height: 500px;

}




.slider {
  display: flex;
  width: 100%;

  margin: 0 auto;
  overflow: hidden;
  padding: 2.5rem;
}

.item {
  animation: animate 20s linear infinite;
}

.item img {
  min-width: 250px;
  padding: 0 15px;
}

.slider:hover .item {
  animation-play-state: paused;
}

@keyframes animate {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-1800px, 0, 0);
  }
}

.top_btn .myBtn {
  position: fixed;
  bottom: 10px;
  float: right;
  right: 1.5%;
  margin-bottom: 80px;
  left: 84.25%;




}

@media (min-width: 768px) {
  .top_btn .myBtn {
    position: fixed;
    bottom: 10px;
    float: right;
    margin-bottom: 80px;
    right: 1.5%;
    left: 92.25%;




  }

}

@media (min-width: 992px) {

  .top_btn .myBtn {
    position: fixed;
    bottom: 10px;
    float: right;
    right: 1.5%;
    margin-bottom: 80px;
    left: 94.25%;




  }
}

.top_btn .myBtn1 {
  position: fixed;
  bottom: 10px;
  float: right;
  right: 1.5%;
  left: 84.25%;




}



@media (min-width: 768px) {
  .top_btn .myBtn1 {
    position: fixed;
    bottom: 10px;
    float: right;
    left: 92.25%;
  }
}

@media (min-width: 992px) {

  .top_btn .myBtn1 {
    position: fixed;
    bottom: 10px;
    float: right;
    left: 94.25%;
  }

  .social_address {
    padding: 0 0;
  }
}

.social_address {
  padding: 0 1rem;
}

@media (max-width: 768px) {

  .eco_friendly_nfc,
  .eco_friendly {
    padding: 0 !important;
    margin-top: 20px;
  }

  .row-ecofriendly {
    display: block;
    padding: 0 !important;
  }

  .row-ecofriendly img {
    margin-bottom: 25px;
  }

  .eco_friendly_zeeqr_p_p {
    width: 100%;
  }

  // .eco_friendly_zeeqr .row .col{
  /* padding: 0 !important;
      width: 100%; */
  // }
}

.slider_btn {
  margin-bottom: 100px;
  padding: 20px;
}

@media (min-width: 768px) {
  .slider_btn {
    margin-bottom: 100px;
    padding: 20px;
  }
}

@media (min-width: 992px) {
  .slider_btn {
    margin-bottom: 100px;
    padding: 20px;
  }
}

.slider_btn .btn {
  width: 259px;
  height: 47px;
  font-size: 16px;
  font-weight: 600;
  outline: transparent;
  color: #000000;
  border-radius: 10px;
  background: linear-gradient(94deg, #F1B63F 46.68%, #E0A819 102.15%);
}

@media (min-width: 768px) {
  .slider_btn .btn {
    width: 259px;
    height: 47px;
    outline: transparent;
    color: #000000;
    border-radius: 10px;
    background: linear-gradient(94deg, #F1B63F 46.68%, #E0A819 102.15%);
  }

}

@media (min-width: 992px) {
  .slider_btn .btn {
    width: 259px;
    height: 47px;

    outline: transparent;
    color: #000000;
    border-radius: 5px;

    border: 2px solid #000000;
    transition: all 0.3s ease;

  }
}

.slider_btn .btn:hover {
  color: #fff;
  border: none;
  background: linear-gradient(90deg, #DDB34A 0%, #966C05 100%);
}

.ourVideo {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
}

.ourVideo video {
  width: 100%;
}

@media (min-width: 768px) {
  a.video {
    position: relative;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
    position: absolute;
    width: 100px;
    height: 100px;
  }
}

a.video {
  position: relative;
  left: 50%;
  top: 50%;
  margin: -50px 0 0 -50px;
  position: absolute;
  width: 100px;
  height: 100px;
}

@media (min-width: 992px) {
  a.video {
    position: relative;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
    position: absolute;
    width: 100px;
    height: 100px;
  }
}

a.video span {

  width: 100%;
  height: 100%;
  background: url("../../../assests/imges/videoIcon.png") no-repeat center center;
  background-size: 100%;
  background-position: 50% 50%;
  display: block;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .eco_friendly_nfc{
.eco_friendly_zeeqr_p_2 {
  width: 100%;
}
  }
  
  .eco_friendly{
    .eco_friendly_zeeqr_p_6,
    .eco_friendly_zeeqr_p_4,
.eco_friendly_zeeqr_p_2 {
    width: 100%;
    max-width: 100%;
    height: 123px;
  }
  }  
}