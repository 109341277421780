.TemplateContainer {
    width: 100%;
    // display: flex;
    gap: 20px;
    margin: 20px 0px 0px 0px;
    flex-wrap: wrap;
    .TemplateImage {
        position: relative;
        // width: 200px;
        border-radius: 12px;
        overflow: hidden;
        // height: 286px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px;
    }

    .CheckMark {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 13px;
            border: 2px solid #F6CD66;
            background: rgba(255, 200, 50, 0.29);
            display: flex;
            justify-content: center;
            align-items: center;
    }

    .CheckMarkIcon {
    
    }

    img {
        width: 100%;
        height: 100%;
        max-width: 100%;
            object-fit: contain;
    }


}

@media (max-width:500px) {
    .TemplateContainer {
        flex-wrap: nowrap;
            max-width: 99%;
            overflow: auto;
            margin: 0px auto;
            // height: 100%;
            align-items: flex-end;
            gap: 10px;
        
            // .TemplateImage {
            //     min-width: 150px;
            //     height: 260px;
            // }
    }
}