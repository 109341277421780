@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body,
*,
button {
  font-family: 'Inter', sans-serif;
}

.wrapper {
  background-color: #151515;
}

.container {
  z-index: 1;
  width: 100%;
  padding: 0px 50px;
  position: relative;
  // margin: 0 20px;
}

.marginLeftAuto {
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    max-width: 1200px;
    // margin: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}


@media (min-width: 992px) {
  .container {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 100px;
    width: 100%;
  }

}

@media (max-width:580px) {
  .container {
    padding: 0px 20px;
  }
}


.zeeqr_section_p {
  font-weight: 600;
  font-size: 16.3961px;
  line-height: 25px;
  letter-spacing: 0.07em;
  color: #000000;

}

.zeeqr_section .zeeqr_section_p::after {
  content: "";
  display: flex;
  width: 60%;
  height: 2px;
  background: #D9D9D9;
  margin-top: 14px;
}

@media (min-width: 768px) {
  .zeeqr_section .zeeqr_section_p {
    font-weight: 600;
    font-size: 16.3961px;
    line-height: 25px;
    /* identical to box height */

    letter-spacing: 0.07em;

    color: #000000;
    display: flex;
    margin-top: 14px;
    gap: 20px;

  }
}

.zeeqr_section .btn {
  width: 147px;
  font-size: 16px;
  color: #4D3000;
  display: flex;
  align-items: center;
  height: 46px;
  font-weight: 700;
  justify-content: center;
  border: 0px;
  border-radius: 10px;
  background: linear-gradient(94deg, #F1B63F 46.68%, #E0A819 102.15%);
  box-shadow: 0px 11px 9px 0px rgba(255, 163, 0, 0.13);
  margin-top: 30px;
}

.zeeqr_section .btn:hover {
  color: #000;
}

@media (min-width: 768px) {
  .zeeqr_section .btn {
    width: 132px;
    color: black;
  }
}


.zeeqr_section_home {
  display: grid;
  align-items: center;
  background-image: url(../../../assests/imges/hero-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 750px;
  overflow: hidden;
  position: relative;

  .zeeqr_section {
    position: relative;
    z-index: 5;
  }

}

.zeeqr_section_home .section_overlay {
  content: "";
  background: linear-gradient(83deg, #1B1B1B 25.29%, rgba(27, 27, 27, 0.69) 49.52%, rgba(27, 27, 27, 0.39) 66.36%, rgba(27, 27, 27, 0.00) 94.89%);
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
}



.zeeqr_section .hero_section_p1 {
  background: linear-gradient(91deg, #FF9B01 7.13%, rgba(229, 170, 60, 1.00) 80.29%, rgba(41, 165, 255, 0.99) 104.01%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}



.zeeqr_section .hero_section_p2 {
  color: #A0A0A0;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}

.hero_section_circleImg {
  position: absolute;
  top: 32%;

}

@media (max-width:580px) {
  .text_content {
    width: 210px;
  }

  .zeeqr_section .hero_section_p1 {
    font-size: 38px;
    background: linear-gradient(91deg, #FF9B01 7.13%, rgb(229, 170, 60) 39.29%, rgba(41, 165, 255, 0.99) 104.01%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }

  .zeeqr_section .hero_section_p2 {
    font-size: 18px;
  }

  .zeeqr_section_home {
    height: 520px;
  }
}

@media (min-width: 768px) {
  .hero_section_circleImg {
    top: 55%;
    width: 40%;
  }
}

@media (min-width: 992px) {
  .hero_section_circleImg {
    top: 28%;
    width: auto;
  }
}


.mainContainer {
  background: #151515;
  background-image: url('../../../assests/imges/vector/bg-image.svg');
  background-repeat: repeat;
  margin-top: -25px;
  border-radius: 20px;
  // overflow: hidden;
  z-index: 2;
  position: relative;
  padding: 100px 0px;
  margin-bottom: -20px;
}

@media (max-width:560px) {
  .hero_section_circleImg {
    top: 53%;
    width: 400px;
    height: 400px;
  }

  .mainContainer {
    padding-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .zeeqr_section .zeeqr_section_p {
    font-size: 18.6657px;
    width: 550px;

  }
}

@media (min-width: 992px) {
  .zeeqr_section .zeeqr_section_p {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .zeeqr_section .zeeqr_section_p1 {
    font-size: 52px;
    width: 443px
  }
}

@media (min-width: 992px) {
  .zeeqr_section .zeeqr_section_p1 {
    font-weight: 700;
    font-size: 77.6657px;
    line-height: 96px;
    color: #000000;
    width: 443px
  }
}

.zeeqr_card_choose {
  padding: 50px 0;

}

.row {
  background: #000000;
  max-width: 1240px;
  margin: auto;
  border-radius: "15px"
}

@media (min-width: 768px) {
  .zeeqr_card_choose {
    padding: 50px 0 50px;
  }
}

.zeeqr_section .zeeqr_section_p1 {
  font-size: 45px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

@media (min-width: 768px) {
  .zeeqr_section .zeeqr_section_p {
    font-size: 18.6657px;
    width: 550px;

  }
}

@media (min-width: 992px) {
  .zeeqr_section .zeeqr_section_p {
    font-size: 16px;


  }
}

@media (min-width: 768px) {
  .zeeqr_section .zeeqr_section_p1 {
    font-size: 52px;
    max-width: 443px;
    margin-top: 20px;

  }
}

@media (min-width: 992px) {
  .zeeqr_section .zeeqr_section_p1 {
    font-weight: 700;
    font-size: 77.6657px;
    line-height: 96px;
    color: #000000;

  }
}

.zeeqr_card {
  height: 100%;
}

.zeeqr_card_first {
  position: relative;
}


@media (min-width: 768px) {
  .zeeqr_card .card .card_body_1 .zeeqr_card_linebar1 {
    border-radius: none;

  }

  .zeeqr_card_first:after {
    width: 2px;
    height: 80%;
    background: rgba(217, 217, 217, 0.5);
    content: "";
    position: absolute;
    right: -10px;
    top: 32px;
    margin-right: 10px;
  }

}




.zeeqr_card .card .card_body_1 .card_body_color {
  position: relative;
  background: linear-gradient(180deg, #DBB24B 0%, #6A4D03 100%);
  box-shadow: 4px 4px 22px 1px rgba(0, 0, 0, 0.41);
  border-radius: 15px;
  color: #FFFFFF;
  padding: 20px;
  border: none;
}

.zeeqr_card .card .card_body_1 .card_body_color img {
  width: 65px;
}

@media (min-width: 768px) {
  .zeeqr_card .card .card_body_1 .card_body_color {
    margin-top: -60px;
    // margin-bottom: -10px;
    height: 380px;

    padding-top: 53px;
  }
}

.zeeqr_card .card .card_body_1 .card_body_color .card_text {
  width: 232px;


  font-weight: 400;
  font-size: 13.67px;
  line-height: 21px;

  color: #DEDEDE;




}

.zeeqr_card .card .card_img_top {
  width: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .zeeqr_card .card .card_img_top {
    width: 50px;
  }
}

@media (min-width: 768px) {
  .zeeqr_card .card .card_img_top {
    width: 50px;

  }
}

@media (min-width: 992px) {
  .navbar_expand_lg {
    padding: 50px 0 10px;
  }
}

.zeeqr_card .card:hover {
  transform: scale(1.15s);
}

.zeeqr_card .card .card_title {
  font-weight: 700;
  font-size: 21.67px;
  line-height: 33px;
  color: #FFFFFF;
  width: 200px;


  font-weight: 500;
  font-size: 19.67px;
  line-height: 24px;


  color: #FFFFFF;



}

.zeeqr_card .card .card_text {

  width: 252px;


  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13.67px;
  line-height: 21px;
  color: #9E9E9E;
}

.zeeqr_card .card {

  // width: 300px;
  margin: auto;
  border-radius: 15px;
  padding: 20px;

}

.card .card_body_1 .zeeqr_card_linebar1 .card_title {
  color: #FCFCFC;
}

.zeeqr_card .card .card_body_1 .card_body_color .card_text {
  color: #FCFCFC;
}

@media (min-width: 768px) {
  .zeeqr_card .card .card_title {
    font-weight: 700;
    font-size: 21.67px;
    line-height: 33px;
    color: #FFFFFF;
  }
}

@media (min-width: 992px) {
  .zeeqr_card .card .card_title {
    font-weight: 700;
    font-size: 21.67px;
    line-height: 33px;
    color: #FFFFFF;
  }
}

.zeeqr_card {
  height: 100%;
}

.row_card {

  background: #1A1A1A;
}

@media (min-width: 768px) {
  .row_card {
    display: flex;
    background: #1A1A1A;
    border-radius: 15px;
  }

}

@media (min-width: 992px) {
  .row_card {
    display: flex;
    background: #1A1A1A;
    border-radius: 15px;
    height: 300px;
    justify-content: space-evenly;
  }

}

// .col_home
// {

// }
@media (min-width: 992px) {
  .col_home {
    display: flex;
    flex-direction: start;
  }
}

@media (min-width: 768px) {
  .col_home {
    display: flex;
    flex-direction: start;
  }

}



.about_nfc {
  padding: 30px 0;
}

@media (min-width: 768px) {
  .about_nfc {
    padding: 100px 0 80px;
  }
}

.about_nfc .about_h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: #000000;

}

@media (min-width: 768px) {
  .about_nfc .about_h1 {
    font-weight: 600;
    font-size: 73px;
    line-height: 78px;
    color: #000000;
  }
}

@media (min-width: 992px) {
  .about_nfc .about_h1 {
    font-weight: 600;
    font-size: 73px;
    line-height: 78px;
    color: #000000;
    width: 640px;

  }
}

.about_nfc .about_p {
  font-weight: 300;
  font-size: 18.49px;
  line-height: 28px;
  width: 324px;
  margin-bottom: 50px;
  color: #343434;
  padding-top: 20px;

}

.about_nfc .btn {
  width: 214px;
  height: 47px;
  border-radius: 5px;
  border: 2px solid;

  border-image-source: linear-gradient(0deg, #6F4F01 4.81%, #DBB24B 100%);


  margin-top: 20px;

}

@media (min-width: 768px) {
  .about_nfc .btn {
    width: 250px;
    margin-top: 20px;
    border: 2px solid;

    border-image-source: linear-gradient(0deg, #6F4F01 4.81%, #DBB24B 100%);


    background: transparent;
  }
}

@media (min-width: 768px) {
  .about_nfc .about_p {
    font-weight: 300;
    font-size: 18.49px;
    line-height: 28px;
    width: 474px;
    color: #343434;

  }



}


@media (min-width: 992px) {

  .about_nfc .about_p {
    font-weight: 300;
    font-size: 18.49px;
    line-height: 28px;
    width: 474px;
    color: #343434;

  }
}



@media (min-width: 992px) {
  .about_nfc .about_col .imgabout {
    width: 664px;

  }
}

.about_img {

  justify-content: center;
  width: 100%;

}

@media (min-width: 768px) {
  .about_img {
    display: flex;
    justify-content: center;


  }

}

@media (min-width: 992px) {}

@media (min-width: 992px) {
  .about_img {
    display: flex;
    justify-content: center;



  }
}

// .about_col{


// }
@media (min-width: 768px) {
  .about_col {
    display: flex;
    flex-direction: start;

  }
}

@media (min-width: 992px) {
  .about_col {
    display: flex;
    flex-direction: start;

  }
}

.about_img_hom1 {
  position: relative;
  text-align: center;
  padding: 10px 0;

}

.about_img_hom1 img {
  width: 100%;
}

@media (min-width: 768px) {
  .about_img_hom1 {
    position: relative;
    text-align: center;
    width: 532px;
    height: 296px;
    ;
    padding: 10px;
  }
}

@media (min-width: 992px) {
  .about_img_hom1 {
    position: relative;
    text-align: center;
    width: 532px;
    height: 296px;
    ;

  }

}

.img_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 21px;
  line-height: 46px;
  width: 100%;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .img_center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 21px;
    line-height: 46px;
    width: 100%;
    color: #FFFFFF;

  }
}

@media (min-width: 992px) {
  .img_center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 30px;
    line-height: 46px;
    width: 100%;
    color: #FFFFFF;

  }
}

/* .about_img .about_img_home {}

.about_img .about_img_home p {}

.about_img .about_img_home1 {} */

.about_nfc .btn {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(0deg, #6F4F01 4.81%, #DBB24B 100%) border-box;
  color: #795909;
  border-radius: linear-gradient(white, white) padding-box,
    linear-gradient(0deg, #6F4F01 4.81%, #DBB24B 100%) border-box 5px;
  border-width: 2px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about_nfc .btn span {
  position: relative;
  z-index: 1;
}

.about_nfc .btn:after {
  background: linear-gradient(0deg, #6F4F01 4.81%, #DBB24B 100%),
    linear-gradient(0deg, rgba(217, 217, 217, 0), rgba(217, 217, 217, 0));
  content: "";
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: all 0.3s ease;
}

.about_nfc .btn:hover,
.about_nfc .btn:hover:focus {
  color: white;
  border-width: 2px;
}

.about_nfc .btn:hover:after {
  opacity: 1;
  visibility: visible;
}

.about_product_zeeqr {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .about_product_zeeqr {
    margin-top: 80px;
  }


}

@media (min-width: 992px) {
  .about_product_zeeqr {
    margin-top: 130px;
  }



}

.about_product .about_product_p1 {

  display: flex;
  gap: 10px;
}

.about_product .about_product_p1:before {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  background: #DBB24B;
  margin-top: 10px;


}

.about_product .about_product_p2 {
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
}

@media (min-width: 768px) {
  .about_product .about_product_p2 {
    font-weight: 900;
    font-size: 45.73px;
    line-height: 58px;
    width: 700px;
  }

}

@media (min-width: 992px) {
  .about_product .about_product_p2 {
    font-weight: 600;
    font-size: 45.73px;
    line-height: 58px;

    width: 700px;
    height: 129px;
  }

}

@media (min-width: 768px) {
  .col1 {
    margin: 20px;

  }
}

@media (min-width: 992px) {
  .col1 {
    margin: 20px;

  }

}

.about_product .about_product_p3 {

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0;
}

@media (min-width: 768px) {

  .about_product .about_product_p3 {
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    height: 140px;
  }
}

@media (min-width: 992px) {
  .about_product .about_product_p3 {
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    width: 678px;

    height: 190px;
  }

}

.about_product .about_product_p4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #333333;


}

@media (min-width: 768px) {
  .about_product .about_product_p4 {
    font-weight: 400;
    font-size: 18.49px;
    line-height: 28px;
    width: 706px;
    height: 90px;
    color: #333333;


  }

}

@media (min-width: 992px) {
  .about_product .about_product_p4 {
    font-weight: 400;
    font-size: 18.49px;
    line-height: 28px;
    width: 680px;
    height: 120px;
    color: #333333;



  }

}

.about_product .about_product_zeeqr_img {
  object-fit: cover;
  margin: 0;
}

@media (min-width: 768px) {
  .about_product .about_product_zeeqr_img {
    object-fit: cover;
    width: 528px;
    height: 466px;

  }

}

@media (min-width: 992px) {
  .about_product .about_product_zeeqr_img {
    object-fit: cover;
    width: 528px;
    height: 466px;

  }

}

// .about_product .about_col_product{

// }

@media (min-width: 768px) {
  .about_product .about_col_product {
    display: flex;
    flex-direction: start;
  }
}

@media (min-width: 992px) {
  .about_product .about_col_product {
    display: flex;
    flex-direction: start;
  }

}

.ourVideo {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 0px;
  position: relative;
  outline: none;
  border: none;
}

.ourVideo video {
  width: 100%;
  outline: none;
  border: none;
}

@media (min-width: 768px) {
  a.video {
    position: relative;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
    position: absolute;
    width: 100px;
    height: 100px;
  }
}

a.video {
  position: relative;
  left: 50%;
  top: 50%;
  margin: -50px 0 0 -50px;
  position: absolute;
  width: 100px;
  height: 100px;
}

@media (min-width: 992px) {
  a.video {
    position: relative;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
    position: absolute;
    width: 100px;
    height: 100px;
  }
}

a.video span {

  width: 100%;
  height: 100%;
  background: url("../../../assests/imges/videoIcon.png") no-repeat center center;
  background-size: 100%;
  background-position: 50% 50%;
  display: block;
}

.zeeqr_Services {
  margin-top: 60px;
}

@media (min-width: 768px) {
  .zeeqr_Services {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .zeeqr_Services {
    margin-top: 70px;
  }
}

.zeeqr_Services .zeeqr_Services_p1 {
  font-weight: 600;
  font-size: 16.3961px;
  line-height: 25px;
  letter-spacing: 0.07em;
  color: #000000;
  justify-content: center;
  display: flex;
  gap: 10px;
}

.zeeqr_Services .zeeqr_Services_p1:after {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  background: #DBB24B;
  margin-top: 10px;

}

.zeeqr_Services .zeeqr_Services_p1:before {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  background: #DBB24B;
  margin-top: 10px;

}

@media (min-width: 768px) {
  .zeeqr_Services .zeeqr_Services_p1 {
    font-weight: 600;
    font-size: 16.3961px;
    line-height: 25px;
    letter-spacing: 0.07em;
    color: #000000;
    text-align: center;
  }

}

@media (min-width: 992px) {

  .zeeqr_Services .zeeqr_Services_p1 {
    font-weight: 600;
    font-size: 16.3961px;
    line-height: 25px;
    letter-spacing: 0.07em;
    color: #000000;
    text-align: center;
  }
}

.zeeqr_Services .zeeqr_Services_p2 {
  font-weight: 600;
  font-size: 45.73px;
  line-height: 55px;
  color: #000000;
  text-align: center;
}

@media (min-width: 768px) {
  .zeeqr_Services .zeeqr_Services_p2 {
    font-weight: 600;
    font-size: 65.73px;
    line-height: 78px;
    color: #000000;
    text-align: center;
  }

}

@media (min-width: 992px) {

  .zeeqr_Services .zeeqr_Services_p2 {
    font-weight: 600;
    font-size: 65.73px;
    line-height: 78px;
    color: #000000;
    text-align: center;
  }

}

.zeeqr_Services .zeeqr_Services_p3 {
  font-weight: 500;
  font-size: 18.49px;
  line-height: 28px;
  text-align: center;
  color: #333333;

}

@media (min-width: 768px) {
  .zeeqr_Services .zeeqr_Services_p3 {
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    text-align: center;
    color: #333333;


  }

}

@media (min-width: 992px) {
  .zeeqr_Services .zeeqr_Services_p3 {
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    max-width: 923px;
    margin: auto;


  }

}

.zeeqr_Services_card {
  margin-top: 50px;
}

.zeeqr_Services_card .card {
  border-radius: 0;
  padding: 20px;
  width: 400px;
  height: 100%;
  margin-bottom: 10px;
  text-align: center;
}

.zeeqr_Services_card .col {
  margin-bottom: 10px;
}

@media (min-width: 768px) {

  .zeeqr_Services_card .col {
    margin-bottom: 0;
  }

  .zeeqr_Services_card .card {
    text-align: left;
    margin-bottom: 0;
    border-radius: 0;

  }

}

.zeeqr_Services_card .card .dots .dot {
  width: 9px;
  height: 9px;

  background: #DBB24B;
  border-radius: 50%;

}

.zeeqr_Services_card .card .dots {
  margin: 0 auto;
  width: 40px;
  display: flex;
  gap: 6px;
}

.zeeqr_Services_card .card .card_img_top {
  margin: 0 auto;
  padding: 0;
}

.zeeqr_Services_card .card {
  width: 100%;
  gap: 10px;
  padding-top: 30px;
  background: #1A1A1A;

}

@media (min-width: 768px) {
  .zeeqr_Services_card .card {
    width: 100%;
    gap: 10px;
    padding-top: 30px;
    background: #1A1A1A;

  }
}

@media (min-width: 992px) {

  .zeeqr_Services_card .card {
    width: 100%;
    gap: 10px;
    padding-top: 30px;
    background: #1A1A1A;

  }

  .zeeqr_Services_card .card .card_img_top {
    margin: 0;
  }

}


.zeeqr_Services_card .card .card_body .card_title {
  width: 100%;
  height: 51px;
  left: 272px;
  top: 3472px;

  font-family: 'Poppins';
  font-style: normal;
  padding: 20px;
  font-weight: 700;
  font-size: 20.49px;
  line-height: 31px;
  /* identical to box height */


  color: #FFFFFF;

}

.zeeqr_Services_card .card .card_text {
  font-weight: 300;
  font-size: 16px;
  padding: 20px;
  line-height: 28px;
  color: #FCFCFC;
}

@media (min-width: 992px) {
  .zeeqr_Services_card .card .card_text {
    font-weight: 300;
    font-size: 16.49px;
    padding: 20px;
    line-height: 28px;
    width: 350px;


    color: #FCFCFC;
  }

}

@media (min-width: 768px) {
  .zeeqr_Services_card .card .card_text {
    font-weight: 300;
    font-size: 16.49px;
    padding: 20px;
    line-height: 28px;
    width: 350px;


    color: #FCFCFC;
  }


}


// .zeeqr_Services_card .zeeqr_col{

// }

@media (min-width: 992px) {
  .zeeqr_Services_card .zeeqr_col {
    display: flex;
    flex-direction: start;
  }
}

@media (min-width: 768px) {
  .zeeqr_Services_card .zeeqr_col {
    display: flex;
    flex-direction: start;
  }

}

.eco_friendly {
  margin-top: 70px;
}

@media (min-width: 768px) {
  .eco_friendly {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .eco_friendly {
    margin-top: 70px;
  }

}

.eco_friendly .eco_friendly_zeeqr_p_1 {
  font-weight: 600;
  font-size: 17.9628px;
  line-height: 24px;
  letter-spacing: 0.07em;
  margin-top: 20px;
  color: #000000;
  display: flex;
  gap: 10px;

}

.eco_friendly .eco_friendly_zeeqr_p_1:before {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  background: #DBB24B;
  margin-top: 10px;

}

@media (min-width: 768px) {
  .eco_friendly .eco_friendly_zeeqr_p_1 {
    font-weight: 600;
    font-size: 15.9628px;
    line-height: 24px;
    letter-spacing: 0.07em;
    color: #000000;

  }
}

@media (min-width: 992px) {
  .eco_friendly .eco_friendly_zeeqr_p_1 {
    font-weight: 600;
    font-size: 15.9628px;
    line-height: 24px;
    letter-spacing: 0.07em;
    color: #000000;

  }

}

.eco_friendly .eco_friendly_zeeqr_p_2 {

  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #000000;

}

@media (min-width: 768px) {
  .eco_friendly .eco_friendly_zeeqr_p_2 {
    font-style: normal;
    font-weight: 600;
    font-size: 63.993px;
    line-height: 76px;
    width: 700px;
    color: #000000;
  }

}

@media (min-width: 992px) {
  .eco_friendly .eco_friendly_zeeqr_p_2 {
    font-style: normal;
    font-weight: 600;
    font-size: 53.993px;
    line-height: 76px;

    width: 645px;
    height: 168px;
    color: #000000;
  }


}

.eco_friendly .row_ecofriendly .eco_friendly_zeeqr_p_3 {
  font-style: normal;
  font-weight: 500;
  font-size: 18.0014px;
  line-height: 27px;
  margin: 0;
  /* identical to box height */


  color: #000000;

}

.eco_friendly .eco_friendly_zeeqr_p_4 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  height: 194px;
  color: #333333;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .eco_friendly .eco_friendly_zeeqr_p_4 {
    font-style: normal;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;
    width: 698px;
    height: 104px;
    color: #333333;
    margin-top: 10px;
  }

}


@media (min-width: 992px) {
  .eco_friendly .eco_friendly_zeeqr_p_4 {
    font-style: normal;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;
    width: 698px;
    height: 104px;
    color: #333333;
    margin-top: 10px;
  }

}


.eco_friendly .row_ecofriendly .eco_friendly_zeeqr_p_5 {
  font-style: normal;
  font-weight: 500;
  font-size: 18.0014px;
  line-height: 27px;
  /* identical to box height */
  width: 200px;

  color: #000000;

}

.eco_friendly .eco_friendly_zeeqr_p_6 {
  font-style: normal;
  font-weight: 400;
  font-size: 17.3701px;
  line-height: 26px;
  margin-top: 10px;
  color: #333333;
}

@media (min-width: 768px) {

  .eco_friendly .eco_friendly_zeeqr_p_6 {
    font-style: normal;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;
    width: 698px;
    height: 98px;
    margin-top: 10px;
    color: #333333;
  }


}

@media (min-width: 992px) {
  .eco_friendly .eco_friendly_zeeqr_p_6 {
    font-style: normal;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;
    width: 698px;
    height: 98px;
    margin-top: 10px;
    color: #333333;
  }
}

.row_ecofriendly {
  display: flex;
  gap: 20px;
  align-items: center;
}



.eco_friendly .zeeqr_card_img {
  width: 350px;

}

@media (min-width: 768px) {
  .eco_friendly .zeeqr_card_img {
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 650px;
    margin-bottom: 0;
    position: relative;
    left: -100px;
  }

}

@media (min-width: 992px) {
  .eco_friendly .zeeqr_card_img {
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 650px;
    margin-bottom: 0;

  }

}

// .eco_friendly .eco{

// }

@media (min-width: 768px) {
  .eco_friendly .eco {
    display: flex;
    flex-direction: start;
  }

}

@media (min-width: 992px) {
  .eco_friendly .eco {
    display: flex;
    flex-direction: start;
  }
}


.zeeqr_work {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .zeeqr_work {
    margin-top: 100px;
  }


}

@media (min-width: 992px) {
  .zeeqr_work {
    margin-top: 140px;
  }

}

.zeeqr_work .zeeqr_work_p_1 {
  font-weight: 600;
  font-size: 12.3961px;
  line-height: 25px;

  letter-spacing: 0.07em;
  color: #000000;
  display: flex;
  gap: 10px;
  margin: 0;
  justify-content: center;
}

.zeeqr_work .zeeqr_work_p_1::before {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  background: #DBB24B;
  margin-top: 10px;
}

.zeeqr_work .zeeqr_work_p_1::after {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  background: #DBB24B;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .zeeqr_work .zeeqr_work_p_1 {
    font-weight: 600;
    font-size: 16.3961px;
    line-height: 25px;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.07em;
    color: #000000;
  }
}

@media (min-width: 992px) {
  .zeeqr_work .zeeqr_work_p_1 {
    font-weight: 600;
    font-size: 16.3961px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.07em;
    justify-content: center;
    color: #000000;
  }
}

.zeeqr_work .zeeqr_work_p_2 {

  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .zeeqr_work .zeeqr_work_p_2 {

    font-weight: 600;
    font-size: 45.73px;
    line-height: 78px;
    text-align: center;
    color: #000000;

  }


}

@media (min-width: 992px) {
  .zeeqr_work .zeeqr_work_p_2 {

    font-weight: 600;
    font-size: 45.73px;
    line-height: 78px;
    text-align: center;
    color: #000000;

  }

}

.zeeqr_work .zeeqr_work_p_3 {

  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;

  color: #333333;
}

@media (min-width: 768px) {
  .zeeqr_work .zeeqr_work_p_3 {

    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    text-align: center;
    width: 779px;
    color: #333333;
  }
}

@media (min-width: 992px) {
  .zeeqr_work .zeeqr_work_p_3 {
    max-width: 923px;
    margin: auto;
    font-weight: 500;
    font-size: 18.49px;
    line-height: 28px;
    text-align: center;
    width: 779px;
    color: #333333;
  }

}

.zeeqr_work .zeeqr_grid .grid_img {
  width: 190px;
  height: 100px;
  gap: 10px;
  object-fit: cover;
}

@media (min-width: 768px) {
  .zeeqr_work .zeeqr_grid .grid_img {
    width: 800px;
    height: 400px;
    object-fit: cover;
  }

}

@media (min-width: 992px) {
  .zeeqr_work .zeeqr_grid .grid_img {
    width: 800px;
    height: 400px;
    margin-top: 20px;
    object-fit: cover;
  }
}

.zeeqr_work .zeeqr_workcol {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  gap: 10px;
}

@media (min-width: 992px) {

  .zeeqr_work .zeeqr_workcol {
    display: flex;
    gap: 30px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .zeeqr_work .zeeqr_workcol {
    display: flex;
    gap: 30px;
    width: 100%;
  }

}


.slider_btn {
  margin-bottom: 50px;
  padding: 20px;
}

@media (min-width: 768px) {
  .slider_btn {
    margin-bottom: 80px;
    padding: 20px;
  }
}

.slider_btn .btn {
  width: 259px;
  height: 47px;

  outline: transparent;
  color: #000000;
  border-radius: 5px;
  border: 2px solid #000000;
}

@media (min-width: 768px) {
  .slider_btn .btn {
    width: 259px;
    height: 47px;

    outline: transparent;
    color: #000000;
    border-radius: 5px;
    border: 2px solid #000000;
  }

}

@media (min-width: 992px) {
  .slider_btn .btn {
    width: 259px;
    height: 47px;

    outline: transparent;
    color: #000000;
    border-radius: 5px;

    border: 2px solid #000000;
    transition: all 0.3s ease;

  }
}

.slider_btn .btn:hover {
  color: #fff;
  border: none;
  background: linear-gradient(90deg, #DDB34A 0%, #966C05 100%);
}




.get_nfc {
  max-width: 100%;
  margin: 0 auto 0px;
  position: relative;
  overflow: hidden;

  .gradient_text {
    background: linear-gradient(92deg, #E39C28 0.04%, rgba(1, 141, 242, 0.99) 60.71%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .overlay {
    background: linear-gradient(267deg, #EEA900 -2.97%, #0E448A 96.94%);
    box-shadow: 0px 4px 184px 93px #FFF3E1 inset;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .overlay2 {
    opacity: 0.8;
    background: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .container {
    position: relative;
    // overflow: hidden;
  }

  @media (max-width:560px) {
    .container {
      padding: 50px 20px;
      overflow: hidden;
    }
  }
}


.get_nfc .getnfc_text p {
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  color: #A5772B;
  margin-bottom: 0;
}


@media (min-width: 768px) {
  .get_nfc .getnfc_text {
    height: 307px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
}


.row_nfc {
  padding: 10px;
  // text-align: center;
}

@media (min-width: 768px) {
  .row_nfc {
    display: flex;
  }
}

@media (min-width: 992px) {
  .row_nfc {
    display: flex;
  }
}

.get_nfc .btn_get_zeeqr {
  width: 147px;
  font-size: 16px;
  color: #4D3000;
  display: flex;
  align-items: center;
  height: 46px;
  font-weight: 700;
  justify-content: center;
  border: 0px;
  border-radius: 10px;
  background: linear-gradient(94deg, #F1B63F 46.68%, #E0A819 102.15%);
  box-shadow: 0px 11px 9px 0px rgba(255, 163, 0, 0.13);
}


.get_nfc .btn_get_zeeqr:hover {
  background-color: #FFFFFF;
  color: #7A5908;

}

.RoyalRectangular1,
.RoyalRectangular2,
.RoyalRectangular3 {
  position: absolute;
  // width: 100%;
}

.RoyalRectangular1 {
  left: -55px;
  top: -140px;
}

.RoyalRectangular2 {
  left: 40%;
  top: -500px;
}

.RoyalRectangular3 {
  left: 45%;
  bottom: -550px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .RoyalRectangular1 {
    left: -55px;
    top: -140px;
    width: 50%;
  }

  .RoyalRectangular2 {
    left: 77%;
    top: 8%;
    width: 84%;
  }

  .RoyalRectangular3 {
    left: -38%;
    bottom: -24%;
    width: 68%;

  }

  .get_nfc .btn_get_zeeqr {
    margin: 20px;
  }
}

@media (max-width:560px) {
  .RoyalRectangular1 {
    left: -55px;
    top: -140px;
    width: 50%;
  }

  .RoyalRectangular2 {
    left: 77%;
    top: 8%;
    width: 84%;
  }

  .RoyalRectangular3 {
    left: -38%;
    bottom: -24%;
    width: 68%;

  }

  .get_nfc .btn_get_zeeqr {
    margin: 20px;
  }
}



#slider-container {
  height: 150px;
  width: 100%;
  max-width: 1000px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  box-shadow: 0 0 15px #888;
}

#slider_scroller {

  top: 0;
  left: 0;
  width: calc(260px * 6);
  transition: all 1s ease;
}

.slider .item {


  display: inline-block;
  margin: 20px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200px;
  max-height: 500px;

}




.slider {
  display: flex;
  width: 100%;

  margin: 0 auto;
  overflow: hidden;
  padding: 2.5rem 0 20px;
}

.item {
  animation: animate 20s linear infinite;
}

.item img {
  min-width: 250px;
  padding: 0 15px;
}

.slider:hover .item {
  animation-play-state: paused;
}

@keyframes animate {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-1800px, 0, 0);
  }
}


.footer {
  margin-top: 350px;
  padding-left: 0;
  background: radial-gradient(50% 50% at 50% 50%, #303030 0%, #272727 100%);
}

@media (min-width: 768px) {
  .get_nfc .btn_get_zeeqr {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .footer {
    margin-top: 350px;
    padding-left: 20px;
  }
}

@media (min-width: 992px) {
  .footer {
    margin-top: 0;
    padding-left: 0;
  }
}

.footer_zeeqr {
  padding-top: 20px;
  margin-top: 0;


}

@media (min-width: 768px) {
  .footer_zeeqr {
    padding-top: 0;

  }

}

@media (min-width: 992px) {
  .footer_zeeqr {
    padding-top: 20px;

  }

}

.footer_logo .footer_logo_text {
  font-weight: 400;
  font-size: 17.3701px;
  line-height: 26px;
  color: #D9D9D9;
}

@media (min-width: 768px) {
  .footer_logo .footer_logo_text {
    width: 397px;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;
    color: #D9D9D9;
    padding-right: 10px;
  }

}

@media (min-width: 992px) {
  .footer_logo .footer_logo_text {
    width: 397px;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;

    color: #D9D9D9;
  }

}

.footer_logo .social_icons img {
  height: 55px;
  gap: 10px;
  padding: 5px;
}

@media (min-width: 768px) {
  .footer_logo .social_icons img {
    height: 55px;
    gap: 10px;
  }

}

@media (min-width: 992px) {
  .footer_logo .social_icons img {
    height: 55px;
    gap: 10px;
  }
}

.social_address .social_address_p {
  font-weight: 600;
  font-size: 21.37px;
  line-height: 32px;
  color: #D9D9D9;
  width: 141px;
  height: 37px;

}

@media (min-width: 768px) {
  .social_address .social_address_p {
    font-weight: 600;
    font-size: 21.37px;
    line-height: 32px;
    color: #D9D9D9;
    width: 141px;
    height: 27px;

  }
}

@media (min-width: 992px) {
  .social_address .social_address_p {
    font-weight: 600;
    font-size: 21.37px;
    line-height: 32px;
    color: #D9D9D9;
    width: 141px;
    height: 27px;

  }

}

.social_address .social_address1 {
  gap: 15px;

}

.social_address .social_address1 p {
  font-weight: 400;
  font-size: 12.7586px;
  line-height: 19px;
  max-width: 220px;
  color: #D9D9D9;
}

.social_address1 a {
  color: #D9D9D9;
  text-decoration: none;
}

/* .nav-footer {} */

@media (min-width: 768px) {
  .nav_footer {
    padding-left: 70px;
  }

}

@media (min-width: 992px) {
  .nav_footer {
    padding-left: 70px;
  }

}

.nav_footer p {
  font-weight: 600;
  font-size: 21.37px;
  line-height: 32px;
  width: 121px;
  color: #D9D9D9;
}

.nav_footer .nav_footer_list p {
  font-weight: 400;
  font-size: 12.7586px;
  line-height: 19px;
  width: 109px;
  height: 29px;
  color: #D9D9D9;
  text-decoration: none;

}

.nav_footer .nav_footer_list p a {
  text-decoration: none;
  color: #fff;
}

// .footercol{



// }
@media (min-width: 768px) {
  .footercol {
    display: flex;
  }

}

@media (min-width: 992px) {
  .footercol {
    display: flex;
  }

}

.colfooter {

  display: none;

}

@media (min-width: 992px) {
  .colfooter {
    display: block;
    float: left;
    width: 50%;

  }
}

.footer_gallery p {
  font-weight: 600;
  font-size: 21.37px;
  line-height: 32px;

  color: #D9D9D9;
  width: 83px;
}

.footer_gallery .img_zeeqr {
  width: 50px;
}

@media (min-width: 768px) {
  .footer_gallery .img_zeeqr {
    width: 200px;
  }
}

.footer_gallery {
  padding-bottom: 15px;
}

.copyright {
  padding: 0 15px 15px 15px;
}

.text_white {
  width: 100%;
  height: 2px;

  margin-top: 20px;
  background: #D9D9D9;
  opacity: 0.24;
}

.copyright .copyright_text {
  font-weight: 400;
  font-size: 12.7586px;
  line-height: 19px;
  text-align: center;
  margin: 30px;
  background: linear-gradient(90deg, #DDB34A 0%, #966C05 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}


#slider-container {
  height: 150px;
  width: 100%;
  max-width: 1000px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  box-shadow: 0 0 15px #888;
}

#slider_scroller {

  top: 0;
  left: 0;
  width: calc(260px * 6);
  transition: all 1s ease;
}

.slider .item {


  display: inline-block;
  margin: 20px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200px;
  max-height: 500px;

}





.item {
  animation: animate 20s linear infinite;
}

.item img {
  min-width: 250px;
  padding: 0 15px;
}

.slider:hover .item {
  animation-play-state: paused;
}

@keyframes animate {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-1800px, 0, 0);
  }
}

.top_btn .myBtn {
  position: fixed;
  bottom: 10px;
  float: right;
  right: 1.5%;
  margin-bottom: 80px;
  left: 84.25%;
}

@media (min-width: 768px) {
  .top_btn .myBtn {
    position: fixed;
    bottom: 10px;
    float: right;
    margin-bottom: 80px;
    right: 1.5%;
    left: 92.25%;
  }

}

@media (min-width: 992px) {

  .top_btn .myBtn {
    position: fixed;
    bottom: 10px;
    float: right;
    right: 1.5%;
    margin-bottom: 80px;
    left: 94.25%;

  }
}

.top_btn .myBtn1 {
  position: fixed;
  bottom: 10px;
  float: right;
  right: 1.5%;
  left: 84.25%;




}



@media (min-width: 768px) {
  .top_btn .myBtn1 {
    position: fixed;
    bottom: 10px;
    float: right;
    left: 92.25%
  }


}

@media (min-width: 992px) {
  .top_btn .myBtn1 {
    bottom: 10px;
    float: right;
    left: 94.25%;
    position: fixed;
  }

  .social_address {
    padding: 0 0;
  }
}

.social_address {
  padding: 0 1rem;
}

.topnav {
  overflow: hidden;
  background-color: #333;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #04AA6D;
  color: white;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}






/* new style */
.gradient_text {
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(92deg, #E39C28 0.04%, rgba(1, 141, 242, 0.99) 15.71%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0px;
}

.whyus {
  .whyus_card {
    // padding: 30px 0px;
  }

  .whyus_h3 {
    flex-shrink: 0;
    color: #F6CD66;
    font-family: Inter;
    // font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .whyus_p {
    flex-shrink: 0;
    color: #F6CD66;
    font-family: Inter;
    // font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }




  .container {
    position: relative;
  }

  .lineLeftSideExtra,
  .bottomRightCorner,
  .bottomLeftCorner,
  .lineRightSide,
  .lineLeftSide,
  .topRightCorner,
  .topLeftCorner {
    position: absolute;
  }

  .lineLeftSideExtra {
    display: none;
  }

  @media (min-width: 768px) {


    .whyus_card {}

    .whyus_col {
      display: grid;
      grid-template-columns: 35% 65%;
      margin-bottom: 150px;
    }

    .whyus_col_inverse {
      display: grid;
      grid-template-columns: 65% 35%;
      margin-bottom: 150px;

    }

    .gradient_text {
      font-size: 30px;
    }

    .whyus_h3 {
      font-size: 20px;
    }

    .whyus_p {
      font-size: 15px;
    }

    .imgaWhyus {
      width: 280px;
      margin-left: auto;
    }

    .imgaWhyus img {
      object-fit: contain;
    }

    .whyus_col_inverse {
      .imgaWhyus {
        margin-left: 0px;
        margin-right: auto;
      }

      .whyus_p,
      .whyus_h3 {
        text-align: right;
      }




    }

    .absoluteImageContainer {
      --edge1: 50px;
      --edge2: -50px;

      .topLeftCorner {
        left: var(--edge1);
        top: var(--edge2);
        width: 100px;

      }

      .topRightCorner {
        right: var(--edge1);
        top: var(--edge2);
        width: 50px;
      }

      .lineLeftSide {
        left: var(--edge1);
        top: 55%;
        height: 130px;
      }

      .lineRightSide {
        right: var(--edge1);
        top: 55%;
        height: 130px;
      }

      .bottomLeftCorner {
        left: var(--edge1);
        bottom: var(--edge2);
        transform: rotate(-180deg);
        width: 50px;
      }

      .bottomRightCorner {
        right: var(--edge1);
        bottom: var(--edge2);
        transform: rotate(-180deg);
        width: 100px;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    .whyus_card {}

    .whyus_col {
      display: grid;
      grid-template-columns: 35% 65%;
      margin-bottom: 150px;
    }

    .whyus_col_inverse {
      display: grid;
      grid-template-columns: 65% 35%;
      margin-bottom: 150px;

    }

    .gradient_text {
      font-size: 30px;
    }

    .whyus_h3 {
      font-size: 20px;
    }

    .whyus_p {
      font-size: 15px;
    }

    .imgaWhyus {
      width: 280px;
      margin-left: auto;
    }

    .imgaWhyus img {
      object-fit: contain;
    }

    .whyus_col_inverse {
      .imgaWhyus {
        margin-left: 0px;
        margin-right: auto;
      }

      .whyus_p,
      .whyus_h3 {
        text-align: right;
      }




    }

    .absoluteImageContainer {
      --edge1: 15px;
      --edge2: -50px;

      .topLeftCorner {
        left: var(--edge1);
        top: var(--edge2);
        width: 100px;

      }

      .topRightCorner {
        right: var(--edge1);
        top: var(--edge2);
        width: 50px;
      }

      .lineLeftSide {
        left: var(--edge1);
        top: 55%;
        height: 130px;
      }

      .lineRightSide {
        right: var(--edge1);
        top: 55%;
        height: 130px;
      }

      .bottomLeftCorner {
        left: var(--edge1);
        bottom: var(--edge2);
        transform: rotate(-180deg);
        width: 50px;
      }

      .bottomRightCorner {
        right: var(--edge1);
        bottom: var(--edge2);
        transform: rotate(-180deg);
        width: 100px;
      }
    }
  }


  @media (min-width: 992px) {

    .whyus_card {
      padding: 80px 0px;
    }

    .whyus_col {
      display: grid;
      grid-template-columns: 35% 65%;
      margin-bottom: 150px;

    }

    .whyus_col_inverse {
      display: grid;
      grid-template-columns: 65% 35%;
      margin-bottom: 150px;

    }

    .gradient_text {
      font-size: 40px;
    }

    .whyus_h3 {
      font-size: 32px;
    }

    .whyus_p {
      font-size: 20px;
    }

    .imgaWhyus {
      width: 400px;
      margin-left: auto;
    }

    .whyus_col_inverse {
      .imgaWhyus {
        margin-left: 0px;
        margin-right: auto;
      }

      .whyus_p,
      .whyus_h3 {
        text-align: right;
      }
    }

    .absoluteImageContainer {
      --edge1: 30px;
      --edge2: -65.2px;

      .topLeftCorner {
        left: var(--edge1);
        top: var(--edge2);
        width: unset;

      }

      .topRightCorner {
        right: var(--edge1);
        top: var(--edge2);
        width: unset;

      }

      .lineLeftSide {
        left: var(--edge1);
        top: 55%;
        width: unset;
      }

      .lineRightSide {
        right: var(--edge1);
        top: 55%;
        width: unset;
      }

      .bottomLeftCorner {
        left: var(--edge1);
        bottom: var(--edge2);
        transform: rotate(-180deg);
        width: unset;
      }

      .bottomRightCorner {
        right: var(--edge1);
        bottom: var(--edge2);
        transform: rotate(-180deg);
        width: unset;
      }
    }


  }

  @media (max-width: 560px) {

    .whyus_card {
      padding: 80px 0px;
      width: 100%;
    }

    .whyus_col {
      display: flex;
      flex-direction: column;
      grid-template-columns: unset;
      margin-bottom: 100px;
    }

    .whyus_col_inverse {
      display: flex;
      flex-direction: column;
      grid-template-columns: unset;
      margin-bottom: 100px;
    }

    .gradient_text {
      font-size: 40px;
      text-align: center;
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      background: linear-gradient(92deg, #E39C28 12.04%, rgba(1, 141, 242, 0.99) 117.71%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0px;
      margin: auto;
      width: 230px;
    }

    .whyus_h3 {
      font-size: 32px;
    }

    .whyus_p {
      font-size: 17px;
      line-height: 1.5;
      margin-bottom: 35px;
    }

    .imgaWhyus {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .whyus_col_inverse {
      .imgaWhyus {
        margin-left: 0px;
        margin-right: auto;
        order: 1;
      }

      .whyus_p,
      .whyus_h3 {
        text-align: left;
      }
    }

    .absoluteImageContainer {
      --edge1: 7px;
      --edge2: -89.2px;
      // display: none;

      .topLeftCorner {
        left: var(--edge1);
        top: var(--edge2);
        width: 100px;

      }

      .topRightCorner {
        right: var(--edge1);
        top: var(--edge2);
        width: 50px;

      }

      .lineLeftSide {
        top: 47%;
        width: unset;
        left: 50%;
        transform: rotate(90deg);

      }

      .lineRightSide {
        top: 71%;
        width: unset;
        left: 50%;
        transform: rotate(90deg);
      }

      .lineLeftSideExtra {
        display: block;
        top: 24%;
        width: unset;
        left: 50%;
        transform: rotate(90deg);
      }

      .bottomLeftCorner {
        left: var(--edge1);
        bottom: var(--edge2);
        transform: rotate(-180deg);
        width: 50px;
      }

      .bottomRightCorner {
        right: var(--edge1);
        bottom: var(--edge2);
        transform: rotate(-180deg);
        width: 100px;
      }
    }


  }
}


.features {
  background-color: #222;
  border-radius: 30px;
  margin-top: -20px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .features_card {}

  .container {
    padding: 76px 100px;
  }

  .row_features {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }

  .feature_col .content {
    color: #A5772B;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 163.523%;
    /* 19.623px */
    text-transform: capitalize;
  }

  .feature_col .feature_card {
    border-radius: 34px;
    border: 4px solid #F6CD66;
    background: radial-gradient(50% 50% at 50% 50%, rgba(236, 196, 102, 0.20) 0%, rgba(155, 104, 27, 0.20) 100%), linear-gradient(146deg, #FFDD87 1.53%, #FFD262 23.63%, #FD8 23.64%, #FFDD89 50.65%, #E59806 50.66%, #C38004 77.66%, #654201 77.67%, #5C3B00 95.85%);
    box-shadow: 9px 5px 6px 1px rgba(255, 242, 209, 0.50) inset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 7px;
    margin-bottom: 36px;
  }

  .feature_card img {
    margin: 47px 0px;
    width: 100px;
    height: 100px;
    object-fit: fill;

  }

  .feature_col .feature_card button {
    border-radius: 25px;
    background: #FFF;
    width: 100%;
    height: 97px;
    flex-shrink: 0;
    color: #996B07;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    padding: 0px 33px;
  }

  @media (max-width:560px) {
    .container {
      padding: 76px 20px;
    }

    .row_features {
      display: block;
    }

    .feature_col {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {

      .container {
        padding: 76px 50px;
      }

  }

}


.offer {
  background: #161616;
  border-radius: 30px;

  .offer_text {}

  .container {
    padding: 90px 100px;
  }

  .offer_row {
    display: grid;
    grid-template-columns: 40% 10% 50%;
    align-items: center;
    justify-content: space-between;
  }

  .offer_content {
    h1 {
      background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 27.97px;
    }

    p {
      color: #D18C0D;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .missionContent {
      color: #D18C0D;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 141.523%;
      /* 22.644px */
    }
  }

  .offer_col {}

  .offer_col img {
    width: 100%;
  }

  @media (max-width:560px) {
    .container {
      padding: 76px 20px;
    }

    .offer_row {
      display: block;
    }

    .feature_col {
      margin-bottom: 40px;
    }

    .offer_content {
      h1 {
        font-size: 40px;
      }

      p {
        font-size: 18px;
        margin-bottom: 40px;
      }

      .missionContent {
        font-size: 16px;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    .container {
      padding: 76px 50px;
    }
  }
}

.get_your_card {
  max-width: 1250px;
  margin: 0 auto 0px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  // z-index: 0;

  .white_bg {
    background: #fff;
    position: absolute;
    width: 90%;
    height: 100%;
    border-radius: 30px;
    z-index: -1;
    top: 0px;
    right: 0px;
  }



}

@media (min-width: 992px) {
  .get_your_card {
    width: 100%;
    position: relative;
    background-image: url(../../../assests/imges/get_your_card_bg.png);
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }

}

@media (max-width:600px) {
  .get_your_card {
    margin: 0px;
  }
}

@media (min-width: 768px) {
  .get_your_card .getyour_card_text {
    background: linear-gradient(90deg, #2A2A2A 40.27%,
        rgba(42, 42, 42, 0.75) 48.93%,
        rgba(42, 42, 42, 0.00) 80.17%);
    border-radius: 30px;

  }

  .get_your_card .getyour_card_text p {
    color: #B4B4B4;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

@media (min-width: 992px) {
  .get_your_card .getyour_card_text p {
    color: #B4B4B4;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

}

.row_your_card {
  padding: 34px 34px 32px 59px;
  background: #2B2B2B;
  border-radius: 30px;

  h1 {
    background: linear-gradient(91deg, #F0BF73 7.13%,
        rgba(204, 158, 72, 1.00) 70.29%, rgba(46, 167, 255, 0.99) 80.01%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0px;
  }

  p {
    color: #B4B4B4;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 48px;
  }

}

@media (max-width:560px) {
  .get_your_card {
    padding: 0px 20px;

    .white_bg {
      background: transparent;
    }
  }

  .row_your_card {
    padding: 34px 34px 34px 34px;
    background: #2B2B2B;
    border-radius: 30px;

    h1 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
      margin-bottom: 18px;
    }

  }

}

@media (min-width: 768px) {
  .row_your_card {
    display: flex;
  }
}

@media (min-width: 992px) {
  .row_your_card {
    display: flex;
    width: fit-content;
  }
}

.get_your_card .btn_get_zeeqr {
  width: 147px;
  color: #4D3000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  height: 46px;
  font-weight: 700;
  justify-content: center;
  border: 0px;
  border-radius: 10px;
  background: linear-gradient(94deg, #F1B63F 46.68%, #E0A819 102.15%);
  box-shadow: 0px 11px 9px 0px rgba(255, 163, 0, 0.13);
}


.get_your_card .btn_get_zeeqr:hover {
  background-color: #FFFFFF;
  color: #7A5908;

}

.topVectorImage {
  position: absolute;
  top: -15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomVectorImage {
  // position: absolute;
  // bottom: -15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 69px;
}

@media (max-width:560px) {
  .get_your_card {
    padding: 0px 20px;

    .white_bg {
      background: transparent;
    }
  }

  .row_your_card {
    padding: 34px 34px 34px 34px;
    background: #2B2B2B;
    border-radius: 30px;

    h1 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
      margin-bottom: 18px;
    }

  }
}