@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

body,
*,
button {
  font-family: 'Poppins', sans-serif;
}

.wrapper {
  background-color: #151515;

}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    padding: 50px 0 10px;
  }
}

.mainContainer {
  background: #151515;
  background-image: url('../../../assests/imges/vector/bg-image.svg');
  background-repeat: repeat;
  border-radius: 20px;
  z-index: 2;
  position: relative;
  margin-top: -30px;
}

.header_bg {

  background: url("../../../assests/imges/contact.png");
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  padding: 150px 0px 80px;
}

.contact_section {
  padding-top: 100px;
}

// .header1 {
//   width: 100%;
//   background-color: rgba(0, 0, 0, .2);
// }



.container {
  margin: 0 20px;
  padding: 0px 20px;
}
@media (max-width:560px) {
  .container {
    margin: 0;
    padding: 0px 20px;
  }
}
@media (min-width: 768px) {
  .container {
  max-width: 1200px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    margin: 30px 30px 80px 30px;
  }

}


@media (min-width: 992px) {
  .container {
    max-width: 1250px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}




.innerPagebanner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

@media (min-width: 769px) {
  .innerPagebanner {
    height: 250px;

  }
}

.innerPagebanner .blog_banner_text1 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  color: white;
  margin-bottom: 0;

  @media (min-width: 992px) {
    font-size: 60px;
    line-height: 78px;
  }
}

.innerPagebanner .blog_banner_text2 {
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: white;
  margin-bottom: 15px;

  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 28px;
  }
}






.contact_text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12.4897px;
  line-height: 19px;
  margin-top: 40px;

  color: #F6CD66;
  text-transform: uppercase;
  justify-content: center;
  display: flex;
  gap: 10px;
}

.contact_text:after {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  justify-content: center;
  background: #DBB24B;
  margin-top: 10px;

}

.contact_text:before {
  content: "";
  display: flex;
  width: 40px;
  height: 2px;
  background: #DBB24B;
  margin-top: 10px;

}

.contact_text1 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 38.7753px;
  line-height: 50px;
  text-align: center;
  background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
}

.contact_text1 span {
  color: #A5772B;
}

.contact_text2 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14.2879px;
  line-height: 30px;
  margin: 0 auto;
  text-align: center;
  color: #A5772B;
  max-width: 764px;

  margin-bottom: 100px;
}

.contact_column {
  float: left;
  width: 50%;

  input,
  select {
    width: 100%;
    height: 60px;
    padding: 12px 12px 12px 12px;
    border: none;
    outline: none;
    border-bottom-color: #e3e3e3 !important;
    border-bottom-width: 1px;
    border-bottom-style: outset;
    box-sizing: border-box;
    background-color: transparent;
    margin-top: 6px;
    margin-bottom: 15px;
    resize: vertical;
    color: #F6CD66;
    font-weight: 500;
    font-size: 15.5068px;
    line-height: 23px;
    font-family: "Inter", sans-serif;
  }

}

.textcontact textarea {
  width: 100%;
  height: 150px;
  padding: 12px 12px 12px 12px;
  border: none;
  outline: none;
  border-bottom-color: var(--light-white);
  border-bottom-width: 2px;
  border-bottom-style: outset;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 15px;
  resize: vertical;
  color: #F6CD66;
  font-weight: 500;
  font-size: 15.5068px;
  line-height: 23px;
  font-family: "Inter", sans-serif;
  background-color: transparent;
}

// .contact_row{

// }

@media (max-width: 768px) {
  .contact_row {
    float: left;
  }
}


@media (max-width: 768px) {
  .contact_column {
    width: 100%;
    text-align: center;
  }
}

// input[type="number"] {
//   -moz-appearance: textfield;
// }



input::placeholder,
textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A69D9D;
  opacity: 1;
  /* Firefox */
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #A69D9D;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #A69D9D;
}

// textarea {
//   margin-bottom: 50px;
//   height: 155px;
// }

@media (max-width: 768px) {
  textarea {
    margin-bottom: 40px;
    height: 155px;
  }
}

.contact_bg {
  background-color: #222;
  padding: 30px;
  margin-left: 80px;
}

@media (max-width: 768px) {
  .contact_bg {
    width: 100%;
    margin: 0;
  }
}

.contact_text3 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12.4555px;
  line-height: 19px;
  text-transform: uppercase;
  color: white;
}

.contact_text4 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 27.5711px;
  line-height: 41px;
  color: white;
}

.contact_text5 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 13.13px;
  line-height: 20px;
  color: white;
}

@media (min-width: 769px) {
  .contact_text5 {
    max-width: 300px;
  }
}

.contact_column_text {
  float: left;
  width: 35%;
  padding-top: 20px;
}

@media (min-width: 769px) {
  .contact_column_text {
    width: calc(100% - 125px);
  }

  .contact_column_text:first-child {
    width: 100px;
  }
}

@media (max-width: 768px) {
  .contact_column_text {
    float: none;
    width: auto;
    margin: 0 auto;
    text-align: center;
  }

  .contact_icon {
    margin-top: 15px;
    justify-content: center !important;
  }
}

.contact_column .btn {
  border-radius: 10px;
  background: linear-gradient(94deg, #F1B63F 46.68%, #E0A819 102.15%);
  color: #000;
  justify-content: center;
  width: 100%;
  height: 50px;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 768px) {
  .contact_column .btn {

    border-radius: 10px;
    background: linear-gradient(94deg, #F1B63F 46.68%, #E0A819 102.15%);
    color: #000;
    justify-content: center;
    width: 150px;
    margin-bottom: 30px;
    text-align: center;
  }
}

.contact_column .btn:hover {

  color: #FCFCFC;
  border: 0px solid #6F4F01;

}

.contact_location {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 17.4538px;
  line-height: 27px;
  color: #CCCCCC;
}

.contact_location a {
  color: #CCCCCC;
  text-decoration: none;
  transition: all 0.3s ease;
}

.contact_location a:hover {
  color: #CCCCCC;
}

.profiles1 {
  padding: 60px 0;
  margin-top: 180px;

}

@media (max-width: 768px) {

  .profiles1 {
    padding: 0px 20px;
  }
}



.profiles1 {
  background-repeat: no-repeat;
  background-size: cover;



}

.footer_text {


  width: 181px;
  height: 48px;
  left: 739.21px;
  top: 5147px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12.7586px;
  line-height: 19px;

  color: #D9D9D9;



}

.profiles1 h2 {
  font-weight: 600;
  font-size: 57.2339px;
  line-height: 60px;
  gap: 10px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;

  color: #000000;
}

@media (min-width: 768px) {
  .profiles1 h2 {
    font-size: 52px;
    line-height: 58px;
    gap: 10px;

  }
}

@media (min-width: 1200px) {
  .profiles1 h2 {
    font-size: 72px;
    line-height: 80px;
    gap: 10px;
    padding: 20px;

  }
}

.profiles1 .small {
  font-weight: 600;
  font-size: 32px;
  line-height: 50px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // margin-top: 1300px;
}

@media (min-width: 768px) {
  .profiles1 .small {
    font-size: 38px;
    line-height: 52px;
    margin-top: 500px;

  }
}

@media (min-width: 1400px) {
  .profiles1 .small {
    font-size: 42px;
    line-height: 60px;
    margin-top: 500px;
  }
}

.profiles1 span {
  width: 13px;
  height: 13px;
  display: inline-block;
  background: linear-gradient(92deg, #F5AC35 7.04%, rgba(20, 117, 188, 0.99) 111.71%);
  line-height: 13px;
  overflow: hidden;
  border-radius: 50%;
  text-indent: -9999px;
  position: relative;
  top: -5px;
}

@media (min-width: 1400px) {
  .profiles1 span {
    top: -8px;
  }
}

@media (max-width: 768px) {
  .profiles1 span {
    display: none;
  }
}

.profiles1 .nfc_text {
  width: 394;
  font-size: 18.49px;
  line-height: 28px;
  text-align: center;
  padding-bottom: 20px;
  color: #F6CD66;
  max-width: 894px;
  margin: auto;

}

.rowcontact {
  display: flex;


}

@media (min-width: 768px) {
  .rowcontact {
    display: flex;


  }
}

@media (min-width: 992px) {
  .rowcontact {
    display: flex;


  }
}

.footer {
  margin-top: 150px;
  padding-left: 0;
  background: radial-gradient(50% 50% at 50% 50%, #303030 0%, #272727 100%);
}

@media (min-width: 768px) {
  .get_nfc .btn_get_zeeqr {

    margin-bottom: 20px;
  }

  .footer {
    margin-top: 350px;
    padding-left: 20px;
  }
}

@media (max-width:600px) {
  .get_nfc {
    margin: 0px;
  }
}

@media (min-width: 992px) {
  .footer {
    margin-top: 0;
    padding-left: 0;
  }
}

.footer_zeeqr {
  padding-top: 100px;
  margin-top: 0;
  padding-left: 0px;

}

@media (min-width: 768px) {

  .footer_zeeqr {
    padding-top: 0;

  }

}

@media (min-width: 992px) {
  .footer_zeeqr {
    padding-top: 80px;

  }

}

.footer_logo .footer_logo_text {
  font-weight: 400;
  font-size: 17.3701px;
  line-height: 26px;
  color: #D9D9D9;
}

@media (min-width: 768px) {
  .footer_logo .footer_logo_text {
    width: 397px;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;
    color: #D9D9D9;
  }

}

@media (min-width: 992px) {
  .footer_logo .footer_logo_text {
    width: 397px;
    font-weight: 400;
    font-size: 17.3701px;
    line-height: 26px;

    color: #D9D9D9;
  }

}

.footer_logo .social_icons img {
  height: 55px;
  gap: 10px;
  padding: 5px;
}

@media (min-width: 768px) {
  .footer_logo .social_icons img {
    height: 55px;
    gap: 10px;
  }

}

@media (min-width: 992px) {
  .footer_logo .social_icons img {
    height: 55px;
    gap: 10px;
  }
}

.social_address .social_address_p {
  font-weight: 600;
  font-size: 21.37px;
  line-height: 32px;
  color: #D9D9D9;
  width: 141px;
  height: 37px;

}

@media (min-width: 768px) {
  .social_address .social_address_p {
    font-weight: 600;
    font-size: 21.37px;
    line-height: 32px;
    color: #D9D9D9;
    width: 141px;
    height: 27px;

  }
}

@media (min-width: 992px) {
  .social_address .social_address_p {
    font-weight: 600;
    font-size: 21.37px;
    line-height: 32px;
    color: #D9D9D9;
    width: 141px;
    height: 27px;

  }

}

.social_address .social_address1 {
  gap: 15px;

}

.social_address .social_address1 p {
  font-weight: 400;
  font-size: 12.7586px;
  line-height: 19px;
  max-width: 220px;
  color: #D9D9D9;
}

.social_address1 a {
  color: #D9D9D9;
  text-decoration: none;
}

/* .nav-footer {} */

@media (min-width: 768px) {
  .nav_footer {
    padding-left: 70px;
  }

}

@media (min-width: 992px) {
  .nav_footer {
    padding-left: 70px;
  }

}

.nav_footer p {
  font-weight: 600;
  font-size: 21.37px;
  line-height: 32px;
  width: 121px;
  color: #D9D9D9;
}

.nav_footer .nav_footer_list p {
  font-weight: 400;
  font-size: 12.7586px;
  line-height: 19px;
  width: 109px;
  height: 29px;
  color: #D9D9D9;
  text-decoration: none;

}

.nav_footer .nav_footer_list p a {
  text-decoration: none;
  color: #fff;
}

// .footercol{

// }
@media (min-width: 768px) {
  .footercol {
    display: flex;
  }

}

@media (min-width: 992px) {
  .footercol {
    display: flex;
  }

}

.colfooter {



  margin-top: 20px;

}

@media (min-width: 992px) {
  .colfooter {

    float: left;
    width: 50%;

  }
}

@media (min-width: 768px) {
  .colfooter {

    float: left;
    width: 50%;

  }

}

.text_white {
  width: 100%;
  height: 2px;

  margin-top: 40px;
  background: #D9D9D9;
  opacity: 0.24;
}

.footer_gallery p {
  font-weight: 600;
  font-size: 21.37px;
  line-height: 32px;

  color: #D9D9D9;
  width: 83px;
}

.footer_gallery .img_zeeqr {
  width: 50px;
}

@media (min-width: 768px) {
  .footer_gallery .img_zeeqr {
    width: 200px;
  }
}

.footer_gallery {
  padding-bottom: 15px;
}

.copyright {
  padding: 0 15px 15px 15px;
}

.copyright .copyright_text {
  font-weight: 400;
  margin: 30px;
  font-size: 12.7586px;
  line-height: 19px;
  text-align: center;
  background: linear-gradient(90deg, #DDB34A 0%, #966C05 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}




#slider-container {
  height: 150px;
  width: 100%;
  max-width: 1000px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  box-shadow: 0 0 15px #888;
}

#slider_scroller {

  top: 0;
  left: 0;
  width: calc(260px * 6);
  transition: all 1s ease;
}

.slider .item {


  display: inline-block;
  margin: 20px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200px;
  max-height: 500px;

}




.slider {
  display: flex;
  width: 100%;

  margin: 0 auto;
  overflow: hidden;
  padding: 2.5rem;
}

.item {
  animation: animate 20s linear infinite;
}

.item img {
  min-width: 250px;
  padding: 0 15px;
}

.slider:hover .item {
  animation-play-state: paused;
}

@keyframes animate {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-1800px, 0, 0);
  }
}

.top_btn .myBtn {
  position: fixed;
  bottom: 10px;
  float: right;
  right: 1.5%;
  margin-bottom: 80px;
  left: 84.25%;




}

@media (min-width: 768px) {
  .top_btn .myBtn {
    position: fixed;
    bottom: 10px;
    float: right;
    margin-bottom: 80px;
    right: 1.5%;
    left: 92.25%;




  }

}

@media (min-width: 992px) {

  .top_btn .myBtn {
    position: fixed;
    bottom: 10px;
    float: right;
    right: 1.5%;
    margin-bottom: 80px;
    left: 94.25%;




  }
}

.top_btn .myBtn1 {
  position: fixed;
  bottom: 10px;
  float: right;
  right: 1.5%;
  left: 84.25%;




}



@media (min-width: 768px) {
  .top_btn .myBtn1 {
    position: fixed;
    bottom: 10px;
    float: right;

    left: 92.25%;




  }


}

@media (min-width: 992px) {

  .top_btn .myBtn1 {
    position: fixed;
    bottom: 10px;
    float: right;

    left: 94.25%;


  }

  .social_address {
    padding: 0 0;
  }
}

.social_address {
  padding: 0 1rem;
}

._btn_ {
  background: linear-gradient(90deg, #DBB24B 0%, #966C05 99.99%, rgba(217, 217, 217, 0) 100%);
  border-radius: 5px;
  color: #6F4F01;
  justify-content: center;
  width: 100%;
  height: 50px;
  text-align: center;
}