@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700;900&display=swap');

::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}

:root {
    --icon-size: 45px
}


.card-wrapper-preview {
    position: relative;
    z-index: 9;

    .mobile-view {
        background-image: url(img/royal-bg-circle.svg);
        // background: linear-gradient(#FFB200, #8059001C);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
        width: 300px;
        height: 100px;
        position: absolute;
        bottom: 2px;
    }
}

.preview-card-container {
    --size: 120%;
    overflow: auto;
    position: relative;
    min-height: 100vh;
    background-color: #151515;

    .background-image {
        position: fixed;
        background-image: url(img/bg-image.svg);
        background-position: center;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    .desktop-view {
        background-image: url(img/royal-bg-circle.svg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 627px 627px;
        position: fixed;
        bottom: 0px;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    @media (max-width:560px) {
        .desktop-view {
            background-size: 130%;
        }
    }

    #card-wrapper-preview {
        max-width: 300px;
        max-height: 620px;
        min-height: 620px;
        border: 1px solid black;
        border-radius: 10px;
        overflow: scroll;
        position: sticky;
        top: 10px;
        margin: auto;
        scrollbar-width: none;
        z-index: 99;
    }

    .powered-text {
        color: #fff;
        font-weight: 600;
        text-align: center;
        margin: 1% 0px;
    }

    .device-wrapper {
        width: 370px;
        height: 580px;
        margin: auto;
        position: relative;
        z-index: 999;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;



        .scale-07 {
            zoom: 0.7;
            max-zoom: 0.6;
            -moz-transform: scale(0.7);

        }

        .device-linear-bg-wrapper {
            width: 290px;
            height: 99%;
            margin: auto;
            position: absolute;
            z-index: -1;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 40px;
            top: 0px;
            border-radius: 60px;
            background-color: #DCDCDC;
        }

        @media (max-width:500px) {
            .device-linear-bg-wrapper {
                display: none;

            }
        }

        .device-linear-bg {
            width: 550px;
            height: 550px;
            border-radius: 500px;
            background: radial-gradient(63.59% 63.59% at 50% 36.41%, #FFDA80 0%, #F3AC00 100%);
            position: absolute;
            bottom: 35%;
            z-index: 1;
            left: -40%;
        }
    }

    .device-image {
        position: absolute;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        z-index: 99;
        pointer-events: none;
        top: 0px;
        left: 0px;
    }

    .linear-bg {
        width: var(--size);
        height: var(--size);
        border-radius: var(--size);
        // border-radius: 824px;
        // background: linear-gradient(0deg, #FCA927 0%, #FFF 54.73%);
        background: radial-gradient(63.59% 63.59% at 50% 36.41%, #FFDA80 0%, #F3AC00 100%);
        position: fixed;
        bottom: 50%;
        z-index: 1;
        left: -150px;
    }

    @media (max-width:968px) {
        .linear-bg {
            width: 942px;
            height: 942px;
            border-radius: 942px;
            bottom: 50%;
            left: -12%;
        }

    }

    @media (max-width:500px) {
        .linear-bg {
            width: 942px;
            height: 942px;
            border-radius: 942px;
            bottom: 50%;
            left: -58%;
        }


    }

    .main-container {
        padding: 0px 15px;
        margin-top: -30px;
    }

    .top-container {
        background-color: #2d2b2b;
        height: 130px;
        border-radius: 10px 10px 0px 0px;
        background-size: cover;
    }

    .mid-container {
        width: 100%;
        // height: 135px;
        display: grid;
        border-radius: 10px;
        grid-template-columns: 35% auto;
        box-sizing: content-box;

    }

    .profile-img {
        height: 98%;
        border-radius: 10px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        margin-right: -10px;
        z-index: 9;
        border: 2px solid #fff;
        box-sizing: inherit;
    }

    .profile-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .top-details {
        height: 100%;
        background-color: #000;
        border-radius: 0px 10px 10px 0px;
        padding: 0.5rem;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding-left: 20px;
    }

    .top-details h1 {
        color: #fff;
        font-family: 'Oswald', sans-serif;
        line-height: 1;
        text-transform: uppercase;
    }

    .top-details span {
        font-size: 0.8rem;
        color: #e1e1e1;
        font-family: 'Roboto', sans-serif;
        line-height: 1;
        /* text-transform: none; */
    }

    .bottom-container {
        padding-top: 20px;

    }

    .btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    .add-to-contact {
        background-color: #000;
        color: #ffff;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

    }

    .share-button {
        background-color: rgb(221, 221, 221);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        color: #000;
    }

    .add-to-contact,
    .share-button {
        font-size: 0.75rem;
        font-weight: 400;
        height: 35px;
        width: 100%;
        border: none;
        outline: none;
        font-family: 'Roboto', sans-serif;
        padding: 11px;
    }

    .about-container p {
        word-wrap: break-word;
        width: 100%;
        font-family: 'Roboto', sans-serif;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.3;

    }

    .section-heading {
        font-size: 0.9rem;
        padding-bottom: 0.3rem;
        font-family: 'Roboto', sans-serif;
        width: fit-content;
        color: #000;
        position: relative;
        letter-spacing: 0.2px;
        margin-bottom: 10px;
        width: 100%;

    }

    .section-heading::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 20px;
        height: 1.5px;
        background-color: #000;
    }

    .social-media {
        padding-top: 8px;
        position: relative;
        display: flex;
        align-items: center;

    }

    .left-arrow {
        padding-right: 5px;
    }

    .right-arrow {
        padding-left: 5px;
    }

    ._social_items_ .slide-item-div {
        /* min-height: 45px; */
        /* min-width: 45px; */
        width: var(--icon-size);
        height: var(--icon-size);
        display: flex;
        align-items: center;
        justify-content: center;
        /* background-color: #ededed; */
        margin: 0px auto;
    }

    ._social_items_ a img {
        height: var(--icon-size);
        width: var(--icon-size);
        object-fit: cover;
    }

    ._social_items_ {
        height: var(--icon-size);
        width: 100%;
        overflow-x: auto;
    }

    .contact-info .phones-info a,
    .contact-info .emails-info a {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        font-size: 0.9rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        color: #000;
    }

    .contact-info .phones-info img,
    .contact-info .emails-info img {
        // border: 1px solid #E2E2E2;        
        object-fit: cover;
        width: 20px;
        height: 20px;
        // box-shadow: 0px 0px 1px gray;
    }


    .about-container,
    .Social-link-container,
    .contact-info {
        margin-bottom: 15px;
    }

    .view_tab {
        text-align: center;
        padding: 10px 0px;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 9;

        ._tabs_ {
            padding: 2px;
            border-radius: 100px;
            background: linear-gradient(92deg, #FFDD55, #FBD853, #B27C27, #FDDB54, #B27C27);
            box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.18);
            // box-shadow: 0px 5px 10px #575757;
            width: 100px;
            height: 35px;
        }

        .tabs__ {
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            background: linear-gradient(92deg, #D38800 5.78%, #D48800 17.09%, #FEB42E 41.12%, #FCB12B 57.14%, #DD9411 77.87%, #FFB52F 96.25%);
            box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.18);
            height: 100%;
        }

    }



    @media (max-width:500px) {

        .device-image,
        .view-tab {
            display: none;
        }
    }

    @media (max-width:500px) {
        .card-wrapper-preview {
            margin: 0px auto;
            display: block;
            height: 100%;
        }

        #card-wrapper-preview {
            background-color: #ffff;
            max-width: 100%;
            max-height: unset;
            height: 100%;
            border: 0px solid black;
            border-radius: 0px;
            position: relative;
            top: 0px;
            /* transform: scale(1); */
        }

        .device-wrapper {
            width: 100%;
            height: 100%;
            align-items: flex-start;
            margin-top: 20px;
        }

        #card-wrapper-preview .top-container {
            border-radius: 0px;
        }

        .powered-text {
            margin: 5% 0px;
            padding-top: 5%;
            padding-bottom: 5%;
        }

    }

    @media (min-width: 1200px) {
        .view-tab {
            padding: 2rem 0px;
            padding-bottom: 0.5rem;
        }

        .desktop-view {
            background-position: center 100px;
        }
    }

    @media (min-width: 1400px) {
        .view-tab {
            padding: 6rem 0px;
            padding-bottom: 3rem;
        }

        .desktop-view {
            background-position: center 350px;
        }
    }
}

.no-card-found {
    min-height: 100vh;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.no-card-found p {
    font-size: 20px;
    font-weight: 600;
}

@media (max-width:500px) {
    .preview-card-container {
        min-height: auto;
        // background: #f00;
    }
}











.previewContainerParent {
    background-image: url(img/royal-bg-circle.svg);
    background-size: 70% 70%;
    background-repeat: no-repeat;
    background-position: bottom;
    min-height: 100vh;
    overflow: hidden;
}

.previewCardContainer {
    margin: 0px auto;
    height: 100vh;
    overflow-y: auto;

    .FullContainer {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

    }

    @media (max-width:500px) {
        .FullContainer {
            height: unset;
            padding-top: 20px;
        }
    }

    .mobileFullContainer {
        max-width: 360px;
        height: 650px;
        overflow: hidden;
        margin: 0 auto;
        position: relative;
        top: 10%;
        transition: 0.5s;
    }

    .TemplateWapper {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;

        .containerHeight {
            height: 560px;
        }
    }

    .mobileTemplateWrapper {
        max-width: 310px;
        height: 99%;
        margin: 0px auto;
        overflow: auto;
        transition: 0.5s;
        border-radius: 80px;

        .zoom {
            zoom: 0.9;
            padding-top: 22px;
            height: 100%;
        }

    }

    .DeviceImage {
        width: 100%;
        position: absolute;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        top: 0px;
        z-index: 9;
        pointer-events: none;
        transition: 0.5s;

    }
}

.view_tab {

    text-align: center;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 9;
    align-items: center;
    width: 100%;
    top: 20px;

    ._tabs_ {
        padding: 2px;
        border-radius: 100px;
        background: linear-gradient(92deg, #FFDD55, #FBD853, #B27C27, #FDDB54, #B27C27);
        box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.18);
        // box-shadow: 0px 5px 10px #575757;
        width: 100px;
        height: 35px;
    }

    .tabs__ {
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        background: linear-gradient(92deg, #D38800 5.78%, #D48800 17.09%, #FEB42E 41.12%, #FCB12B 57.14%, #DD9411 77.87%, #FFB52F 96.25%);
        box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.18);
        height: 100%;
    }

}

@media (max-width:500px) {
    .view_tab {
        display: none;
    }

    .previewContainerParent {
        background-size: 117%;
    }

    .blur {
        display: none;
    }

}

.blur {
    position: fixed;
    width: 100%;
    height: 100%;
    filter: blur(5px);
    background-size: cover;
    background-position: center;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    transition: 0.5s;
    pointer-events: none;
}

.poweredText {
    position: relative;
    z-index: 9;
    font-size: 1rem;
    bottom: 0px;
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 20px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .view_tab {
        display: none;
    }

    .previewCardContainer {
        .FullContainer {
            height: unset;
            padding-top: 20px;
        }
    }

    .blur {
        display: none;
    }
    .containerHeight{
        zoom: 0.85;
    }

}