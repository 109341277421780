.CardTemplate8 {
height: 98%;

    .CardWrapper {

        width: 310px;
        height: 530px;
        overflow: hidden;
        position: relative;
        margin: auto;

        .CardContainer {
            border-radius: 16px;
            position: relative;
            border: 1px solid #43434378;
            box-sizing: border-box;
            overflow: scroll;
            height: 100%;
        }

        .TopBackground {
            height: 310px;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 60%;
                object-fit: cover;
            }

            .ShareIcon {
                position: absolute;
                top: 17px;
                z-index: 99;
                right: 12px;
            }

            .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0.6;
                background: linear-gradient(268deg, #000 1.58%, rgba(0, 0, 0, 0.00) 93.99%);
                z-index: 0;
                top: 0px;
            }
        }

        .CardBody {
            width: 100%;
            background-color: #fff;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
            display: block;
            height: 100%;

            .CardProfileImage {
                border-radius: 14px;
                width: 121px;
                height: 121px;
                overflow: hidden;
                box-sizing: border-box;
                margin: auto;
                padding: 2px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .CardTopContainer {
                padding: 20px;
                padding-bottom: 0px;
                display: grid;
                align-items: center;
                grid-template-columns: 56% 44%;
            }

            .CardContent {
                padding: 20px;
                display: flex;
                flex-direction: column;
                padding-bottom: 0px;
                height: 100%;
            }

            .CardContentTop {
                // display: flex;
                // justify-content: space-between;
                // margin-bottom: 22.5px;
                // flex: 1;
                // flex-shrink: 1;
            }

            .LocationIcon {
                width: 30px;
                height: 30px;
                border: 0.5px solid #E2E2E2;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 30px;

            }

            .CardText_P {
                color: #414141;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .CardText_p2 {
                color: #101010;
                font-family: Inter;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                display: inline;
                margin-right: 9px;
                line-height: 20px;
            }

            .CardText_p3 {
                color: #1877DB;
                font-family: Inter;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                white-space: nowrap;
            }

            .CardScrollWrapper {
                display: flex;
                flex: 1;
                flex-grow: 12;
                position: relative;
                height: 100%;
                padding-bottom: 0px;
            }

            .CardScrollContainer {
                flex: 1;
                overflow: auto;
                max-height: 100%;
                padding-bottom: 80%;
            }

            .AddressContainer {
                display: flex;
                align-items: flex-start;
                margin-bottom: 12.5px;
                gap: 10px;

                p {
                    color: #1877DB;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            .AboutContainer {
                margin-bottom: 10px;

                p {
                    color: #494949;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .CardLinksContainer {
                margin-bottom: 10px;

                .CardLinkRow {
                    display: grid;
                    grid-template-columns: 15% 85%;
                    padding: 5px 0px;
                    align-items: center;
                }

                .Icon {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 0.5px solid #EEEEEE;
                    border-radius: 30px;
                }

                p {
                    color: #1877DB;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
.divider {
    position: relative;
    padding-top: 10px;
}

.divider::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 1px;
    background-color: #D3D3D3;
    left: 30%;
}
            }



        }

        .BottomButton {
            position: absolute;
            bottom: 0px;
            right: 0px;
            left: 0px;
            width: 100%;
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            z-index: 1;
            border-bottom: 1px solid #E2E2E2;
            border-left: 1px solid #E2E2E2;
            border-right: 1px solid #E2E2E2;
            border-radius: 0px 0px 16px 16px;

            .SaveButton {
                width: 100%;
                color: #B17A26;
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .ShareButton {
                width: 100%;
                color: #444;
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .verticalDivider {
                background: #EEE;
                width: 1px;
                height: 22px;
            }

        }
    }
}

@media (max-width:500px) {
    .CardTemplate6 {        
        height: unset;
    }
}