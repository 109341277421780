.CardContainer {
    border-radius: 20px;
    border: 3px solid #ECC466;
    background: linear-gradient(103deg, #111 7.89%, #000 30.16%, #333 66.33%, #222 66.34%, #000 92.32%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .CardBody {
        padding: 18px 15px 18px 22px;
    }

    .CardBody {
        display: flex;

    }

    .CardInner {
        flex: 1;
        display: flex;
    }

    .textContainer {
        float: left;
        flex-grow: 1;
        padding-right: 50px;

        .textLabel {
            background: linear-gradient(111deg, #F6CD66 15.26%, #DFB858 52.78%, #63440F 55.28%, #4E3002 95.3%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            width: fit-content;
            font-size: 48px;
            font-weight: 200;
            margin-bottom: 13px;
        }

        span {
            color: #FAFAFA;
            font-size: 14px;
            font-weight: 600;
        }
    }

    .copyButton {
        flex-shrink: 0;
        float: right;
    }
}