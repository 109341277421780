@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

// .background{
//     background-image: url("../../../assests/imges/lognbg.webp"); 
//     background-size: cover;
// }
.fix {
    margin-left: 0;
    margin-top: 5px;
    cursor: pointer;
}




.content {
    color: #FFFFFF;
    margin-bottom: 30px;
}

.form_password {
    position: relative;
}

.fprm_passwordbtn {
    position: absolute;
}

.checkbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;

    @media (min-width: 768px) {
        flex-direction: row;
    }
}

.loginForm .check .Privacy {
    margin-left: 0;
    white-space: nowrap;
}

.loginForm .check {
    margin-left: 0;
}

.bg {
    width: 100%;
    height: 100%;
    position: absolute;

}



* {
    font-family: "Poppins", sans-serif;
}




.loginwrap {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 15;
}

.loginForm {
    width: 100%;
    max-width: 320px;
    padding: 25px 20px;
    margin: 15px auto;
    position: relative;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    background: linear-gradient(130deg, rgba(205, 205, 205, 0.207) 0%, rgba(40, 40, 40, 0) 100%);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    /* background-color: rgba(255, 255, 255, 0.2); */
}






.loginForm .form_control {

    height: 48.76px;
    border: 0.937701px solid #DCB249;
    border-radius: 6.56391px;
    background: transparent;
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15.9409px;
    line-height: 24px;
    color: #FFFFFF !important;
    opacity: 0.45;
    padding: 0px 15px;

    input {
        background-color: transparent;
    }

}

.loginForm .Privacy a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13.1278px;
    line-height: 20px;

    color: #FFFFFF;
}


.forgot {
    font-size: 18px;
    margin-top: 20px;
}

.loginForm .form_control::placeholder {
    color: #fff;
}

.loginForm .form_control:focus {
    box-shadow: none;
}

.loginForm .phone-group {
    position: relative;
}

.loginForm .phone-group .form-control {
    padding-left: 78px;
    // position: relative;
}

.loginForm .phone-group .phone {
    position: absolute;
    left: 0;
    top: 9px;
    font-size: 14px;
    height: 20px;
    display: flex;
    align-items: center;
}

.loginForm h2 {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #000;
}


.loginForm .btn_primary {
    height: 41px;
    border: none;
    margin-top: 15px;
    width: 100%;
    font-weight: 400;

    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    letter-spacing: 0.15em;
border: 2px solid #FD5;
    background: linear-gradient(92deg, #D38800 5.78%, #D48800 17.09%, #FEB42E 41.12%, #FCB12B 57.14%, #DD9411 77.87%, #FFB52F 96.25%);
    border-radius: 6.52397px;
    outline: none;
    box-shadow: none;
}

.loginForm .cursor_pointer {

    margin: 10px;
    background: linear-gradient(92deg, #D38800 5.78%, #D48800 17.09%, #FEB42E 41.12%, #FCB12B 57.14%, #DD9411 77.87%, #FFB52F 96.25%);
    border-radius: 6.52397px;
}



.loginForm .alert {
    font-size: 13px;
    padding: 5px 10px;
}

.loginForm .alert ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.cursor_pointer {
    cursor: pointer;
}

.font-weight-semibold {
    font-weight: 600;
}

.owl-carousel.owl-bg-slider {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 10;
}

.forgotPassword {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;

    h3 {
        font-weight: 600;
        font-size: inherit;
        margin-bottom: 10px;
        color: inherit;
    }



}

.forgotPassword p {
    text-align: left;
    font-size: 13px;
    color: #fff;
    text-decoration: none;
    margin-bottom: 10px;
}

@media screen and (min-width: 600px) {
    .loginForm {
        max-width: 380px;
    }

    .loginForm h1 {
        width: 135px;
        height: 135px;
        top: -67.5px;
        margin: 0 0 0 -67.5px;
    }

    .loginForm .btn_primary {
        height: 48px;
        font-size: 14px;
    }
}

.text_white {
    width: 117px;
    height: 20px;
    left: 772px;
    top: 638.33px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13.1278px;
    line-height: 20px;
    /* identical to box height */


    color: #A3A3A3;
}

.otpinputStyle {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    border: 0.937701px solid #DCB249;
    border-radius: 6.56391px;
    background: transparent;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15.9409px;
    line-height: 24px;
    color: #FFFFFF !important;
    opacity: 0.45;
    padding: 0px 15px;
}