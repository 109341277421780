@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.bg {
    background: transparent;
}

.bg1 {
    background: #000000;
}

.navbar_brand {
    display: flex;
    align-items: center;

    img {
        width: 130px;
    }

    @media (max-width:560px) {
        img {
            width: 110px;
        }
    }
}

header {
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: 2;
}

header nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

header nav .menu_icon {
    display: block;

    height: 30px;
    place-items: center;
    width: 30px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
}

header nav .menu_icon .fatimes {
    display: block;

    height: 30px;
    place-items: center;
    width: 30px;
    color: white;
    justify-content: center;
    align-items: center;
}

header nav .menu_icon .fabars {
    display: block;
    color: white;
    height: 30px;
    place-items: center;
    width: 30px;

    justify-content: center;
    align-items: center;
}

header nav .menu_icon1 {
    display: block;
    height: 30px;
    border-radius: 10px;
    margin-top: 20px;


}

header nav .menu_icon1 .fatimes {
    display: block;

    height: 30px;
    place-items: center;
    width: 30px;
    color: black;
    justify-content: center;
    align-items: center;
}

header nav .menu_icon1 .fabars {
    display: block;
    color: black;
    height: 30px;
    place-items: center;
    width: 30px;

    justify-content: center;
    align-items: center;
}

header .navv {
    display: none;
}

.mobileMenu {
    display: block;
}

header ul li {
    margin: 20px 26px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

}

header ul li:last-child {
    margin: 20px 26px;
    margin-right: 0px;

}

header li {

    text-decoration: none;

}

header li .navbar {
    transition: all 0.3s ease;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-style: 700;
    color: #FFAA12;
    text-align: center;

}

header li .navbar1 {
    transition: all 0.3s ease;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-style: 700;
    color: #FFAA12;
    text-align: center;
}

header li .navbar:hover {
    font-size: 16px;
    line-height: 24px;
    color: #FF9C00;
    border: none;
}

header li .navbar1:hover {
    font-size: 16px;
    line-height: 24px;
    color: #FF9C00;
    border: none;
}

header li .navbar:active {
    font-size: 16px;
    font-weight: 600px;
    line-height: 24px;
    color: #FF9C00;
    border: none;
}

.ActiveIndicator {
    position: absolute;
    bottom: 0;
    min-width: 135%;
    margin: auto;
}

.s_navContainer {
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: 99;
    background: linear-gradient(180deg, rgba(17, 17, 17, 0.75) 0%, rgba(17, 17, 17, 0.47) 53.12%, rgba(17, 17, 17, 0.00) 100%);
}

.s_navbar {
    flex-grow: 1;

    ul {
        display: flex;
        margin-left: auto;
        margin-bottom: 0px;
        justify-content: flex-end;
    }

    ul li {
        margin: 20px 26px;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        height: 40px;
    }



    .s_nav_link {
        color: #FF9C00;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .s_nav_link:hover {
        color: #FF9C00;
    }
}

.LogoImage {
    width: 130px;
}

.container {
    margin: 0px 30px 50px 30px;
    padding: 0px 20px;
}

@media (max-width:560px) {
    header {
        z-index: 9;
    }

    header li .ActiveIndicator {
        position: absolute;
        bottom: 0;
        min-width: unset;
    }
}


header li .btn_wrapper {
    background: linear-gradient(92deg, #FFDD55, #FBD853, #B27C27, #FDDB54, #B27C27);
    padding: 2px;
    border-radius: 10px;
}

header li .btn {
    border-radius: 10px;
    background: linear-gradient(92deg, #D38800 5.78%, #D48800 17.09%, #FEB42E 41.12%, #FCB12B 57.14%, #DD9411 77.87%, #FFB52F 96.25%);
    box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.18);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    height: 47px;
    color: #4D3000;
    ;
    text-align: center;
    align-items: center;
    padding: 0 22px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}

header li .btn .span {
    width: 20px;
}

header li .btn1 {
    background: transparent;
    font-weight: 1000;
    font-size: 16px;
    line-height: 24px;
    height: 47px;


    color: #795909;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    padding: 0 22px;
    border: 2px solid #795909;
    display: flex;
    align-items: center;
}

header li .btn .span {
    width: 20px;



}

@media (min-width: 768px) {

    .container {
        margin: 30px 30px 80px 30px;
    }

    header .navv {
        display: block;
    }

    .mobileMenu {
        display: none;
    }

    header .navv ul {
        display: flex;
    }

}

@media (min-width: 992px) {
    .container {
        max-width: 1250px;
        margin: 0 auto;
    }

    .showInMobile {
        display: none;
    }

    .showInDesktop {
        display: block;
    }

    header nav .menu_icon {
        display: none;


    }

    header nav .menu_icon1 {
        display: none;


    }
}

@media (max-width: 992px) {
    header.bg {
        background: transparent;
    }

    .nav_menu,
    .navv {
        background: #151515;
        position: absolute;
        left: 0;
        top: 80px;
        width: 100%;
        z-index: 10;
    }

    .nav_menu {
        background-image: url('../../../assests/imges/vector/bg-image.svg');
    }

    header .container {
        margin: 0px 20px 20px 20px;

        .navbar_brand {
            padding: 15px 0;
        }

        .menu_icon1,
        .menu_icon {
            cursor: pointer;
            margin-top: 18px;
        }

        .nav_menu {
            .navbar1 {
                color: #ffaa12;
                -webkit-text-fill-color: #ffaa12;
                -moz-text-fill-color: #ffaa12;
                text-fill-color: #ffaa12;
                margin-bottom: 2px;
            }

            .btn {
                width: 100%;
                justify-content: center;
            }
        }
    }

    .showInMobile {
        display: block;
    }

    .showInDesktop {
        display: none;
    }
}
@media (max-width:560px) {
 header .container {
        margin: 0;
        padding: 0px 20px !important;
    }
}
@media (max-width:500px) {
    .s_navContainer .s_navbar {
        display: none;
    }
}

@media (max-width:560px) {
    .LogoImage {
        width: 110px;
    }

    .s_navContainer {
        padding: 25px 0px;
        // background: #151515;
        position: relative;
    }

    .navLink {
        display: block;
        padding: 15px 10px;
        color: #FF9C00;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .nav_menu {
        padding-top: 100px;
        top: 0px;
        z-index: 999;
    }

    .menu_icon1,
    .navbar_brand {
        z-index: 9999;
        color: #ffaa12;
    }

    .menu_icon1 svg {
        color: #D48800;
    }
}

@media (max-width:820px) {
    header .navv {
        display: none;
    }

    .LogoImage {
        width: 110px;
    }

    .s_navContainer {
        padding: 25px 0px;
        // background: #151515;
        position: absolute;
    }

    .navLink {
        display: block;
        padding: 15px 10px;
        color: #FF9C00;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .nav_menu {
        padding-top: 100px;
        top: 0px;
        z-index: 999;
    }

    .menu_icon1,
    .navbar_brand {
        z-index: 9999;
        color: #ffaa12;
    }

    .menu_icon1 svg {
        color: #D48800;
    }

    header li .ActiveIndicator {
        position: absolute;
        bottom: 0;
        min-width: unset;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .s_navbar{
        display: none;
    }
}