.CardTemplate9 {

    --margin: 20px;
    --padding: 20px;
    --shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;
    height: 100%;

    .backgroundImage {
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-radius: 10px;
    }

    .CardWrapper {
        max-width: 600px;
        max-height: 100%;
        display: block;
        position: relative;
        overflow: auto;
        padding: 20px;
        margin: 0px auto;

        .logoImage {

            background-color: #fff;
            align-self: center;
            padding: 14px 0px;
            margin-bottom: 40px;
            border-radius: 10px;
            box-shadow: var(--shadow);

            img {
                width: 100px;
                height: auto;
                margin: 0px auto;

            }
        }

        .userInfo {

            background-color: #fff;
            align-self: center;
            padding: 14px 10px;
            text-align: center;
            border-radius: 10px;
            margin-bottom: var(--margin);
            box-shadow: var(--shadow);
            margin-top: 120px;

            img {
                width: 100px;
                height: 100px;
                border-radius: 100px;
                margin: 0px auto;
                margin-top: -64px;
                margin-bottom: 10px;
            }

            h2 {
                font-size: 1.8rem;
                font-weight: 600;
                color: #000;
                padding: 0px var(--padding);
            }

            h6 {
                font-size: 0.8rem;
            }

            p {
                font-size: 0.9rem;
                font-weight: 400;
                color: #000;
                padding: 0px var(--padding);
                text-align: center;
                line-height: 1.2;
            }
        }

        .socialMediaContainer {
            background-color: #fff;
            margin-bottom: var(--margin);
            padding: var(--padding);
            border-radius: 10px;
            box-shadow: var(--shadow);

            h4 {
                font-size: 1.3rem;
                text-align: center;
            }
        }

        .contactContainer {

            .strip {
                background-color: #fff;
                margin-bottom: var(--margin);
                padding: 14px var(--padding);
                border-radius: 10px;
                box-shadow: var(--shadow);

                .stripRow {
                    display: flex;
                    align-items: center;

                    .icon {
                        flex-grow: 1;
                    }

                    .label {
                        flex-grow: 1;
                        font-size: 1rem;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}