.CardTemplate7Wrapper {
   
    height: 98%;
}

.CardTemplate7 {
   
height: 100%;
    .CardWrapperDrawer,
    .CardWrapper {

        width: 310px;
        height: 100%;
        overflow: hidden;
        position: relative;
        margin: auto;

        .CardContainer {
            border-radius: 16px;
            position: relative;
            border: 1px solid #E2E2E2;
            box-sizing: border-box;
            overflow: hidden;
            height: 100%;
        }

        .TopBackground {
            height: 310px;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 60%;
                object-fit: cover;
            }

            .ShareIcon {
                position: absolute;
                top: 17px;
                z-index: 99;
                right: 12px;
            }

            .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.4);
                z-index: 0;
                top: 0px;
            }
        }

        .CardBody {
            position: absolute;
            top: 140px;
            width: 100%;
            background-color: #fff;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
            display: block;
            height: 100%;

            .CardProfileImage {
                border: 3px solid #fff;
                border-radius: 122px;
                width: 122px;
                height: 122px;
                overflow: hidden;
                box-sizing: border-box;
                margin-right: auto;
                margin-top: -110px;
                margin-left: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .CardContent {
                padding: 20px;
                display: flex;
                flex-direction: column;
                padding-bottom: 0px;
                height: 100%;
            }

            .CardContentTop {
                display: flex;
                justify-content: space-between;
                margin-bottom: 22.5px;
                flex: 1;
                flex-shrink: 1;
            }

            .CardText_P {
                color: #404040;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .CardText_p2 {
                color: #101010;
                font-family: Inter;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: inline;
            }

            .CardText_p3 {
                color: #1877DB;
                font-family: Inter;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left: 9px;
            }

            .CardScrollWrapper {
                display: flex;
                flex: 1;
                flex-grow: 12;
                position: relative;
                height: 100%;
            }

            .CardScrollContainer {
                flex: 1;
                overflow: auto;
                max-height: 100%;
                padding-bottom: 110%;
            }

            .AddressContainer {
                display: flex;
                align-items: flex-start;
                margin-bottom: 12.5px;
                gap: 10px;

                p {
                    color: #1877DB;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            .AboutContainer {
                margin-bottom: 20px;

                p {
                    color: #494949;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .CardLinksContainer {
                margin-bottom: 10px;

                .CardLinkRow {
                    display: grid;
                    grid-template-columns: 10% 90%;
                    padding: 5px 0px;
                }

                p {
                    color: #1877DB;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }



        }

        .SaveButton {
            position: absolute;
            bottom: 0px;
            right: 0px;
            left: 0px;
            z-index: 1;

            button {
                border-radius: 0px 0px 16px 16px;
                background: #F6CD66;
                // box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                width: 100%;
                height: 45px;
                color: #774B07;
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

        }
    }
}

.CardBackSide {
    height: 100%;

    .CardTemplate7 {
        .CardBody {
            position: relative;
            top: 0;
        }

        .TopBackground {
            height: 354px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            background-size: cover;

            .overlay {
                opacity: 0.6;

                background: linear-gradient(0deg, #000 1.58%, rgba(0, 0, 0, 0.00) 93.99%);
                background-color: rgba(0, 0, 0, 0.0);
            }
        }



        .CardProfileImage {
            border: 3px solid #fff;
            border-radius: 122px;
            width: 122px;
            height: 122px;
            overflow: hidden;
            box-sizing: border-box;
            position: relative;
            z-index: 9;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .SaveButton {
            button {
                border: 2px solid rgba(255, 221, 85, 0.40);
                background: #FFF;

            }

        }
    }
}

@media (max-width:500px) {
    .CardTemplate7Wrapper {
        position: relative;
        top: 0px;
        height: 100%;

        .CardTemplate7 {
            .CardWrapper {
                height: calc((var(--vh, 1vh) * 100) - 40px);

            }
        }

        .CardBackSide {
            .CardWrapper {
                height: calc((var(--vh, 1vh) * 100) - 40px);

            }
        }
    }
}