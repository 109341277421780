.CardTemplate5 {
height: 98%;
    .CardWrapperDrawer,
    .CardWrapper {

        width: 310px;
         height: 100%;
        overflow: hidden;
        position: relative;
        margin: auto;

        .CardContainer {
            border-radius: 16px;
            position: relative;
            border: 1px solid #43434378;
            box-sizing: border-box;
            overflow: hidden;
            height: 100%;
        }

        .TopBackground {
            height: 310px;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 60%;
                object-fit: cover;
            }

            .ShareIcon {
                position: absolute;
                top: 17px;
                z-index: 99;
                right: 12px;
            }

            .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 1;
                background: #fff;
                z-index: 0;
                top: 0px;
            }
        }

        .CardBody {
            position: absolute;
            top: 140px;
            width: 100%;
            background-color: #fff;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
            display: block;
            height: 100%;

            .CardProfileImage {
                border-radius: 122px;
                width: 122px;
                height: 122px;
                overflow: hidden;
                box-sizing: border-box;
                margin: auto;
                margin-top: -110px;
                background: linear-gradient(#F6CD66, #C08F15);
                padding: 2px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 122px;
                }
            }

            .CardContent {
                padding: 20px;
                display: flex;
                flex-direction: column;
                padding-bottom: 0px;
                height: 100%;
            }

            .CardContentTop {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                flex: 1;
                flex-shrink: 1;
            }

            .LocationIcon {
                width: 30px;
                height: 30px;
                border: 0.5px solid #E2E2E2;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 30px;

            }

            .CardText_P {
                color: #414141;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .CardText_p2 {
                color: #101010;
                font-family: Inter;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                display: inline;
                margin-right: 9px;
                line-height: 20px;
            }

            .CardText_p3 {
                color: #1877DB;
                font-family: Inter;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                white-space: nowrap;
            }

            .CardScrollWrapper {
                display: flex;
                flex: 1;
                flex-grow: 12;
                position: relative;
                height: 100%;
                padding-bottom: 0px;
            }

            .CardScrollContainer {
                flex: 1;
                overflow: auto;
                max-height: 100%;
                padding-bottom: 100%;
                overflow: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;

            }

            .AddressContainer {
                display: flex;
                align-items: flex-start;
                margin-bottom: 12.5px;
                gap: 10px;

                p {
                    color: #1877DB;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            .AboutContainer {
                margin-bottom: 5px;

                p {
                    color: #494949;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .CardLinksContainer {
                margin-bottom: 10px;

                .CardLinkRow {
                    display: grid;
                    grid-template-columns: 15% 85%;
                    padding: 5px 0px;
                    align-items: center;
                }

                .Icon {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 0.5px solid #EEEEEE;
                    border-radius: 30px;
                }

                p {
                    color: #1877DB;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }



        }

        .SaveButton {
            position: absolute;
            bottom: 0px;
            right: 0px;
            left: 0px;
            z-index: 1;

            button {
                border-radius: 0px 0px 16px 16px;
                width: 100%;
                height: 45px;
                border-bottom: 1px solid;
                border-left: 1px solid;
                border-right: 1px solid;
                border-color: #E2E2E2;
                background: #FFF;
                color: #7B510E;
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

        }
    }
}

@media (max-width:500px) {
    .CardTemplate5 {
        position: relative;
        top: 0px;
        height: 100%;

        .CardWrapper {
            height: calc((var(--vh, 1vh) * 100) - 40px);

        }
    }
}