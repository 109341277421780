@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.container {
  margin: 30px 30px 50px 30px;
  padding: 0px 20px;
    width: 100%;
}

@media (min-width: 576px) and (max-width: 767.98px) {

.container {
    margin: 0px;
    padding: 0px 20px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 1200px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    margin: 30px 30px 80px 30px;
  }

}


@media (min-width: 992px) {
  .container {
    max-width: 1250px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer {
  padding: 0;
  overflow: hidden;
  // background: radial-gradient(50% 50% at 50% 50%, #161616 0%, #161616 100%);
  background: #161616;
}




@media (min-width: 992px) {
  .footer {
    margin-top: 0;
    padding-left: 0;
  }
}

.footer_zeeqr {
  padding-top: 10px;
  margin-top: 0;
  padding-left: 0px;

}

@media (min-width: 768px) {
  .footer_zeeqr {
    padding-top: 0;

  }

}

@media (min-width: 992px) {
  .footer_zeeqr {
    padding-top: 150px;

  }

}

.footer_zeeqr1 {
  padding-top: 100px;
  margin-top: 0;
  padding-left: 0px;

}

@media (min-width: 768px) {
  .footer_zeeqr1 {
    padding-top: 0;

  }

}

@media (min-width: 992px) {
  .footer_zeeqr1 {
    padding-top: 50px;

  }

}

.footer_logo .footer_logo_text {
  font-weight: 400;
  font-size: 16.3701px;
  color: #F0BF73;
  width: 397px;
}


@media (max-width:560px) {
  .footer_logo .footer_logo_text {
    width: 100%;
  }
    .container {
      margin: 0px;
      padding: 0px 20px;
      width: 100%;
    }
}


.footer_logo .social_icons img {
  height: 55px;
  gap: 10px;
  padding: 5px;
}

@media (min-width: 768px) {
  .footer_logo .social_icons img {
    height: 55px;
    gap: 10px;
  }

}

@media (min-width: 992px) {
  .footer_logo .social_icons img {
    height: 55px;
    gap: 10px;
    margin-top: 10px;
  }
}

.quickHead {
  margin-bottom: 27px;
}

.social_address .social_address_p,
.quickHead {
  font-weight: 600;
  font-size: 21.37px;
  line-height: 32px;
  color: #9F750F;

}

@media (min-width: 768px) {

  .social_address .social_address_p,
  .quickHead {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #9F750F;
    width: 141px;
    height: 27px;

  }
}

@media (min-width: 992px) {

  .social_address .social_address_p,
  .quickHead {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #9F750F;
    width: 141px;
    height: 27px;

  }

}

.social_address .social_address1 {
  gap: 15px;

}

.social_address .social_address1 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  max-width: 220px;
  color: #F0BE73;
}

.social_address1 a {
  color: #F0BE73;
  text-decoration: none;
}

/* .nav-footer {} */

@media (min-width: 768px) {
  .nav_footer {
    padding-left: 70px;
  }

}

@media (min-width: 992px) {
  .nav_footer {
    padding-left: 70px;
  }

}

.nav_footer p {
  font-weight: 600;
  color: #9F750F;
}

.nav_footer .nav_footer_list p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #9F750F;
  text-decoration: none;
  margin-bottom: 12px;

}

.nav_footer .nav_footer_list p a {
  color: #F0BE73;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

// .footercol{

// }
@media (min-width: 768px) {
  .footercol {
    display: flex;
  }

}

@media (min-width: 992px) {
  .footercol {
    display: flex;
  }

}

.colfooter {


  margin-top: 20px;

}

@media (min-width: 992px) {
  .colfooter {

    float: left;
    width: 50%;

  }
}

@media (min-width: 768px) {
  .colfooter {

    float: left;
    width: 50%;

  }

}

.text_white {
  width: 100%;
  height: 2px;

  margin-top: 40px;
  background: #D9D9D9;
  opacity: 0.24;
}

.footer_gallery p {
  font-weight: 600;
  font-size: 21.37px;
  line-height: 32px;

  color: #9F750F;
  width: 83px;
}

.footer_gallery .img_zeeqr {
  width: 50px;
}

@media (min-width: 768px) {
  .footer_gallery .img_zeeqr {
    width: 200px;
  }
}

.footer_gallery {
  display: none;
  padding-bottom: 15px;

  @media (min-width: 768px) {
    display: block;
  }
}

.copyright {
  padding: 23px 0px 11px 0px;
  background-color: #222222;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  margin-top: 51px;
  margin-bottom: 26px;
  width: 100%;
}

.copyright .copyright_text {
  font-weight: 400;
  font-size: 12.7586px;
  line-height: 19px;
  margin-bottom: 12px;
  color: #EEEEEE;
    opacity: 0.4;

}


@keyframes hithere {
  30% {
    transform: scale(1);
  }

  40%,
  60% {
    transform: rotate(-2deg) scale(1);
  }

  50% {
    transform: rotate(2deg) scale(1);
  }

  70% {
    transform: rotate(0deg) scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.top_btn .chatWithUs {
  position: fixed;
  bottom: 25px;
  left: 25px;
  margin-bottom: 0;
  height: 54px;
  padding: 10px 5px 10px 10px;
  font-size: 13px;
  font-weight: 600;
  color: #9d7b24;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #fff;
  border: solid 1.5px #cc9933;
  overflow: hidden;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  box-shadow: 1px 2px 7px rgba(000, 000, 000, 0.20);
  -moz-box-shadow: 1px 2px 7px rgba(000, 000, 000, 0.20);
  -webkit-box-shadow: 1px 2px 7px rgba(000, 000, 000, 0.20);
  animation: hithere 2s ease infinite;
  z-index: 9999;

  span {
    width: 85px;
    display: block;
    text-align: center;
    margin-left: 3px;
  }

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 18px;
    -moz-border-radius: 18px;
    -webkit-border-radius: 18px;
  }

  @media (min-width: 992px) {
    left: inherit;
    right: 25px;
    margin-bottom: 45px;
  }
}

.top_btn {
  position: relative;
  z-index: 999;
}

.top_btn .myBtn {
  position: fixed;
  bottom: 10px;
  float: right;
  right: 25px;
  margin-bottom: 80px;
  z-index: 9999;
}

@media (min-width: 768px) {
  .top_btn .myBtn {
    position: fixed;
    bottom: 10px;
    float: right;
    margin-bottom: 80px;
    right: 25px;
    z-index: 9999;

  }

}


.top_btn .myBtn1 {
  position: fixed;
  bottom: 10px;
  float: right;
  right: 25px;
}



@media (min-width: 768px) {
  .top_btn .myBtn1 {
    position: fixed;
    bottom: 10px;
    float: right;
  }
}

@media (min-width: 992px) {

  .top_btn .myBtn1 {
    position: fixed;
    bottom: 10px;
    float: right;
    left: 94.25%
  }

}
